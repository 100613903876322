import { Avatar } from '@chakra-ui/react'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, Path, useLocation, useNavigate } from 'react-router-dom'
import { AuthService } from '../../api/AuthService'
import { Paths } from '../../App'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Icon } from '../UI/Icon/Icon'
import Logo from '../../static/icons/cwlogo_new.svg'
import './Navbar.scss'
import { useDispatch } from 'react-redux'
import { removeAdmin } from '../../store/reducers/userReducer'

const AccordionPaths = (path: Paths) => {
    switch (path) {
        case Paths.PUSH:
            return 0
        case Paths.STORIES:
            return 1
        case Paths.SEGMENTS:
            return 2
        case Paths.AUTOSEGMENTS:
            return 3
        case Paths.PROMO:
            return 4
        case Paths.TRIGGERS:
            return 5
        case Paths.MENU:
            return 6
        case Paths.MODIFICATORS:
            return 7
        case Paths.BADGES:
            return 8
        case Paths.RECOMMENDATIONS:
            return 9
        case Paths.ORDERS:
            return 10
        case Paths.ORDER_STATES:
            return 11
        default:
            return -1
    }
}
export const Navbar = () => {
    const [menuList, setMenu] = useState([
        {
            id: Paths.DASHBOARD,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.DASHBOARD}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Dashboard</div>
                </li>
            ),
        },
        {
            id: Paths.PUSH,
            isActive: false,
            accordionIndex: -1,
            Component: ({ isActive = false, index = -1 }) => (
                <li className={`navbar__list-item dd ${isActive ? 'active open' : ''}`} onClick={toggleAccordion}>
                    <Icon name='MenuMarketing' />
                    <div className='navbar__list-item--name'>Маркетинг</div>
                    <div className='dd__list'>
                        <div onClick={() => navigateTo(`/${Paths.PUSH}`)} className={`item-link ${index === 0 ? 'active-link' : ''}`}>
                            Push
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.STORIES}`)} className={`item-link ${index === 1 ? 'active-link' : ''}`}>
                            Stroies
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.SEGMENTS}`)} className={`item-link ${index === 2 ? 'active-link' : ''}`}>
                            Сегменты
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.AUTOSEGMENTS}`)} className={`item-link ${index === 3 ? 'active-link' : ''}`}>
                            Автосегменты
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.PROMO}`)} className={`item-link ${index === 4 ? 'active-link' : ''}`}>
                            Promo
                        </div>
                        {/* <div
                            onClick={() => navigateTo(`/${Paths.TRIGGERS}`)}
                            className={`item-link ${
                                index === 5 ? 'active-link' : ''
                            }`}>
                            Триггер
                        </div> */}
                    </div>
                </li>
            ),
        },
        {
            id: Paths.MENU,
            isActive: false,
            accordionIndex: -1,
            Component: ({ isActive = false, index = -1 }) => (
                <li className={`navbar__list-item dd ${isActive ? 'active open' : ''}`} onClick={toggleAccordion}>
                    <Icon name='MenuMarketing' />
                    <div className='navbar__list-item--name'>Меню</div>
                    <div className='dd__list'>
                        <div onClick={() => navigateTo(`/${Paths.MENU}`)} className={`item-link ${index === 6 ? 'active-link' : ''}`}>
                            Основное меню
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.MODIFICATORS}`)} className={`item-link ${index === 7 ? 'active-link' : ''}`}>
                            Модификаторы
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.BADGES}`)} className={`item-link ${index === 8 ? 'active-link' : ''}`}>
                            Баджи
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.RECOMMENDATIONS}`)} className={`item-link ${index === 9 ? 'active-link' : ''}`}>
                            Рек. в корзине
                        </div>
                    </div>
                </li>
            ),
        },
        {
            id: Paths.CONCEPTS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.CONCEPTS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Концепции</div>
                </li>
            ),
        },
        {
            id: Paths.CUSTOMERS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.CUSTOMERS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Пользователи</div>
                </li>
            ),
        },
        {
            id: Paths.ORDERS,
            isActive: false,
            accordionIndex: -1,
            Component: ({ isActive = false, index = -1 }) => (
                <li className={`navbar__list-item dd ${isActive ? 'active open' : ''}`} onClick={toggleAccordion}>
                    <Icon name='MenuMarketing' />
                    <div className='navbar__list-item--name'>Заказы</div>
                    <div className='dd__list'>
                        <div onClick={() => navigateTo(`/${Paths.ORDERS}`)} className={`item-link ${index === 10 ? 'active-link' : ''}`}>
                            Заказы
                        </div>
                        <div onClick={() => navigateTo(`/${Paths.ORDER_STATES}`)} className={`item-link ${index === 11 ? 'active-link' : ''}`}>
                            Статусы заказов
                        </div>
                    </div>
                </li>
            ),
        },
        {
            id: Paths.CONTENTS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.CONTENTS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Контент</div>
                </li>
            ),
        },
        {
            id: Paths.STUFFS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.STUFFS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Сотрудники</div>
                </li>
            ),
        },
        {
            id: Paths.SETTINGS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.SETTINGS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Настройки</div>
                </li>
            ),
        },
        {
            id: Paths.SETTINGS_TPCAS,
            isActive: false,
            Component: ({ isActive = false }) => (
                <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.SETTINGS_TPCAS}`)}>
                    <Icon name='MenuDashboard' />
                    <div className='navbar__list-item--name'>Настройки интеграций</div>
                </li>
            ),
        },
        {
            id: Paths.SUPER_ADMIN_PASSWORD,
            isActive: false,
            Component: ({ isActive = false, isAdmin = false }) =>
                isAdmin ? (
                    <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.SUPER_ADMIN_PASSWORD}`)}>
                        <Icon name='MenuDashboard' />
                        <div className='navbar__list-item--name'>Настройки пользователей</div>
                    </li>
                ) : (
                    <></>
                ),
        },
        {
            id: Paths.NEW_TRIGGERS,
            isActive: false,
            Component: ({ isActive = false, isAdmin = false }) =>
                isAdmin ? (
                    <li className={`navbar__list-item ${isActive ? 'active' : ''}`} onClick={() => navigateTo(`/${Paths.NEW_TRIGGERS}`)}>
                        <Icon name='MenuDashboard' />
                        <div className='navbar__list-item--name'>Триггеры для Антона</div>
                    </li>
                ) : (
                    <></>
                ),
        },
    ])
    const [isOpen, setOpen] = useState(false)
    const location = useLocation()
    const [logo, setLogo] = useState(Logo)
    useEffect(() => {
        let path = location.pathname.split('/')[1]
        const _path = location.pathname.split('/')[1]
        if (path === Paths.STORIES || path === Paths.SEGMENTS || path === Paths.AUTOSEGMENTS || path === Paths.PROMO || path === Paths.TRIGGERS) path = Paths.PUSH
        if (path === Paths.MODIFICATORS || path === Paths.BADGES || path === Paths.RECOMMENDATIONS) path = Paths.MENU
        if (path === Paths.ORDER_STATES) path = Paths.ORDERS
        setMenu((prev) => {
            return prev.map((e) => {
                if (e.id === path) {
                    e.isActive = true
                    e.accordionIndex = AccordionPaths(_path as Paths)
                } else {
                    e.isActive = false
                    e.accordionIndex = -1
                }
                return e
            })
        })
    }, [location.pathname])

    const { logout, getAdmin } = useActions()
    const { admin } = useTypedSelector((state) => state.userReducer)
    useEffect(() => {
        if (!admin._id) getAdmin()
    }, [])
    const dispatch = useDispatch()
    // useEffect(() => {
    //     let isMount = true
    //     const getSettings = async (id: string) => {
    //         try {
    //             const api = new AuthService()
    //             const settings = await api.settings(id)
    //             // console.log(settings)
    //             if (isMount) setLogo(settings.settings.logo)
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }
    //     if (admin) {
    //         if (admin.companyId) getSettings(admin.companyId)
    //         else setLogo(Logo)
    //     }

    //     return () => {
    //         isMount = false
    //     }
    // }, [admin])
    const navigator = useNavigate()
    const toggleAccordion = (e: React.MouseEvent<HTMLLIElement>) => {
        let elem = e.target as HTMLElement
        while (elem.nodeName.toLowerCase() !== 'li') {
            if (elem.classList[0] === 'dd__list') return

            elem = elem.parentElement as HTMLElement
        }
        elem.classList.toggle('open')
    }
    const handleLogout = () => {
        dispatch(removeAdmin())
        logout()
        navigator('/auth')
        setOpen(false)
    }
    const navigateTo = (to: string) => {
        navigator(to)
        setOpen(false)
    }
    const mobileStyles = classNames({
        'mobile-open': isOpen,
    })
    return (
        <>
            <div className={`navbar ${mobileStyles}`}>
                <header className='navbar__logo'>
                    {logo && <img src={logo} alt='Logo' />}
                    <div className='navbar__logo-mobile-close' onClick={() => setOpen(false)}>
                        <Icon name='Close' />
                    </div>
                </header>

                <ul className='navbar__list'>
                    {menuList.map((Elem) => (
                        <Elem.Component key={Elem.id} isActive={Elem.isActive} index={Elem.accordionIndex} isAdmin={admin.isAdmin} />
                    ))}
                </ul>
                {admin._id ? (
                    <footer className='navbar__footer'>
                        <Avatar className='navbar__footer-avatar' name={admin.firstname} />
                        <div className='navbar__footer-user'>
                            <div className='navbar__footer-user--name'>{admin.firstname}</div>
                            <div className='navbar__footer-user--role'>{admin.isAdmin && 'Администратор'}</div>
                        </div>
                        <div className='navbar__footer-logout' onClick={handleLogout}>
                            <Icon name='Logout' />
                        </div>
                    </footer>
                ) : null}
            </div>
            <div className='mobile-navbar'>
                <div className='mobile-navbar__element'>
                    <div className='--menu' onClick={() => setOpen(!isOpen)}>
                        <Icon name='MenuDashboard' />
                    </div>
                </div>

                <div className='mobile-navbar__element --user'>
                    <Avatar className='--user-avatar' name={admin.firstname} />
                    <div className='--user-logout' onClick={handleLogout}>
                        <Icon name='Logout' />
                    </div>
                </div>
            </div>
            <div className={`mobile-shadow ${mobileStyles}`} onClick={() => setOpen(false)}></div>
        </>
    )
}
