import React, { FC } from 'react'

import { Table } from '../UI/Table/Table'
import { OrderStatesTableRow } from './OrderStatesTableRow'
import './OrderStatesTable.scss'
import { IOrderState } from '../../interfaces/OrderState'
interface OrderStatesTableProps {
    states: IOrderState[]
}
export const OrderStatesTable: FC<OrderStatesTableProps> = ({ states }) => {
    const headers = ['Номер', 'Название', 'Уведомление']
    return (
        <Table className='order-states-table' headers={headers}>
            {states.map((state, index) => (
                <OrderStatesTableRow
                    key={state._id}
                    state={state}
                    index={index + 1}
                />
            ))}
        </Table>
    )
}
