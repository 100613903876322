import { IOrderProduct } from '../interfaces/Order'

export const getTotal = (products: IOrderProduct[]) => {
    let total = 0
    if(products)
        products.forEach(p => {
            total += p.price            
            if(p.productModifiers)
                p.productModifiers.forEach(m => total += m.price)
        })

    return total
}