import { createAsyncThunk } from '@reduxjs/toolkit'
import { PromoService } from '../../../api/PromoService'
import { IAddPromo, IPromo } from '../../../interfaces/Promo'
import { RootState } from '../../reducers/rootReducer'

export const addPromocode = createAsyncThunk('promo/add',
    async(promocode: IAddPromo, thunkApi) => {
        const api = new PromoService()
        const response = await api.add( promocode) as IPromo
        return response
    }
)


export const addPromocodeClearState = createAsyncThunk('promo/addclearstate',
    (_, thunkApi) => {
        return true
    }
)