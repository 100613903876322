import React, { useEffect, useState } from 'react'
import './AdminUsers.scss'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Input } from '../../components/UI/Input/Input'
import { ChangePassword } from '../../components/ChangePassword/ChangePassword'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { UserService } from '../../api/UserService'
import { IUser } from '../../interfaces/User'
import { Loader } from '../../components/Loader/Loader'
import { Button } from '@chakra-ui/react'
export const AdminUserEdit = () => {
    const { id } = useParams()
    const [user, setUser] = useState<IUser | null>(null)
    const { getAdmin } = useActions()
    const { admin } = useTypedSelector((state) => state.userReducer)
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        let isMount = true

        const loadUser = async (_id: string) => {
            try {
                const api = new UserService()
                const res = await api.getById(_id)
                if (isMount) {
                    setUser(res)
                }
            } catch (e) {
                alert('Ошибка при загрузке данных')
            }
        }

        if (id) loadUser(id)

        if (!admin._id) getAdmin()

        return () => {
            isMount = false
        }
    }, [])
    const nav = useNavigate()
    const onSubmit = async () => {
        if (password.trim().length === 0) return alert('Введите пароль')
        if (password.trim().length < 5) return alert('Пароль должен содержать минимум 5 символов')
        try {
            setLoading(true)
            const api = new UserService()
            const res = await api.changeUserPassword(id || '', password)
            nav('/admin-users')
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    if (admin._id && !admin.isAdmin) return <Navigate to={'/'} />
    if (!user) return <Loader />
    return (
        <section className='admin-users-page'>
            <header className='admin-users-page__header'>
                <div className='admin-users-page__header--name'>
                    Настройки пользователей/{user.firstname}-{user.username}
                </div>
            </header>

            <div className='admin-users-page__content'>
                <div className='admin-users-page__username'>
                    Username:
                    <Input value={user.username} readonly />
                </div>
                <div className='admin-users-page__password'>
                    Новый пароль:
                    <Input value={password} onChange={({ target }) => setPassword(target.value)} />
                </div>
                <Button isLoading={loading} className='btn-submit' onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
        </section>
    )
}
