import { IOrder } from '../../interfaces/Dashboard'
import { FetchOrders } from '../actions/actionTypes'
import { FetchOrdersAction, FetchOrdersState } from '../types/lastOrder'

const initialState: FetchOrdersState= {
    loading: false,
    error: '',
    orders: [] as IOrder[]
}

export const lastOrdersReducer = (state = initialState, action: FetchOrdersAction): FetchOrdersState => {
    switch(action.type) {
    case FetchOrders.FETCH_ORDERS_START:
        return {
            ...state,
            loading: true,
            error: ''
        }
    case FetchOrders.FETCH_ORDERS_SUCCESS:
        return {
            ...state,
            orders: action.payload,
            loading: false
        }
    case FetchOrders.FETCH_ORDERS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error
        }
    default: return state
    }
}