import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TerminalsService } from '../../../api/TerminalService'
import { ITerminal } from '../../../interfaces/Terminals'

interface GetTerminalsByGroupArgs {
    groupId: string
    page: number
    limit?: number
}
export const getTerminalsByGroup = createAsyncThunk('terminals/getByGroup',
    async(args: GetTerminalsByGroupArgs, thunkApi) => {
        const {groupId, page, limit} = args
        const api = new TerminalsService()
        const term = await api.getByGroupId( groupId, page, limit) as IApi<ITerminal>
        return term
    }
)


interface GetTerminalsByConceptArgs {
    conceptId: string
    page: number
    limit?: number
}
export const getTerminalsByConcept = createAsyncThunk('terminals/getByConcept',
    async(args: GetTerminalsByConceptArgs, thunkApi) => {
        const {conceptId, page, limit} = args
        const api = new TerminalsService()
        const term = await api.getByConcept( conceptId, page, limit) as IApi<ITerminal>
        return term
    }
)



interface GetAllTerminalsArgs {
    page: number
    limit?: number
}
export const getAllTerminals = createAsyncThunk('terminals/getAll',
    async(args: GetAllTerminalsArgs, thunkApi) => {
        const {page, limit} = args
        const api = new TerminalsService()
        const term = await api.getAll( page, limit) as IApi<ITerminal>
        return term
    }
)

export const getTerminalById = createAsyncThunk('terminals/getById',
    async(id: string, thunkApi) => {
        const api = new TerminalsService()
        const term = await api.getById( id) as ITerminal
        return term
    }
)


// export const syncNomenclature = createAsyncThunk('terminals/syncNomenclature',
//     async(id: string, thunkApi) => {
//         const api = new TerminalsService()
//         const term = await api.sync( id) as any
//         return term
//     }
// )
