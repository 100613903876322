import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IActionContent, IContent } from '../interfaces/Content'
import { IStoriesSort } from '../interfaces/Stories'
import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class ContentService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/contents'
    getContents = async( page: number, limit?: number, query?: GetQuery) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IContent>>(`${this.URL}/${this.PATH}/?limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getContentsByConcept = async( page: number, conceptId: string, limit?: number, query?: GetQuery) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IContent>>(`${this.URL}/${this.PATH}?conceptId=${conceptId}&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getContentById = async( id: string) => {
        try {
            const response = await axiosInstance.get<IContent>(`${this.URL}/${this.PATH}/${id}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    addContent = async( content: IActionContent) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/${this.PATH}/`, content)
            return response.data
        } catch (e: any) {
            const error = e as AxiosError
            console.log(e.message)
            
            // if(error.response) {
            //     console.log('here error')
                
            //     console.log(error.response.data)
                
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    updateContent = async( content: IActionContent) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/${this.PATH}/`, content)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/${this.PATH}/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    sort = async(items: IStoriesSort[]) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/${this.PATH}/sort`, items)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}