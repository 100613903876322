import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IStories } from '../../interfaces/Stories'
import { addStorie, clearAddState } from '../actions/stories/add'
import { deleteStorie, disableStorie, enableStorie } from '../actions/stories/delete'
import { clearEditState, editStorie } from '../actions/stories/edit'
import { getAllStories, getStorieById } from '../actions/stories/get'
import { sortStories } from '../actions/stories/put'
import { StoriesState } from '../types/stories'

const initialState: StoriesState = {
    stories: [],
    storie: {} as IStories,
    getStories: {
        loading: false,
        error: ''
    },
    getStorie: {
        loading: false,
        error: ''
    },
    addStorie: {
        loading: false,
        error: '',
        isSuccess: false
    },
    sortStories: {
        error: ''
    },
    editStorie: {
        loading: false,
        error: '',
        isSuccess: false
    },
    limit: 0,
    page: 0,
    pages: 0
}


export const storiesSlice = createSlice({
    name: 'stories',
    initialState,
    reducers: {
        clear: (state) => {
            state.stories = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllStories.pending.type]: (state) => {
            state.getStories.error = ''
            state.getStories.loading = true
            // state.stories = []
        },
        [getAllStories.fulfilled.type]: (state, action: PayloadAction<IApi<IStories>>) => {
            state.stories = [...state.stories, ...action.payload.data].sort((a, b) => {
                if(a.order < b.order) return  -1
                if(a.order > b.order) return 1
                return 0
            })
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.getStories.loading = false
        },
        [getAllStories.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getStories.error = action.error.message
            state.getStories.loading = false
        },
        [getStorieById.pending.type]: (state) => {
            state.getStorie.error = ''
            state.getStorie.loading = true
        },
        [getStorieById.fulfilled.type]: (state, action: PayloadAction<IStories>) => {
            state.storie = action.payload
            state.getStorie.loading = false
        },
        [getStorieById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getStorie.error = action.error.message
            state.getStorie.loading = false
        },
        [addStorie.pending.type]: (state) => {
            state.addStorie.error = ''
            state.addStorie.loading = true
            state.addStorie.isSuccess = false
        },
        [addStorie.fulfilled.type]: (state) => {
            state.addStorie.error = ''
            state.addStorie.loading = false
            state.addStorie.isSuccess = true
        },
        [addStorie.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addStorie.error = action.error.message
            state.addStorie.loading = true
            state.addStorie.isSuccess = false
        },
        [clearAddState.fulfilled.type]: (state) => {
            state.addStorie.error = ''
            state.addStorie.loading = false
            state.addStorie.isSuccess = false
        },
        [editStorie.pending.type]: (state) => { 
            state.editStorie.error = ''
            state.editStorie.loading = true
            state.editStorie.isSuccess = false
        },
        [editStorie.fulfilled.type]: (state) => {
            state.editStorie.error = ''
            state.editStorie.loading = false
            state.editStorie.isSuccess = true
        },
        [editStorie.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editStorie.error = action.error.message
            state.editStorie.loading = true
            state.editStorie.isSuccess = false
        },
        [clearEditState.fulfilled.type]: (state) => {
            state.editStorie.error = ''
            state.editStorie.loading = false
            state.editStorie.isSuccess = false
        },
        [sortStories.pending.type]: (state) => {
            state.sortStories.error = ''
        },
        [sortStories.fulfilled.type]: (state, action: PayloadAction<IStories[]>) => {
            state.sortStories.error = ''
            state.stories = action.payload
        },
        [sortStories.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.sortStories.error = action.error.message
        },
        [disableStorie.pending.type]: (state) => {
            state.getStories.error = ''
            state.getStories.loading = true
        },
        [disableStorie.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.stories = state.stories.map(s => {
                if(s._id === action.payload) s.isDisabled = true
                return s
            })
            state.getStories.loading = false
        },
        [disableStorie.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getStories.error = action.error.message
            state.getStories.loading = false
        },
        [enableStorie.pending.type]: (state) => {
            state.getStories.error = ''
            state.getStories.loading = true
        },
        [enableStorie.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.stories = state.stories.map(s => {
                if(s._id === action.payload) s.isDisabled = false
                return s
            })
            state.getStories.loading = false
        },
        [enableStorie.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getStories.error = action.error.message
            state.getStories.loading = false
        },
        [deleteStorie.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.stories = state.stories.map(s => {
                if(s._id === action.payload) s.isDeleted = !s.isDeleted
                return s
            })
            state.getStories.loading = false
        },
    }
})

export const storiesReducer = storiesSlice.reducer
export const storiesActions = storiesSlice.actions