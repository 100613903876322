import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IStorieAdd, IStories, IStoriesSort } from '../interfaces/Stories'
import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class StoriesService extends Api {
    constructor() {
        super()
    }

    getAllStories = async(page: number, conceptId: string | null, query: GetQuery) => {
        try {
            let concept = ''
            if(conceptId) concept = `conceptId=${conceptId}`
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IStories>>(`${this.URL}/api/v1/stories/?${concept}&limit=10&page=${page}&${this.getQuery(query)}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }

    }
    getActiveStories = async() => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IStories[]>(`${this.URL}/api/v1/stories`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getStorieById = async(id: string) => {
        try {
            const response = await axiosInstance.get<IStories>(`${this.URL}/api/v1/stories/${id}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    addStorie = async(storie: IStorieAdd) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/stories/`, storie)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    sortStories = async(stories: IStoriesSort[]) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v1/stories/sort`, stories)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    editStorie = async(storie: IStories) => {
        try {
            console.log(storie)
            
            const response = await axiosInstance.put(`${this.URL}/api/v1/stories/`, storie)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error(error.response.data.detail)
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/api/v1/stories/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
   
}