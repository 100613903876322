import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IFeaturedProduct } from '../../interfaces/FeaturedProduct'
import { getFeaturedProductsByConceptId } from '../actions/featuredProducts/get'
import { updateFeaturedProducts, updateFeaturedProductsClearState } from '../actions/featuredProducts/update'
import { FeaturedProductsState } from '../types/featuredProducts'



const initialState: FeaturedProductsState = {
    limit: 0,
    pages: 0,
    page: 0,
    featuredProducts: [],
    getProducts: {
        loading: false,
        error: ''
    },
    updateProducts: {
        loading: false,
        error: '',
        isSuccess: false
    }
}

export const featuredProductsSlice = createSlice({
    name:'featuredProducts',
    initialState,
    reducers: {},
    extraReducers: {
        [getFeaturedProductsByConceptId.pending.type]: (state) => {
            state.getProducts.error = ''
            state.getProducts.loading = true
        },
        [getFeaturedProductsByConceptId.fulfilled.type]: (state, action: PayloadAction<IApi<IFeaturedProduct>>) => {
            state.getProducts.error = ''
            state.getProducts.loading = false
            state.featuredProducts = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getFeaturedProductsByConceptId.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.error = action.error.message
            state.getProducts.loading = false
        },
        [updateFeaturedProducts.pending.type]: (state) => {
            state.updateProducts.loading = true
            state.updateProducts.error = ''
            state.updateProducts.isSuccess = false
        },
        [updateFeaturedProducts.fulfilled.type]: (state,  action: PayloadAction<IApi<IFeaturedProduct>>) => {
            state.updateProducts.loading = false
            state.updateProducts.error = ''
            state.updateProducts.isSuccess = true
            state.featuredProducts = action.payload.data
        },
        [updateFeaturedProducts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.updateProducts.loading = false
            state.updateProducts.error = action.error.message
            state.updateProducts.isSuccess = false
        },
        [updateFeaturedProductsClearState.fulfilled.type]: (state) => {
            state.updateProducts.loading = false
            state.updateProducts.error = ''
            state.updateProducts.isSuccess = false
        },
    }
})


export const featuredProductsReducer = featuredProductsSlice.reducer