import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDashboardOrdersByType, IDashboardTopProducts } from '../../interfaces/Dashboard'
import { getOrdersByType, getTopProducts } from '../actions/dashboard/get'
import { DashboardState } from '../types/dasboard'

const initialState: DashboardState = {
    topProducts: [],
    getTopProducts: {
        error: '',
        loading: false
    },
    ordersByType: [],
    getOrdersByType: {
        error: '',
        loading: false
    }
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        [getTopProducts.pending.type]: (state) => {
            state.getTopProducts.error = ''
            state.getTopProducts.loading = true
        },
        [getTopProducts.fulfilled.type]: (state, action: PayloadAction<IDashboardTopProducts[]>) => {
            state.getTopProducts.error = ''
            state.getTopProducts.loading = false
            state.topProducts = action.payload
        },
        [getTopProducts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTopProducts.error = action.error.message
            state.getTopProducts.loading = false
        },
        [getOrdersByType.pending.type]: (state) => {
            state.getOrdersByType.error = ''
            state.getOrdersByType.loading = true
        },
        [getOrdersByType.fulfilled.type]: (state, action: PayloadAction<IDashboardOrdersByType[]>) => {
            state.getOrdersByType.error = ''
            state.getOrdersByType.loading = false
            state.ordersByType = action.payload
        },
        [getOrdersByType.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getOrdersByType.error = action.error.message
            state.getOrdersByType.loading = false
        },
    }
})


export const dashboardReducer = dashboardSlice.reducer