import { Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { FC, useEffect } from 'react'

import './CustomModal.scss'
import { CustomModalError } from './CustomModalError'
import { CustomModalSuccess } from './CustomModalSuccess'

interface CustomModalProps {
    open: boolean
    isError: boolean
    toggleWindow: (status: boolean) => void
    errorMsg: string
    message: string
    clearState: () => void
    navigate?: string
    // eslint-disable-next-line no-undef
    btnYes?: JSX.Element
    // eslint-disable-next-line no-undef
    btnNo?: JSX.Element
    details: string
}

export const CustomModal: FC<CustomModalProps> = ({
    isError,
    open,
    toggleWindow,
    errorMsg,
    message,
    clearState,
    navigate = '',
    btnNo,
    btnYes,
    details,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const onModalClose = () => {
        onClose()
        toggleWindow(false)
    }
    const onModalOpen = () => {
        onOpen()
        toggleWindow(true)
    }
    useEffect(() => {
        open ? onModalOpen() : onModalClose()
        return () => {}
    }, [open])

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay className='modal-wrapper' />
            {!isError ? (
                <CustomModalSuccess
                    onClose={onModalClose}
                    clearState={clearState}
                    msg={message}
                    navTo={navigate}
                    btnNo={btnNo}
                    btnYes={btnYes}
                />
            ) : (
                <CustomModalError
                    errorMsg={errorMsg}
                    clearState={clearState}
                    onClose={onModalClose}
                    details={details}
                />
            )}
        </Modal>
    )
}
