import { IPush } from '../../../interfaces/Push'
import { RootState } from '../../reducers/rootReducer'
import { PushService } from '../../../api/PushService'
import { IApi } from '../../../interfaces/Api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'


interface GetAppPushesArgs {
    page: number
    tpCasId: string
    limit?: number
    query?: GetQuery
}

export const getAllPushes = createAsyncThunk('push/getAll',
    async(args: GetAppPushesArgs, thunkApi) => {
        const {page, limit, query, tpCasId} = args
        const api = new PushService()
        const pushes = await api.get(tpCasId, page, limit, query) as IApi<IPush>
        return pushes
    }
)


export const getPushById = createAsyncThunk('push/getById',
    async(id: string, thunkApi) => {
        const api = new PushService()
        const pushes = await api.getById( id) as IPush
        return pushes
    }
)
