import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConceptService } from '../../../api/ConceptService'
import { FilesService } from '../../../api/FilesService'
import { IChangeConcept } from '../../../interfaces/Concept'
import { IStoriesAddImage, IStoriesSlides } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'

interface AddConceptArgs {
    concept: IChangeConcept,
    image: File
}
export const addConcept = createAsyncThunk('concepts/add',
    async(args: AddConceptArgs, thunkApi) => {
        const {concept, image} = args
        const api = new ConceptService()
        const fileApi = new FilesService()
        const prepairedImage: IStoriesAddImage = await fileApi.uploadStoryFile(image, `${concept.name.trim()}-preview`) as IStoriesAddImage
        const preview: IStoriesSlides = {
            hash: prepairedImage.hash,
            body: prepairedImage.body
        }

        const _concept: IChangeConcept = {
            ...concept,
            image: preview
        }

        const res = await api.add( _concept)
        return
    }
)

export const clearAddConceptState = createAsyncThunk('concepts/clearAddConceptState',
    (_, thunkApi) => {
        return
    }
)
