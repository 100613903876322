import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StuffService } from '../../api/StuffService'
import { IStuff, IStuffScope } from '../../interfaces/Stuff'
import { CustomModal } from '../Modal/CustomModal'
import { Icon } from '../UI/Icon/Icon'
import { TableRow } from '../UI/Table/TableRow/TableRow'

import './StuffTable.scss'
interface StuffTableRowProps {
    stuff: IStuff
    onDelete: (id: string) => void
    scopes: IStuffScope[]
}
export const StuffTableRow: FC<StuffTableRowProps> = ({
    stuff,
    onDelete,
    scopes,
}) => {
    const navigator = useNavigate()
    const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation()
        navigator(`./edit/${stuff._id}`)
    }
    const deleteStuff = async () => {
        try {
            const api = new StuffService()
            const res = await api.delete(stuff._id, !stuff.isDeleted)
            onDelete(stuff._id)
        } catch (e) {
            console.log(e)
        }
    }
    const disabled = () => {}
    const preparedData = [
        `${stuff.name}`,
        `Код: ${stuff.code || ''}`,
        `${stuff.tpcases.map((c) => c.name).join(', ')}`,
        `${
            stuff.scope
                // ?.map((s) => scopes.find((sc) => s === sc.value))
                .join(', ') || ''
        }`,
        <div
            className='stuff-table-row__icons'
            onClick={(e) => e.stopPropagation()}>
            <div
                className='stuff-table-row__icons--icon'
                onClick={(e) => {
                    e.stopPropagation()
                    setIsDelete(true)
                    setShow(true)
                }}>
                <Icon name='Delete' />
            </div>
        </div>,
    ]
    const [isShow, setShow] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    return (
        <>
            <TableRow
                onClick={handleEdit}
                className={`${
                    stuff.isDeleted ? 'stuff-table-row--disabled' : ''
                }`}
                data={preparedData}
            />
            <CustomModal
                open={isShow}
                message={`Вы уверены, что хотите ${
                    isDelete ? 'удалить/восстановить' : 'скрыть/восстановить'
                } ${stuff.name}?`}
                isError={false}
                toggleWindow={setShow}
                errorMsg={''}
                clearState={() => {}}
                details={''}
                btnYes={
                    <button
                        className='modal__footer-btn btn-yes'
                        onClick={(e) => {
                            isDelete ? deleteStuff() : disabled()
                            setShow(false)
                        }}>
                        Да
                    </button>
                }
                btnNo={
                    <button
                        className='modal__footer-btn btn-no'
                        onClick={() => {
                            setShow(false)
                        }}>
                        Нет
                    </button>
                }
            />
        </>
    )
}
