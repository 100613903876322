import { createAsyncThunk } from '@reduxjs/toolkit'
import { IikoCategoriesService } from '../../../api/IikoCategoriesService'
import { IIikoCategories, IIikoCategoriesUpdate } from '../../../interfaces/IikoCategories'
import { RootState } from '../../reducers/rootReducer'
import { IikoProductsService } from '../../../api/IikoProductsService'

interface UpdateArgs {
    category: IIikoCategoriesUpdate,
    isSmart: boolean
}
export const updateCategory = createAsyncThunk('iikoCategory/update',
    async (args: UpdateArgs, thunkApi) => {
        const api = new IikoCategoriesService()
        const prodApi = new IikoProductsService()
        if(args.category.isSmart) {
            const respMig = api.migrateToSmart(args.category.rrule!, args.category._id!)
            // const respMigP = prodApi.migrateToSmart(args.category.rrule!, args.category._id!)
            const promises = await Promise.all([respMig])
        }
        const response = await api.updateIikoCategory(args.category, args.isSmart)
        return response
    }
)


export const clearUpdateCategoryState = createAsyncThunk('iikoCategory/clearUpdateCategoryState',
    (_, thunkApi) => {
        return
    }
)


interface SortCategoriesArgs {
    categories: IIikoCategories[]
    page: number
}
export const sortCategories = createAsyncThunk('iikoCategory/sortCategories',
    async (args: SortCategoriesArgs, thunkApi) => {
        const { categories, page } = args
        const api = new IikoCategoriesService()
        const catUpdate = categories.map((cat, index) => ({
            _id: cat._id,
            // order: (page - 1)*10+index
            order: index
        }))
        const response = await api.sort(catUpdate)
        return catUpdate
    }
)