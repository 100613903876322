import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { login, logout, testToken } from '../actions/auth/actionAuth'
import { AuthState } from '../types/auth'


const initialState: AuthState = {
    isAuth: !!localStorage.getItem('token'),
    error: '',
    loading: false,
    token: localStorage.getItem('token') || ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.token = action.payload
            state.isAuth = true
            state.error = ''
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
            state.error = ''
        },
        setError: (state, action: PayloadAction<Error>) => {
            state.loading = false
            state.isAuth = false
            state.error = action.payload.message
        }
    },
    extraReducers: {
        [login.fulfilled.type]: (state, action: PayloadAction<string>) => {
            // localStorage.setItem('token', action.payload)
            state.loading = false
            state.token = action.payload
            state.isAuth = true
            state.error = ''
        },
        [login.pending.type]: (state) => {
            state.loading = true
            state.error = ''
        },
        [login.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.loading = false
            state.isAuth = false
            state.error = action.error.message
        },
        [logout.fulfilled.type]: (state) => {
            localStorage.removeItem('token')
            localStorage.removeItem('company')
            state.isAuth = false
            state.token = ''
        }
    }
})

export const authReducer = authSlice.reducer
export const authActions = authSlice.actions