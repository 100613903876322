import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IAddBadge, IBadge } from '../interfaces/Badges'
import { Api, DeleteQuery } from './Api'
import { axiosInstance } from './axios'

export class BadgeService extends Api {
    constructor() {
        super()
    }

    get = async( page: number, limit?: number) => {
        try {
            let _limit = 10000
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IBadge>>(`${this.URL}/api/v1/badges/?isDeleted=false&isHidden=false&isDisabled=false&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    add = async( badge: IAddBadge) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/badges/`, badge)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/api/v1/badges/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}