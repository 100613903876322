import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TerminalsService } from '../../api/TerminalService'
import { TpCasService } from '../../api/TpCasService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { ITerminal } from '../../interfaces/Terminals'
import { ITpCas } from '../../interfaces/TpCas'
import { IMultiSelectOptions, MultiSelect } from '../UI/MultiSelect/MultiSelect'

interface TerminalsSelectProps {
    onChange: (badges: ITerminal[]) => void
    selectedTerminals: ITerminal[]
    selectedConcepts: IConcept[]
}

export const TerminalsSelect: FC<TerminalsSelectProps> = ({
    onChange,
    selectedTerminals,
    selectedConcepts
}) => {
    const [currPage, setCurrPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [ts, setTs] = useState<ITerminal[]>([])
    const limit = 10
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [tsArray, setTsArray] = useState<ITerminal[]>([])

    useEffect(() => {
        let isM = true
        const getCasses = async (page: number) => {
            try {
                const api = new TerminalsService()
                const _casses = await api.getAll(page, limit)
                if (isM) {
                    setPages(_casses.pages)
                    setTs(_casses.data)
                }
            } catch (e: any) {
                console.log(e)
                setStatus({
                    loading: false,
                    error: e,
                })
            }
        }
        getCasses(currPage)
        return () => {
            isM = false
        }
    }, [currPage])
    useEffect(() => {
        setTsArray((prev) => [...prev, ...ts])
    }, [ts])

    const cb = useCallback(() => {
        console.log([limit, currPage, pages])

        if (pages <= currPage) return
        setCurrPage((prev) => prev + 1)
    }, [limit, currPage])

    const optionsToCass = (values: IMultiSelectOptions[]) => {
        const _casses = tsArray.filter((b) => {
            for (let value of values) {
                if (value.id === b._id) return true
            }
            return false
        })
        onChange(_casses)
    }

    const getOptionsWithoutSelected = useMemo(() => {
        return tsArray
            .map((b) => ({
                id: b._id,
                value: b.name,
                concept: b.conceptId
            }))
            .filter(b => {
                for (let i = 0; i < selectedConcepts.length; i++) {
                    if (b.concept === selectedConcepts[i]._id) return true
                }
                return false 
            })
            .filter((b) => {
                for (let i = 0; i < selectedTerminals.length; i++) {
                    if (b.id === selectedTerminals[i]._id) return false
                }
                return true
            })
    }, [selectedConcepts, selectedTerminals])

    if (tsArray.length === 0) return null
    return (
        <MultiSelect
            options={getOptionsWithoutSelected}
            defaultValue={selectedTerminals.map((s) => ({
                id: s._id,
                value: s.name,
            }))}
            onChange={optionsToCass}
            loading={status.loading}
            isUpdateDefault
            cb={cb}
        />
    )
}
