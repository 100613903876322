import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilesService } from '../../../api/FilesService'
import { StoriesService } from '../../../api/StoriesService'
import { IStorieAdd, IStoriesAddImage, IStoriesSlides, IUISettings } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'


interface AddStorieArgs {
    name: string
    imageMini: File
    images: File[]
    tpcasId: string
    conceptId: string
    companyId: string
    uiSettings: IUISettings
}
export const addStorie = createAsyncThunk('storie/add',
    async(args: AddStorieArgs, thunkApi) => {
        const {name, images, imageMini, tpcasId, companyId, conceptId, uiSettings} = args
        const api = new StoriesService()
        const fileApi = new FilesService()
        const prepairedPreview: IStoriesAddImage = await fileApi.uploadStoryFile(imageMini, `${name.trim()}-preview`) as IStoriesAddImage
        const preview: IStoriesSlides = {
            hash: prepairedPreview.hash,
            body: prepairedPreview.body
        }
        
        let slides: IStoriesSlides[] = []

        for(let i = 0; i < images.length; i++) {
            const prepSlide = await fileApi.uploadStoryFile(images[i], `${name.trim()}-${i}`)  as IStoriesAddImage
            slides.push({
                hash: prepSlide.hash,
                body: prepSlide.body
            })
        }

        const storie: IStorieAdd = {
            preview,
            type: 0,
            name: name.trim(),
            slides,
            isDisabled: false,
            isDeleted: false,
            order: 0,
            title: '',
            subtitle: '',
            authorId: '',
            moderators: [],
            auditors: [],
            companyId,
            tpcasId,
            conceptId,
            uiSettings
        }

        const res = await api.addStorie(storie)
        return
    }
)
export const clearAddState = createAsyncThunk('storie/clearState',
    (_, thunkApi) => {
        return
    }
)
