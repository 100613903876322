import { clearIikoProductsState, getAllIikoProducts, getIikoProductsByCategoryIdAndConceptId, getIikoProductsById, getIikoProductsBySubstr } from './get'
import { updateIikoProduct, clearUpdateIikoProductState, disabledProduct, deleteProduct, sortProducts, enableProduct } from './update'

export const IikoProductsActions = {
    getAllIikoProducts,
    getIikoProductsById,
    getIikoProductsByCategoryIdAndConceptId,
    updateIikoProduct,
    clearUpdateIikoProductState,
    getIikoProductsBySubstr,
    disabledProduct,
    clearIikoProductsState,
    deleteProduct,
    enableProduct,
    sortProducts,
    // 
}