import React, { FC, useCallback, useEffect, useState } from 'react'
import { StuffService } from '../../api/StuffService'
import { IStuffScope } from '../../interfaces/Stuff'
import { IMultiSelectOptions, MultiSelect } from '../UI/MultiSelect/MultiSelect'

interface StaffScopesSelectProps {
    onChange: (scopes: IStuffScope[]) => void
    selectedScopes: IStuffScope[]
    defaultValues?: string[]
}

export const StaffScopesSelect: FC<StaffScopesSelectProps> = ({
    onChange,
    selectedScopes,
    defaultValues = [],
}) => {
    const [scopes, setScopes] = useState<IStuffScope[]>([])
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [scopesArray, setScopesArray] = useState<IStuffScope[]>([])

    useEffect(() => {
        let isM = true
        const getScopes = async () => {
            try {
                const api = new StuffService()
                const _casses = await api.getScopes()
                if (isM) {
                    setScopes(_casses)
                }
            } catch (e: any) {
                console.log(e)
                setStatus({
                    loading: false,
                    error: e,
                })
            }
        }
        getScopes()
        return () => {
            isM = false
        }
    }, [])
    useEffect(() => {
        setScopesArray((prev) => [...prev, ...scopes])
    }, [scopes])
    // useEffect(() => {
    //     setScopesArray()
    // }, [defaultValues])
    const optionsToCass = (values: IMultiSelectOptions[]) => {
        let _casses = scopesArray.filter((b) => {
            for (let value of values) {
                if (value.id === b.value) return true
            }
            return false
        })

        if (values[0].id === 'default' && values.length > 1)
            _casses = _casses.filter((c) => c.value !== 'default')
        else if (values[values.length - 1].id === 'default')
            _casses = _casses.filter((c) => c.value === 'default')

        onChange(_casses)
    }

    const getOptionsWithoutSelected = () => {
        return scopesArray
            .map((b) => ({
                id: b.value,
                value: b.name,
            }))
            .filter((b) => {
                for (let i = 0; i < selectedScopes.length; i++) {
                    if (b.id === selectedScopes[i].value) return false
                }
                return true
            })
    }

    if (scopesArray.length === 0) return null
    return (
        <MultiSelect
            options={getOptionsWithoutSelected()}
            defaultValue={selectedScopes.map((s) => ({
                id: s.value,
                value: scopes.find((sc) => sc.value === s.value)?.name || '',
            }))}
            isUpdateDefault
            onChange={optionsToCass}
            loading={status.loading}
        />
    )
}
