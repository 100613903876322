import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilesService } from '../../../api/FilesService'
import { PushService } from '../../../api/PushService'
import { IChangePush, IPush } from '../../../interfaces/Push'
import { IStoriesAddImage, IStoriesSlides } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'

interface AddPushArgs {
    push: IChangePush
    image: File
}
export const addPush = createAsyncThunk('push/add',
    async(args: AddPushArgs, thunkApi) => {
        const {push, image} = args
        const api = new PushService()
            
        const fileApi = new FilesService()
        const prepairedPreview: IStoriesAddImage = await fileApi.uploadNotificationFile(image, `${push.title.trim()}-preview`) as IStoriesAddImage
        const preview: IStoriesSlides = {
            hash: prepairedPreview.hash,
            body: prepairedPreview.body
        }
        const _push: IChangePush = {
            title: push.title,
            subtitle: push.subtitle,
            body: push.body,
            image: preview,
            segmentId: push.segmentId,
            moderators: push.moderators,
            auditors: push.auditors,
            tpcasId: push.tpcasId,
            companyId: push.companyId
        }
          
        const response = await api.add( _push) as IPush
        return response._id
            
    }
)
export const clearAddPushState = createAsyncThunk('push/clearState',
    (_, thunkApi) => {
        return
    }
)
