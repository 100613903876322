import { createAsyncThunk } from '@reduxjs/toolkit'
import { StoriesService } from '../../../api/StoriesService'
import { RootState } from '../../reducers/rootReducer'

export const disableStorie = createAsyncThunk('storie/disable',
    async(id: string, thunkApi) => {
        const api = new StoriesService()
        const response = await api.delete( id, {isDisabled: true})
        return id
    }
)


export const enableStorie = createAsyncThunk('storie/enable',
    async(id: string, thunkApi) => {
        const api = new StoriesService()
        const response = await api.delete( id, {isDisabled: false})
        return id
    }
)

interface IDelete {
    id: string
    isDeleted: boolean
}
export const deleteStorie = createAsyncThunk('storie/deleteStorie',
    async(args: IDelete, thunkApi) => {
        const api = new StoriesService()
        const {id, isDeleted} = args
        const response = await api.delete( id, {isDeleted})
        return id
    }
)
