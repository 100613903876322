import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useScroll } from '../../../hooks/useScroll'
import { Loader } from '../../Loader/Loader'
import { Icon } from '../Icon/Icon'
import './MultiSelect.scss'

export interface IMultiSelectOptions {
    id: number | string
    value: string
    isActive?: boolean
}
interface SelectProps {
    options: IMultiSelectOptions[]
    defaultValue?: IMultiSelectOptions[]
    onChange: (value: IMultiSelectOptions[]) => void
    className?: string
    isAsync?: boolean
    loading?: boolean
    cb?: () => void
    isUpdateDefault?: boolean
}

export const MultiSelect: FC<SelectProps> = ({
    options,
    defaultValue = [],
    onChange,
    className = '',
    isAsync = false,
    loading = false,
    cb,
    isUpdateDefault = false,
}) => {
    const [Options, setOptions] = useState(options)
    const [selectedItem, setSelectedItem] =
        useState<IMultiSelectOptions[]>(defaultValue)
    const [btnValue, setBtnValue] = useState<string[]>([])
    const [isListVisible, setListVisible] = useState(false)
    const btnRef = useRef<HTMLButtonElement>(null)
    const ddListStyles = classNames({
        multi_dropdown__list: true,
        'multi_dropdown__list--visible': isListVisible,
    })
    const liListStyles = classNames({
        'multi_dropdown__list-item': true,
    })
    const handleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        // eslint-disable-next-line no-extra-semi
        ;(btnRef.current as HTMLButtonElement).focus()
        setListVisible((prev) => !prev)
    }

    const selectRef = useRef<HTMLDivElement>(null)
    const liRef = useRef<HTMLLIElement>(null)
    const ulRef = useRef<HTMLUListElement>(null)

    useScroll(ulRef, liRef, cb)

    const handleClickOutside = (e: MouseEvent) => {
        if (selectRef.current?.contains(e.target as Node)) return
        setListVisible(false)
    }

    useEffect(() => {
        if (isListVisible)
            document.addEventListener('mousedown', handleClickOutside)
        else document.removeEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isListVisible])

    const handleListClick = (e: any) => {
        e.stopPropagation()
        ;(btnRef.current as HTMLButtonElement).focus()
        const { id, value, active } = e.target.dataset
        // console.log(e.target.dataset)

        setSelectedItem([...selectedItem, { id, value }])
        setOptions((prev) => prev.filter((o) => o.id !== id))
        onChange([...selectedItem, { id, value }])
    }

    useEffect(() => {
        if (isUpdateDefault) {
            setSelectedItem(defaultValue)
            setOptions(options)
        }
        // console.log(selectedItem)
        // onChange(selectedItem)
    }, [defaultValue])

    const removeFromSelect = (id: string | number, value: string) => {
        setSelectedItem((prev) => prev.filter((s) => s.id !== id))
        setOptions([...Options, { id, value }])
        onChange(selectedItem.filter((s) => s.id !== id))
    }
    return (
        <div className={`multi_dropdown ${className}`} ref={selectRef}>
            <button
                className='multi_dropdown__button'
                // onBlur={handleCloseDropDown}
                onClick={handleBtnClick}
                ref={btnRef}>
                <div className='multi_dropdown__button--text'>
                    {selectedItem.map((s) => {
                        return (
                            <div key={s.id} className='multi-dd-element'>
                                <div className='multi-dd-element__name'>
                                    {s.value}
                                </div>
                                <div
                                    className='multi-dd-element__delete'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        removeFromSelect(s.id, s.value)
                                    }}>
                                    <Icon name='Close' />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Icon name='ArrowDown' />
                <ul className={ddListStyles} ref={ulRef}>
                    {Options.map((option, index) => {
                        return (
                            <li
                                key={`${JSON.stringify(option)}`}
                                className={liListStyles}
                                data-id={option.id}
                                data-value={option.value}
                                data-active={option.isActive}
                                onClick={handleListClick}>
                                {option.value}
                            </li>
                        )
                    })}
                    {loading ? (
                        <li className='dd-list-helper-loader'>
                            <Loader className='dd-list-helper-loader--l' />
                        </li>
                    ) : null}

                    <li className='dd-list-helper' ref={liRef}></li>
                </ul>
            </button>
        </div>
    )
}
