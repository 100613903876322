import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IModifiers } from '../../interfaces/IikoProducts'
import { disableModifier } from '../actions/iikoModifiers/delete'
import { getIikoModifiersByTerminalId } from '../actions/iikoModifiers/get'

import { IikoModifiersState } from '../types/iikoModifiers'

const initialState: IikoModifiersState = {
    iikoModifiers: [],
    getModifiers: {
        loading: false,
        error: ''
    },
    limit: 0,
    pages: 0,
    page: 0,
}

export const iikoModifiersSlice = createSlice({
    name: 'iikoModifiers',
    initialState,
    reducers: {},
    extraReducers: {
        [getIikoModifiersByTerminalId.pending.type]: (state) => {
            state.getModifiers = {
                error: '',
                loading: true
            }
        },
        [getIikoModifiersByTerminalId.fulfilled.type]: (state, actions: PayloadAction<IApi<IModifiers>>) => {
            state.getModifiers = {
                error: '',
                loading: false
            }
            state.iikoModifiers = actions.payload.data
            state.limit = actions.payload.limit
            state.page = actions.payload.page
            state.pages = actions.payload.pages
        },
        [getIikoModifiersByTerminalId.rejected.type]: (state, actions: PayloadAction<any, any, any, Error>) => {
            state.getModifiers = {
                error: actions.error.message,
                loading: false
            }
        },
        [disableModifier.pending.type]: (state) => {
            state.getModifiers = {
                error: '',
                loading: true
            }
        },
        [disableModifier.fulfilled.type]: (state, actions: PayloadAction<string>) => {
            state.getModifiers = {
                error: '',
                loading: false
            }
            state.iikoModifiers = state.iikoModifiers.map(m => {
                if(m._id === actions.payload) m.isDisabled = !m.isDisabled
                return m
            }) 
        },
        [disableModifier.rejected.type]: (state, actions: PayloadAction<any, any, any, Error>) => {
            state.getModifiers = {
                error: actions.error.message,
                loading: false
            }
        }
    }
})


export const iikoModifiersReducer = iikoModifiersSlice.reducer