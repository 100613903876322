import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IPush } from '../../interfaces/Push'
import { addPush, clearAddPushState } from '../actions/push/add'
import { deletePush, disablePush, enablePush } from '../actions/push/delete'
import { getAllPushes, getPushById } from '../actions/push/get'
import { clearUpdatePushState, updatePush } from '../actions/push/update'
import { PushState } from '../types/push'

const initialState: PushState = {
    pushs: [],
    getPushes: {
        loading: false,
        error: ''
    },
    limit: 0,
    page: 0,
    pages: 0,
    editPush: {
        loading: false,
        error: '',
        isSuccess: false
    },
    addPush: {
        loading: false,
        error: '',
        isSuccess: false,
        id: ''
    },
    push: {} as IPush,
    getPush: {
        loading: false,
        error: ''
    }
}

export const pushSlice = createSlice({
    name: 'push',
    initialState,
    reducers: {
        clear: (state) => {
            state.pushs = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllPushes.pending.type]: (state) => { 
            state.getPushes.error = ''
            state.getPushes.loading = true
            // state.pushs = []
        },
        [getAllPushes.fulfilled.type]: (state, action: PayloadAction<IApi<IPush>>) => {
            state.getPushes.loading = false
            state.pushs = [...state.pushs, ...action.payload.data]
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getAllPushes.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getPushes.error = action.error.message
            state.getPushes.loading = false
        },
        [getPushById.pending.type]: (state) => { 
            state.getPush.error = ''
            state.getPush.loading = true
        },
        [getPushById.fulfilled.type]: (state, action: PayloadAction<IPush>) => {
            state.getPush.loading = false
            state.push = action.payload
        },
        [getPushById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getPush.error = action.error.message
            state.getPush.loading = false
        },
        [addPush.pending.type]: (state) => {
            state.addPush.error = ''
            state.addPush.loading = true
            state.addPush.isSuccess = false
        },
        [addPush.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.addPush.error = ''
            state.addPush.loading = false
            state.addPush.isSuccess = true
            state.addPush.id = action.payload
        },
        [addPush.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => { 
            state.addPush.error = action.error.message
            state.addPush.loading = false
            state.addPush.isSuccess = false
        },
        [clearAddPushState.fulfilled.type]: (state) => {
            state.addPush.error = ''
            state.addPush.loading = false
            state.addPush.isSuccess = false
        },
        [updatePush.pending.type]: (state) => {
            state.editPush.error = ''
            state.editPush.loading = true
            state.editPush.isSuccess = false
        },
        [updatePush.fulfilled.type]: (state) => {
            state.editPush.error = ''
            state.editPush.loading = false
            state.editPush.isSuccess = true
        },
        [updatePush.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editPush.error = action.error.message
            state.editPush.loading = false
            state.editPush.isSuccess = false
        },
        [clearUpdatePushState.fulfilled.type]: (state) => {
            state.editPush.error = ''
            state.editPush.loading = false
            state.editPush.isSuccess = false
        },
        [deletePush.fulfilled.type]: (state, action: PayloadAction<string>) => {    
            state.pushs = state.pushs.map(c => {
                if(c._id === action.payload) {
                    // c.isDisabled = true
                    c.isDeleted = !c.isDeleted
                }
                    
                return c
            })
        },
        [disablePush.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.pushs = state.pushs.map(segment => {
                if(segment._id === action.payload)
                    segment.isDisabled = true
                return segment
            })
        },
        [enablePush.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.pushs = state.pushs.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = false
                return tg
            })
        },
    }
})

export const pushReducer = pushSlice.reducer
export const pushActions = pushSlice.actions