import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomerService } from '../../../api/CustomerService'
import { IikoOrdersService } from '../../../api/IikoOrdersService'
import { IApi } from '../../../interfaces/Api'
import { IOrder } from '../../../interfaces/Order'
import { RootState } from '../../reducers/rootReducer'

interface GetCustomerOrdersArgs {
    customerId: string
    page: number
    limit?: number
}
export const getCustomerOrders = createAsyncThunk('order/getByCustomerId', 
    async(args: GetCustomerOrdersArgs, thunkApi) => {
        const {page, limit, customerId} = args
        const api = new CustomerService()
        const orders = await api.getCustomerOrders( customerId ,page, limit) as IApi<IOrder>
        return orders
    }
)


interface GetCustomerOrderById {
    customerId: string
    orderId: string
}
export const getCustomerOrderById = createAsyncThunk('order/getOrderById', 
    async(args: GetCustomerOrderById, thunkApi) => {
        const {customerId, orderId} = args
        const api = new CustomerService()
        const order = await api.getCustomerOrderById( customerId, orderId) as IOrder
        return order
    }
)

export const getOrdersForDashboard = createAsyncThunk('order/getOrdersForDashboard', 
    async(page: number, thunkApi) => {
        const api = new IikoOrdersService()
        const orders = await api.get( page) as IApi<IOrder>
        return orders
    }
)


