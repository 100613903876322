import React, { useEffect, useMemo, useState } from 'react'
import { axiosInstance } from '../../api/axios'
import { Select } from '../../components/UI/Select/Select'
import './MarketingTriggers.scss'

export const MarketingTriggers = () => {
    return (
        <div className='marketing-triggers'>
            <Conditions />
            <Actions />
        </div>
    )
}

const Actions = () => {
    const [actions, setActions] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedAction, setSelected] = useState('')
    const [loadingPayload, setLoadingPayload] = useState(false)

    useEffect(() => {
        let isMount = true

        const getActions = async () => {
            try {
                setLoading(true)
                const res = await axiosInstance.get('https://marketing.api.cw.marketing/triggers/actions')
                if (isMount) setActions(res.data)
            } catch (e) {
                console.log(e)
                alert('Smth went wrong')
            } finally {
                setLoading(false)
            }
        }

        getActions()
        return () => {
            isMount = false
        }
    }, [])

    // useEffect(() => {
    //     let isMount = true

    //     const getActionsPayload = async () => {
    //         try {
    //             setLoadingPayload(true)
    //             const res = await axiosInstance.get(`https://marketing.api.cw.marketing/triggers/actions/payload/${selectedAction}`)
    //             if (isMount) console.log(res.data)
    //         } catch (e) {
    //             alert('Smth went wrong')
    //         } finally {
    //             setLoadingPayload(false)
    //         }
    //     }
    //     if (selectedAction.length > 0) getActionsPayload()
    //     return () => {
    //         isMount = false
    //     }
    // }, [selectedAction])

    if (loading) return <></>
    return (
        <div className='marketing-triggers_actions'>
            <h2>Actions</h2>
            <Select options={actions.map((a) => ({ id: a, value: a }))} onChange={(v) => setSelected(v.value)} />
        </div>
    )
}

const Conditions = () => {
    const [conditions, setConditions] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingPayload, setLoadingPayload] = useState(false)
    const [selectedCond, setSelected] = useState('')
    const [condPayload, setCondPayload] = useState(null)
   
    useEffect(() => {
        let isMount = true

        const getConditions = async () => {
            try {
                setLoading(true)
                const res = await axiosInstance.get('https://marketing.api.cw.marketing/triggers/conditions/is_sheduled')
                if (isMount) setConditions(res.data)
            } catch (e) {
                alert('Smth went wrong')
            } finally {
                setLoading(false)
            }
        }

        getConditions()
        return () => {
            isMount = false
        }
    }, [])

    useEffect(() => {
        let isMount = true

        const getConditionsPayload = async () => {
            try {
                setLoadingPayload(true)
                const res = await axiosInstance.get(`https://marketing.api.cw.marketing/triggers/conditions/payload/${selectedCond}`)
                if (isMount) {
                    setCondPayload(res.data)
                }
            } catch (e) {
                alert('Smth went wrong')
            } finally {
                setLoadingPayload(false)
            }
        }
        if (selectedCond.length > 0) getConditionsPayload()
        return () => {
            isMount = false
        }
    }, [selectedCond])

    const properties = useMemo(() => {
        if(!condPayload) return []
        return Object.keys(condPayload.properties).map(k => ({...condPayload.properties[k], property: k}))
    }, [condPayload])

    if (loading) return <></>
    return (
        <div className='marketing-triggers_conditions'>
            <h2>Conditions</h2>
            <Select options={conditions.map((c) => ({ id: c.name, value: c.name }))} onChange={(v) => setSelected(v.value)} />
            {condPayload && <div>
                {properties.map(pr => <TriggerInput key={condPayload.title + pr.title} title={pr.title} type={pr.type} format={pr.format} required={condPayload.required.includes(pr.property)}/>)}
            </div>}
        </div>
    )
}


const TriggerInput = ({type, title, required, format}) => {
    console.log(type)
    if(type === 'integer') 
        return (
            <div className='trigger-input'>
                <label>{title}{required && <sup>*</sup>}:</label>
                <input type={'number'}/>
            </div>
        )
    if(type === 'string') {
        if(format === 'date-time') {
            return (
                <div className='trigger-input'>
                    <label>{title}:</label>
                    <input type="datetime-local" />
                </div>
            )
        }
        return (
            <div className='trigger-input'>
                <label>{title}:</label>
                <input />
            </div>
        )
    }
       
    return <></>
}