import { createAsyncThunk } from '@reduxjs/toolkit'
import { TerminalsService } from '../../../api/TerminalService'
import { IChangeTerminal, ITerminal } from '../../../interfaces/Terminals'
import { RootState } from '../../reducers/rootReducer'


export const updateIikoTerminal = createAsyncThunk('terminals/update',
    async(args: IChangeTerminal, thunkApi) => {     
        const api = new TerminalsService()
        const terminal = await api.update(args) as ITerminal
        return terminal
    }
)

export const clearUpdateTerminalState = createAsyncThunk('terminals/clearUpdateTerminalState',
    (_, thunkApi) => {
        return 
    }
)