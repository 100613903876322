import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IBadge } from '../../../interfaces/Badges'
import { BadgeService } from '../../../api/BadgeService'

interface getAllBadgesArgs {
    page: number
    limit?: number
}
export const getAllBadges = createAsyncThunk('badges/getAll',
    async(args: getAllBadgesArgs, thunkApi) => {
        const {page, limit} = args
        const api = new BadgeService()
        const concepts = await api.get( page, limit) as IApi<IBadge>
        
        return concepts
    }
)
