import { addConcept, clearAddConceptState } from './add'
import { deleteConcept, disableConcept, enableConcept } from './disable'
import { clearConceptState, getAllConcepts, getConceptById } from './get'
import { clearUpdateConceptState, updateConcept } from './update'


export const ConceptsAction = {
    getAllConcepts,
    getConceptById,
    clearConceptState,
    addConcept,
    clearAddConceptState,
    updateConcept,
    clearUpdateConceptState,
    disableConcept,
    deleteConcept,
    enableConcept
}