import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { TpCasService } from '../../api/TpCasService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IOrder } from '../../interfaces/Order'
import { ITpCas } from '../../interfaces/TpCas'
import Alarm from '../../static/audio/new_order.wav'
import { orderActions } from '../../store/reducers/orderReducer'
import './NewOrderListener.scss'
interface SocketMessage {
    action: string
    data: IOrder
}

export const NewOrderListener = () => {
    const { admin } = useTypedSelector((state) => state.userReducer)
    const [orders, setOrders] = useState<IOrder[]>([])
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        let socket: WebSocket
        if (admin && admin.companyId) {
            socket = new WebSocket(
                `wss://wss.api.cw.marketing/live/${admin.companyId}/ws`
            )
            socket.onopen = () => {
                console.log(`[OPEN] Соединение установлено с ${socket.url}`)
            }
            socket.onmessage = (ev) => {
                const message = JSON.parse(ev.data) as SocketMessage

                if (message.action === 'add') {
                    console.log(message.data)
                    const alarm = new Audio(Alarm)
                    alarm.volume = 0.5
                    const promise = alarm.play()
                    if (promise) {
                        promise
                            .then((_) => {
                                ///
                            })
                            .catch((_) => {
                                console.log(
                                    'autoplay error, fuck autoplay policy'
                                )
                            })
                    }
                    setOrders([...orders, message.data])
                    if (location.pathname === '/orders') {
                        dispatch(orderActions.addNewOrder(message.data))
                    }
                    setTimeout(() => {
                        alarm.pause()
                        setOrders(
                            orders.filter((o) => o._id !== message.data._id)
                        )
                    }, 5000)
                }
            }
            socket.onclose = () => {
                console.log(`[CLOSE] Соединение закрыто с ${socket.url}`)
            }
        }

        return () => {
            if (socket) socket.close()
        }
    }, [admin])

    const navigate = useNavigate()
    return (
        <div className='order-listener'>
            {orders.map((order) => (
                <div
                    key={order._id}
                    className='order-listener__block'
                    onClick={() => navigate(`/orders/edit/${order._id}`)}>
                    <p>Получен новый заказ</p>
                </div>
            ))}
        </div>
    )
}
