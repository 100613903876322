import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthService } from '../../../api/AuthService'
import { RootState } from '../../reducers/rootReducer'

export const login = createAsyncThunk('auth/login',
    async ({username, password}: {username: string, password: string}, thunkApi) => {
        const api = new AuthService()
        const response = await api.login(username, password) 
        console.log(response)
        localStorage.setItem('token', response.access_token)
        return response.access_token
    }
)

export const testToken = createAsyncThunk('auth/testToken',
    async (_, thunkApi) => {
        const api = new AuthService()
        // await api.testToken((thunkApi.getState() as RootState).authReducer.token)       
    }
)
export const logout = createAsyncThunk('auth/logout',
    async (_, thunkApi) => {
        
        return true   
    }
)
