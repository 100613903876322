import React, { FC, useEffect, useState } from 'react'
import { CompanyService } from '../../api/CompanyService'
import { IApi } from '../../interfaces/Api'
import { ICompany } from '../../interfaces/Company'
import { ISelectOptions, Select } from '../UI/Select/Select'

interface CompanySelectProps {
    onChange: (company: ICompany) => void
    token?: string
    withEmpty?: boolean
}
const emptyCompany: ICompany = {
    _id: 'null',
    name: 'Нет',
}
export const CompanySelect: FC<CompanySelectProps> = ({ onChange, token, withEmpty = false }) => {
    const [selectData, setSelectData] = useState<ISelectOptions[]>([])
    const [loading, setLoading] = useState(true)

    const [companies, setCompanies] = useState<ICompany[]>([])

    useEffect(() => {
        if (companies.length > 0) {
            const data = []
            if (withEmpty) {
                data.push({ id: emptyCompany._id, value: emptyCompany.name })
            }
            data.push(
                ...companies.map((c) => ({
                    id: c._id,
                    value: c.name,
                }))
            )
            setSelectData(data)
            if (withEmpty) onChange(emptyCompany)
            else onChange(companies[0])
        }
        return () => {}
    }, [companies])

    useEffect(() => {
        let isMounted = true

        const getCompanies = async () => {
            const api = new CompanyService()
            try {
                const _companies = await api.getCompanies(1, 1000, token)

                if (isMounted) {
                    setLoading(false)
                    setCompanies(_companies.data)
                }
            } catch (e) {
                console.log(e)
                if (isMounted) setLoading(false)
            }
        }
        getCompanies()
        return () => {
            isMounted = false
        }
    }, [])

    const handleDropdown = (value: ISelectOptions) => {
        if (value.id === 'null') return onChange(emptyCompany)
        const _company = companies.find((c) => c._id === value.id)
        if (_company) onChange(_company)
    }
    if (selectData.length === 0 || loading) return null
    return <Select onChange={handleDropdown} options={selectData} defaultValue={selectData[0]} />
}
