import React, { FC, useEffect, useState } from 'react'
import { IContent } from '../../interfaces/Content'
import { Table } from '../UI/Table/Table'
import './StuffTable.scss'
import { IStuff, IStuffScope } from '../../interfaces/Stuff'
import { StuffTableRow } from './StuffTableRow'
import { StuffService } from '../../api/StuffService'
interface StuffTableProps {
    stuffs: IStuff[]
    onDelete: (id: string) => void
}

export const StuffTable: FC<StuffTableProps> = ({ stuffs, onDelete }) => {
    const [scopes, setScopes] = useState<IStuffScope[]>([])
    useEffect(() => {
        let isM = true
        const getScopes = async () => {
            try {
                const api = new StuffService()
                const _casses = await api.getScopes()
                if (isM) {
                    setScopes(_casses)
                }
            } catch (e: any) {
                console.log(e)
            }
        }
        getScopes()
        return () => {
            isM = false
        }
    }, [])
    return (
        <Table className='stuff-table'>
            {stuffs.map((stuff) => (
                <StuffTableRow
                    key={stuff._id}
                    stuff={stuff}
                    onDelete={onDelete}
                    scopes={scopes}
                />
            ))}
        </Table>
    )
}
