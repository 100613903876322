import axios, { AxiosError } from 'axios'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class AuthService extends Api {
    constructor() {
        super()
    }

    // testToken = async(token: string) => {
    //     try {
    //         const response = await axios.get('https://staging.api.seabass.group/marketing_segments/?onlyEnabled=true', {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         return response.data
    //     } catch (e) {
    //         const error = e as AxiosError
    //         if(error.response) {
    //             if(error.response.status === 404) throw new Error('Сервер временно не работает')
    //             if(error.response.status === 401) throw new Error('401')
    //         } else throw new Error('Сервер временно не работает')
    //     }
    // }

    login = async(username: string, password: string) => {
        try {
            const body = new FormData()
            body.append('username', username)
            body.append('password', password)
            const response = await axios.post(`${this.URL}/api/v1/auth/token`, body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status)
                if(error.response.status === 401) throw new Error('Неверный логин или пароль')
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    logout = async() => {
        try {
            // const response = await axios.post('https://staging.api.seabass.group/logout')
            // return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    settings = async(id: string) => {
        try {
            const response = await axiosInstance.get(`${this.URL}/api/v1/companies/${id}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}