import { createAsyncThunk } from '@reduxjs/toolkit'
import { IikoModifiersService } from '../../../api/IikoModifiersService'
import { IApi } from '../../../interfaces/Api'
import { IModifiers, IModifiersOption } from '../../../interfaces/IikoProducts'
import { RootState } from '../../reducers/rootReducer'

interface GetIikoModifiersByTerminalIdArgs {
    terminalId: string
    companyId: string
    page: number
    limit?: number
}
export const getIikoModifiersByTerminalId = createAsyncThunk('iikoModifiers/getByTerminalId', 
    async(args: GetIikoModifiersByTerminalIdArgs, thunkApi) => {
        const {page, limit, terminalId, companyId} = args
        const api = new IikoModifiersService()
        const mods = await api.getIikoModifiersByTerminalId( terminalId , companyId, page, limit) as IApi<IModifiers>
        return mods
    }
)

export const getIikoModifiersoptionsByTerminalId = createAsyncThunk('iikoModifiers/getIikoModifiersoptionsByTerminalId', 
    async(args: GetIikoModifiersByTerminalIdArgs, thunkApi) => {
        const {page, limit, terminalId} = args
        const api = new IikoModifiersService()
        const options = await api.getIikoModifiersOptionsByTerminalId( terminalId ,page, limit) as IApi<IModifiersOption>
        return options
    }
)
