import { createAsyncThunk } from '@reduxjs/toolkit'
import { DashboardService } from '../../../api/DashboardService'
import { IDashboardOrdersByType, IDashboardTopProducts } from '../../../interfaces/Dashboard'
import { RootState } from '../../reducers/rootReducer'

export const getTopProducts = createAsyncThunk('dashboard/getTopProducts', 
    async(_, thunkApi) => {
        const api = new DashboardService()
        const res = await api.getTopProducts() as IDashboardTopProducts[]
        return res
    }
)
export const getOrdersByType = createAsyncThunk('dashboard/getOrdersByType',
    async(_, thunkApi) => {
        const api = new DashboardService()
        const res = await api.getOrdersByType() as IDashboardOrdersByType[]
        return res
    }
)