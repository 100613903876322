export enum Auth {
    FETCH_AUTH_START = 'FETCH_AUTH_START',
    FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS',
    FECTH_AUTH_ERROR = 'FECTH_AUTH_ERROR',
    FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS'
}

export enum Terminals {
    FETCH_TERMINALS_START = 'FETCH_TERMINALS_START',
    FETCH_TERMINALS_SUCCESS = 'FETCH_TERMINALS_SUCCESS',
    FETCH_TERMINALS_ERROR = 'FETCH_TERMINALS_ERROR',

    REMOVE_TERMINALS_START = 'REMOVE_TERMINALS_START',
    REMOVE_TERMINALS_SUCCESS = 'REMOVE_TERMINALS_SUCCESS',
    REMOVE_TERMINALS_ERROR = 'REMOVE_TERMINALS_ERROR',

    ADD_TERMINALS_START = 'ADD_TERMINALS_START',
    ADD_TERMINALS_SUCCESS = 'ADD_TERMINALS_SUCCESS',
    ADD_TERMINALS_ERROR = 'ADD_TERMINALS_ERROR'
}

export enum TerminalsGroup {
    FETCH_TERMINALS_GROUP_START = 'FETCH_TERMINALS_GROUP_START',
    FETCH_TERMINALS_GROUP_SUCCESS = 'FETCH_TERMINALS_GROUP_SUCCESS',
    FETCH_TERMINALS_GROUP_ERROR = 'FETCH_TERMINALS_GROUP_ERROR',

    REMOVE_TERMINALS_GROUP_START = 'REMOVE_TERMINALS_GROUP_START',
    REMOVE_TERMINALS_GROUP_SUCCESS = 'REMOVE_TERMINALS_GROUP_SUCCESS',
    REMOVE_TERMINALS_GROUP_ERROR = 'REMOVE_TERMINALS_GROUP_ERROR',

    ADD_TERMINALS_GROUP_START = 'ADD_TERMINALS_GROUP_START',
    ADD_TERMINALS_GROUP_SUCCESS = 'ADD_TERMINALS_GROUP_SUCCESS',
    ADD_TERMINALS_GROUP_ERROR = 'ADD_TERMINALS_GROUP_ERROR'
}

export enum IikoLogin {
    IIKO_LOGIN_START = 'IIKO_LOGIN_START',
    IIKO_LOGIN_SUCCESS = 'IIKO_LOGIN_SUCCESS',
    IIKO_LOGIN_ERROR = 'IIKO_LOGIN_ERROR'
}

export enum FetchOrders {
    FETCH_ORDERS_START = 'FETCH_ORDERS_START',
    FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS',
    FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'
}

export enum FetchTopDishes {
    FETCH_TOP_DISHES_START = 'FETCH_TOP_DISHES_START',
    FETCH_TOP_DISHES_SUCCESS = 'FETCH_TOP_DISHES_SUCCESS',
    FETCH_TOP_DISHES_ERROR = 'FETCH_TOP_DISHES_ERROR'
}
export enum FetchPush {
    FETCH_PUSH_START = 'FETCH_PUSH_START',
    FETCH_PUSH_SUCCESS = 'FETCH_PUSH_SUCCESS',
    FETCH_PUSH_ERROR = 'FETCH_PUSH_ERROR'
}
export enum EditPush {
    EDIT_PUSH_START = 'EDIT_PUSH_START',
    EDIT_PUSH_SUCCESS = 'EDIT_PUSH_SUCCESS',
    EDIT_PUSH_ERROR = 'EDIT_PUSH_ERROR',
    EDIT_PUSH_CLEAR_STATE = 'EDIT_PUSH_CLEAR_STATE'
}
export enum AddPush {
    ADD_PUSH_START = 'ADD_PUSH_START',
    ADD_PUSH_SUCCESS = 'ADD_PUSH_SUCCESS',
    ADD_PUSH_ERROR = 'ADD_PUSH_ERROR',
    ADD_PUSH_CLEAR_STATE = 'ADD_PUSH_CLEAR_STATE'
}



export enum FetchStories {
    FETCH_STORIES_START = 'FETCH_STORIES_START',
    FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS',
    FETCH_STORIES_ERROR = 'FETCH_STORIES_ERROR'
}
export enum AddStorie {
    ADD_STORIE_START = 'ADD_STORIE_START',
    ADD_STORIE_SUCCESS = 'ADD_STORIE_SUCCESS',
    ADD_STORIE_ERROR = 'ADD_STORIE_ERROR',
    ADD_STORIE_CLEAR_STATE = 'ADD_STORIE_CLEAR_STATE'
}
export enum SortStories {
    SORT_STORIES_START = 'SORT_STORIES_START',
    SORT_STORIES_SUCCESS = 'SORT_STORIES_SUCCESS',
    SORT_STORIES_ERROR = 'SORT_STORIES_ERROR'
}
export enum EditStorie {
    EDIT_STORIE_START = 'EDIT_STORIE_START',
    EDIT_STORIE_SUCCESS = 'EDIT_STORIE_SUCCESS',
    EDIT_STORIE_ERROR = 'EDIT_STORIE_ERROR',
    EDIT_STORIE_CLEAR_STATE = 'EDIT_STORIE_CLEAR_STATE'
}
export enum FetchStorie {
    FETCH_STORIE_START = 'FETCH_STORIE_START',
    FETCH_STORIE_SUCCESS = 'FETCH_STORIE_SUCCESS',
    FETCH_STORIE_ERROR = 'FETCH_STORIE_ERROR'
}
export enum RemoveStorie {
    REMOVE_STORIE_START = 'REMOVE_STORIE_START',
    REMOVE_STORIE_SUCCESS = 'REMOVE_STORIE_SUCCESS',
    REMOVE_STORIE_ERROR = 'REMOVE_STORIE_ERROR'
}


export enum FetchSegments {
    FETCH_SEGMENTS_START = 'FETCH_SEGMENTS_START',
    FETCH_SEGMENTS_SUCCESS = 'FETCH_SEGMENTS_SUCCESS',
    FETCH_SEGMENTS_ERROR = 'FETCH_SEGMENTS_ERROR'
}
export enum FetchSegment {
    FETCH_SEGMENT_START = 'FETCH_SEGMENT_START',
    FETCH_SEGMENT_SUCCESS = 'FETCH_SEGMENT_SUCCESS',
    FETCH_SEGMENT_ERROR = 'FETCH_SEGMENT_ERROR'
}
export enum AddSegment {
    ADD_SEGMENT_START = 'ADD_SEGMENT_START',
    ADD_SEGMENT_SUCCESS = 'ADD_SEGMENT_SUCCESS',
    ADD_SEGMENT_ERROR = 'ADD_SEGMENT_ERROR',
    CLEAR_ADD_SEGMENT = 'CLEAR_ADD_SEGMENT'
}
export enum DisableSegment {
    DISABLE_SEGMENT_START = 'DISABLE_SEGMENT_START',
    DISABLE_SEGMENT_SUCCESS = 'DISABLE_SEGMENT_SUCCESS',
    DISABLE_SEGMENT_ERROR = 'DISABLE_SEGMENT_ERROR'
}

export enum FetchMenu {
    FETCH_MENU_START = 'FETCH_MENU_START',
    FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS',
    FETCH_MENU_ERROR = 'FETCH_MENU_ERROR'
}

export enum FetchIikoProducts {
    FETCH_IIKOP_START = 'FETCH_IIKOP_START',
    FETCH_IIKOP_SUCCESS = 'FETCH_IIKOP_SUCCESS',
    FETCH_IIKOP_ERROR = 'FETCH_IIKOP_ERROR'
}
export enum UpdateIikoProducts {
    UPDATE_IIKOP_START = 'UPDATE_IIKOP_START',
    UPDATE_IIKOP_SUCCESS = 'UPDATE_IIKOP_SUCCESS',
    UPDATE_IIKOP_ERROR = 'UPDATE_IIKOP_ERROR',
    CLEAR_UPDATE_PRODUCT = 'CLEAR_UPDATE_PRODUCT'
}
export enum FetchConcepts {
    FETCH_CONCEPTS_START = 'FETCH_CONCEPTS_START',
    FETCH_CONCEPTS_SUCCESS = 'FETCH_CONCEPTS_SUCCESS',
    FETCH_CONCEPTS_ERROR = 'FETCH_CONCEPTS_ERROR'
}
export enum FetchConcept {
    FETCH_CONCEPT_START = 'FETCH_CONCEPT_START',
    FETCH_CONCEPT_SUCCESS = 'FETCH_CONCEPT_SUCCESS',
    FETCH_CONCEPT_ERROR = 'FETCH_CONCEPT_ERROR'
}

export enum FetchIikoCategoriesByConcept {
    FETCH_IIKOCATBYCONCEPT_START = 'FETCH_IIKOCATBYCONCEPT_START',
    FETCH_IIKOCATBYCONCEPT_SUCCESS = 'FETCH_IIKOCATBYCONCEPT_SUCCESS',
    FETCH_IIKOCATBYCONCEPT_ERROR = 'FETCH_IIKOCATBYCONCEPT_ERROR'
}