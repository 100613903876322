import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IRRule } from '../interfaces/IikoCategories'
import { IIikoProducts, IUpdateIikoProducts } from '../interfaces/IikoProducts'
import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class IikoProductsService extends Api {
    constructor() {
        super()
    }

    getIikoProducts = async( page: number, code?: string) => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v2/products`, {
                params: {
                    companyId,
                    isDeleted: false,
                    isHidden: false,
                    isDisabled: false,
                    limit: 10000,
                    code
                }
            })
            // const responseSmart = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v1/smart_products`, {
            //     params: {
            //         companyId,
            //         isDeleted: false,
            //         isHidden: false,
            //         isDisabled: false,
            //         limit: 10000,
            //         code
            //     }
            // })
            return {
                ...response.data,
                data: [...response.data.data, 
                    // ...responseSmart.data.data
                ]
            }
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getProductById = async( code: string, isSmart: boolean) => {
        try {
            // if(isSmart) {
            //     const response = await axiosInstance.get<IIikoProducts>(`${this.URL}/api/v1/smart_products/${code}`)
            //     return response.data
            // }
            const response = await axiosInstance.get<IIikoProducts>(`${this.URL}/api/v2/products/${code}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getIikoProductsByCategoryIdAndConceptId = async( catId: string, conceptId: string, page: number, query?: GetQuery,  terminalId?: string) => {
        try {
            const t = terminalId ? `terminalId=${terminalId}&` : '' 
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v2/products/?${this.getQuery(query)}`, {
                params: {
                    companyId,
                    terminalId,
                    limit: 10000,
                    categoryId: catId
                }
            })
            // const responseSmart = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v1/smart_products/?${this.getQuery}`, {
            //     params: {
            //         companyId,
            //         terminalId,
            //         limit: 10000,
            //         categoryId: catId
            //     }
            // })
            return response.data
            // return {
            //     ...response.data,
            //     data: [...response.data.data, ...responseSmart.data.data]
            // }
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    update = async( product: IUpdateIikoProducts, isSmart: boolean) => {
        try {
            // if(isSmart) {
            //     const response = await axiosInstance.put<IIikoProducts>(`${this.URL}/api/v1/smart_products/`, product)
            //     return response.data
            // }
            const response = await axiosInstance.put<IIikoProducts>(`${this.URL}/api/v2/products/`, product)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    add = async( product: IUpdateIikoProducts, isSmart: boolean) => {
        try {
            // if(isSmart) {
            //     const response = await axiosInstance.post<IIikoProducts>(`${this.URL}/api/v1/smart_products/`, product)
            //     return response.data
            // }
            const response = await axiosInstance.post<IIikoProducts>(`${this.URL}/api/v2/products/`, product)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    migrateToSmart = async (rrule: IRRule, id: string) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v2/p2smp/`, {
                _id: id,
                rrule
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    search = async( conceptId: string, searchProduct: string, terminalId?: string) => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v2/products/?search=${searchProduct}`, {
                params: {
                    companyId,
                    conceptId,
                    terminalId,
                    isDeleted: false
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    getFeaturedProductsByConcept = async( conceptId: string, page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = 10
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoProducts>>(`${this.URL}/api/v2/featured_products/concept/${conceptId}?limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery, isSmart: boolean) => {
        try {
            const q = this.getQuery(params)
            // if(isSmart) {
            //     const response = await axiosInstance.delete(`${this.URL}/api/v1/smart_products/${id}?${q}`)
            //     return response.data
            // }
            const response = await axiosInstance.delete(`${this.URL}/api/v2/products/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    sort = async(products: any) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v2/products/sort`, products)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}