import { createAsyncThunk } from '@reduxjs/toolkit'
import { GroupOfTerminalsService } from '../../../api/GroupOfTerminalsService'
import { IAddTerminalsGroup, ITerminalsGroup } from '../../../interfaces/TerminalsGroup'
import { RootState } from '../../reducers/rootReducer'


export const addTerminalGroups = createAsyncThunk('terminalsGroup/add',
    async(args: IAddTerminalsGroup, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const term = await api.add(args) as ITerminalsGroup
        return term
    }
)

export const clearAddTerminalGroupsState = createAsyncThunk('terminalsGroup/clearaddstate',
    (_, thunkApi) => {
        return 
    }
)