import { createAsyncThunk } from '@reduxjs/toolkit'
import { GroupOfTerminalsService } from '../../../api/GroupOfTerminalsService'
import { IAddTerminalsGroup, ITerminalsGroup } from '../../../interfaces/TerminalsGroup'
import { RootState } from '../../reducers/rootReducer'


export const updateTerminalGroups = createAsyncThunk('terminalsGroup/update',
    async(args: IAddTerminalsGroup, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const term = await api.update(args) as ITerminalsGroup
        return term
    }
)

export const clearUpdateTerminalGroupsState = createAsyncThunk('terminalsGroup/clearUpdateTerminalGroupsState',
    (_, thunkApi) => {
        return 
    }
)