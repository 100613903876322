import  React, { useEffect, useRef } from 'react'

export function useScroll(parentRef: any, childRef: any, cb?: (() => void)) {
    const observer = useRef<IntersectionObserver>()
    useEffect(() => {
        if(cb) {
            const options = {
                root: parentRef.current,
                rootMargin: '0px',
                threshold: 1
            } 
            observer.current = new IntersectionObserver(([target]) => {
                if(target.isIntersecting) {
                    cb()
                }
            }, options)
    
            observer.current.observe(childRef.current)
        }
        
        const childCopy = childRef.current
        
        return () => {
            observer.current?.unobserve(childCopy)
        }
    }, [cb, childRef, parentRef])
}