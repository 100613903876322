import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { IIikoCategories } from '../../../interfaces/IikoCategories'
import { IikoCategoriesService } from '../../../api/IikoCategoriesService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'

interface GetCategoriesByConceptIdArgs {
    conceptId: string
    page: number
    terminalId?: string
    query?: GetQuery
}
export const getCategoriesByConceptId = createAsyncThunk('iikoCategores/getByConcept',
    async (args: GetCategoriesByConceptIdArgs, thunkApi) => {
        const { conceptId, page, query, terminalId } = args
        const api = new IikoCategoriesService()
        const categories = await api.getAllCategoriesByConceptId(conceptId, page, query, terminalId) as IApi<IIikoCategories>
        return categories
    }
)

interface getCategorieByIdArgs {
    id: string,
    isSmart: boolean
}
export const getCategorieById = createAsyncThunk('iikoCategores/getById',
    async (args: getCategorieByIdArgs, thunkApi) => {
        const { id, isSmart } = args
        const api = new IikoCategoriesService()
        const categorie = await api.getCategoryById(id, isSmart) as IIikoCategories
        return categorie
    }
)

export const clearCategorieState = createAsyncThunk('iikoCategores/clearCategorieState',
    (_, thunkApi) => {
        return
    }
)