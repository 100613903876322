import { addPromocode, addPromocodeClearState } from './add'
import { getAllPromos, getPromoById } from './get'
import { editPromocode, editPromocodeClearState } from './update'

export const PromoActions = {
    getAllPromos,
    addPromocode,
    addPromocodeClearState,
    getPromoById,
    editPromocode,
    editPromocodeClearState
}