import React, { FC, useEffect, useRef, useState } from 'react'
import { IContent } from '../../interfaces/Content'
import { Table } from '../UI/Table/Table'
import { ContentTableRow } from './ContentTableRow'
import './ContentTable.scss'
import { ContentService } from '../../api/ContentService'
interface ContentTableProps {
    contents: IContent[]
    onDelete: (id: string) => void
}

export const ContentTable: FC<ContentTableProps> = ({ contents, onDelete }) => {
    const [contentsArray, setContentsArray] = useState(contents)
    const [isDragging, setDragging] = useState(false)
    const dragItem = useRef<number | null>()
    const dragNode = useRef<EventTarget | null>()
    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        index: number
    ) => {
        dragItem.current = index
        dragNode.current = e.target
        dragNode.current.addEventListener('dragend', handleDragEnd)
        setTimeout(() => {
            setDragging(true)
        }, 0)
    }
    useEffect(() => {
        setContentsArray(contents.sort((a, b) => a.order - b.order))
    }, [contents])
    useEffect(() => {
        const sortContent = async () => {
            try {
                const api = new ContentService()
                const res = await api.sort(
                    contentsArray.map((c, i) => ({
                        _id: c._id,
                        order: i,
                    }))
                )
            } catch (e) {
                console.log(e)
            }
        }

        const timeout = setTimeout(() => {
            if (contentsArray.length > 0) {
                let flag = false
                contents.forEach((s, i) => {
                    if (s._id !== contentsArray[i]._id) flag = true
                })
                if (flag) sortContent()
            }
        }, 700)
        return () => {
            clearTimeout(timeout)
        }
    }, [contentsArray])
    const handleDragEnter = (
        e: React.DragEvent<HTMLDivElement>,
        index: number
    ) => {
        const currentItem = dragItem.current
        if (e.target !== dragNode.current) {
            setContentsArray((prev) => {
                const newData = [...prev]
                newData.splice(
                    index,
                    0,
                    newData.splice(currentItem as number, 1)[0]
                )
                dragItem.current = index
                return newData
            })
        }
    }
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
    }
    const handleDragEnd = (e: Event) => {
        setDragging(false)
        dragNode.current?.removeEventListener('dragend', handleDragEnd)
        dragItem.current = null
        dragNode.current = null
    }
    const getStyles = (index: number, isDisabled: boolean) => {
        const currentItem = dragItem.current
        const className = ['content-dnd__item']
        if (currentItem === index) className.push('--current')
        if (isDisabled) className.push('--disabled')
        return className.join(' ')
    }

    return (
        <Table className='content-table'>
            {contentsArray.map((content, index) => (
                <div
                    key={content._id}
                    draggable
                    onDragOver={handleDragOver}
                    onDragEnter={
                        isDragging ? (e) => handleDragEnter(e, index) : () => {}
                    }
                    className={
                        isDragging
                            ? getStyles(index, content.isDeleted)
                            : content.isDeleted
                            ? 'content-dnd__item --disabled'
                            : 'content-dnd__item'
                    }
                    onDragStart={(e) => handleDragStart(e, index)}>
                    <ContentTableRow
                        key={content._id}
                        content={content}
                        onDelete={onDelete}
                    />
                </div>
            ))}
        </Table>
    )
}
