import { addSegment, addSegmentPhones, clearAddSegmentState } from './add'
import { disableSegment, deleteSegment, enableSegment } from './disable'
import { getAllSegments, getSegmentById } from './get'


export const SegmentActions = {
    getAllSegments,
    getSegmentById,
    addSegment,
    disableSegment,
    addSegmentPhones,
    clearAddSegmentState,
    deleteSegment,
    enableSegment
}