import { createAsyncThunk } from '@reduxjs/toolkit'
import { TerminalsService } from '../../../api/TerminalService'
import { IChangeTerminal, ITerminal } from '../../../interfaces/Terminals'
import { RootState } from '../../reducers/rootReducer'


export const addIikoTerminal = createAsyncThunk('terminals/addIikoTerminal',
    async (args: IChangeTerminal, thunkApi) => {
        const api = new TerminalsService()

        const terminal = await api.add(args) as ITerminal
        return terminal
    }
)

export const clearAddTerminalState = createAsyncThunk('terminals/clearAddTerminalState',
    (_, thunkApi) => {
        return
    }
)