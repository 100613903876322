import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AuthActions from '../store/actions/auth/actionAuth'
import {TerminalsActions} from '../store/actions/terminals/actionTerminals'
import {TerminalsGroupActions} from '../store/actions/terminalsGroup/actionTerminalsGroup'
import * as LastOrdersAction from '../store/actions/dashboard/actionLastOrders'
import {PushActions} from '../store/actions/push/actionPush'
import {SegmentActions} from '../store/actions/segment/actionSegment'
import {StoriesActions} from '../store/actions/stories/actionStories'
import { IikoProductsActions } from '../store/actions/iikoProducts/actionIikoProducts'
import { ConceptsAction } from '../store/actions/concepts/actionConcepts'
import { IikoCategoriesAction } from '../store/actions/iikoCategories/actionIikoCategories'
import {AppDispatch} from '../store/store'
import { PromoActions } from '../store/actions/promo/actionPromo'
import { CustomerAction } from '../store/actions/customer/actionCustomer'
import { TriggerAction } from '../store/actions/triggers/actionTrigger'
import { BadgesAction } from '../store/actions/badge/actionBadges'
import { OrdersAction } from '../store/actions/order/actionOrder'
import { UsersAction } from '../store/actions/user/actionUser'
import { IikoModifiersActions } from '../store/actions/iikoModifiers/iikoModifiersAction'
import { FeaturedProductActions } from '../store/actions/featuredProducts/featuredProductsAction'
import { DashboardActions } from '../store/actions/dashboard/dashboardAction'

export const useActions = () => {
    const dispatch = useDispatch<AppDispatch>()
    return bindActionCreators({
        ...TerminalsActions,
        ...AuthActions,
        ...TerminalsGroupActions,
        ...LastOrdersAction,
        ...PushActions,
        ...StoriesActions,
        ...SegmentActions,
        ...IikoProductsActions,
        ...ConceptsAction,
        ...IikoCategoriesAction,
        ...PromoActions,
        ...CustomerAction,
        ...TriggerAction,
        ...BadgesAction,
        ...OrdersAction,
        ...UsersAction,
        ...IikoModifiersActions,
        ...FeaturedProductActions,
        ...DashboardActions
    }, dispatch)
}