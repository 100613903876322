import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ICustomer } from '../interfaces/Customer'
import { IOrder } from '../interfaces/Order'
import { Api } from './Api'
import { axiosInstance } from './axios'


export class CustomerService extends Api {
    constructor() {
        super()
    }

    getCustomers = async( page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<ICustomer>>(`${this.URL}/api/v1/customers/?isDeleted=false&isHidden=false&isDisabled=false&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getCustomersSearch = async( searchParamPhone: string, searchParamCard: string, searchParamName: string, page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<ICustomer>>(`${this.URL}/api/v1/customers/?isDeleted=false&isHidden=false&isDisabled=false&limit=${_limit}&page=${page}&${getSearchParams(searchParamPhone,searchParamCard,searchParamName)}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getCustomerById = async( id: string) => {
        try {
            const response = await axiosInstance.get<ICustomer>(`${this.URL}/api/v1/customers/${id}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getCustomerOrders = async( id: string, page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IOrder>>(`${this.URL}/api/v1/customers/orders/${id}?limit=${_limit}&page=${page}`, {
                params:{
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getCustomerOrderById = async( id: string, orderId: string) => {
        try {
            const response = await axiosInstance.get<IOrder>(`${this.URL}/api/v1/customers/order/${orderId}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}

function getSearchParams(searchParamPhone: string, searchParamCard: string, searchParamName: string) {
    let params = ''
    if(searchParamPhone.length > 0) params += `searchPhone=${searchParamPhone}`
    if(searchParamCard.length > 0) {
        if(params.length > 0) params += '&'
        params += `searchCard=${searchParamCard}`
    }
    if(searchParamName.length > 0) {
        if(params.length > 0) params += '&'
        params += `searchName=${searchParamName}`
    }

    return params
}