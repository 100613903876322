import { RootState } from '../../reducers/rootReducer'
import { SegmentService } from '../../../api/SegmentService'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface AddSegmentArgs {
    title: string
    dob: string
    gender: 0 | 1,
    tpcasId: string
    sendAll: boolean
}
export const addSegment = createAsyncThunk('segment/add',
    async(args: AddSegmentArgs, thunkApi) => {
        const {title, dob, gender, tpcasId, sendAll} = args
        const api = new SegmentService()
        const company = localStorage.getItem('company')
        if(sendAll) {
            const segment = {
                title: title.trim(),
                dob: null,
                gender: null,
                companyId: company || '',
                tpcasId,
                auditors: null,
                moderators: null,
                isDeleted: false, 
                isDisabled: false
            }
            console.log(segment)
            
            const response = await api.add(segment) as any
            return response
        }
        
        const bDay = dob.split(',')[0].split('.')
        const segment = {
            title: title.trim(),
            dob: `${bDay[2]}-${bDay[1]}-${bDay[0]}`,
            gender: gender === 0 ? 'female' : 'male',
            companyId: company || '',
            tpcasId,
            auditors: [],
            moderators: [],
            isDeleted: false, 
            isDisabled: false
        }
        console.log(segment)
        
        const response = await api.add( segment) as any
        return response
    }
)
interface AddSegmentPhonesArgs {
    title: string
    phones: string[]
    tpcasId: string
}
export const addSegmentPhones = createAsyncThunk('segment/addWithPhones',
    async(args: AddSegmentPhonesArgs, thunkApi) => {
        const {title, phones, tpcasId} = args
        const api = new SegmentService()
        const company = localStorage.getItem('company')
        const segment = {
            title: title.trim(),
            phones: phones.map(p => +p),
            tpcasId,
            companyId: company || '',
            auditors: [],
            moderators: [],
            isDeleted: false,
            isDisabled: false
        }
        const response = await api.addWithPhones( segment) as any
        return response
    }
)
export const clearAddSegmentState = createAsyncThunk('segment/clearState',
    (_, thunkApi) => {
        return
    }
)
