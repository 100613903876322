import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IPromo } from '../../interfaces/Promo'
import { addPromocode, addPromocodeClearState } from '../actions/promo/add'
import { getAllPromos, getPromoById } from '../actions/promo/get'
import { editPromocode, editPromocodeClearState } from '../actions/promo/update'
import { PromoState } from '../types/promo'

const initialState: PromoState = {
    limit: 0,
    page: 0,
    pages: 0,
    promos: [],
    promo: {} as IPromo,
    getPromo: {
        loading: false,
        error: ''
    },
    getPromos: {
        loading: false,
        error: ''
    },
    addPromo: {
        loading: false,
        error: '',
        isSuccess: false
    },
    editPromo: {
        loading: false,
        error: '',
        isSuccess: false
    }
}

export const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        clear: (state) => {
            state.promos = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllPromos.pending.type]: (state) => {
            state.getPromos.loading = true
            state.getPromos.error = ''
            // state.promos = []
        },
        [getAllPromos.fulfilled.type]: (state, action: PayloadAction<IApi<IPromo>>) => {
            state.getPromos.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.promos = [...state.promos, ...action.payload.data]
        },
        [getAllPromos.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getPromos.loading = false
            state.getPromos.error = action.error.message
        },
        [getPromoById.pending.type]: (state) => {
            state.getPromo.loading = true
            state.getPromo.error = ''
        },
        [getPromoById.fulfilled.type]: (state, action: PayloadAction<IPromo>) => {
            state.getPromo.loading = false
            state.promo = action.payload
        },
        [getPromoById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getPromo.loading = false
            state.getPromo.error = action.error.message
        },
        [addPromocode.pending.type]: (state) => {
            state.addPromo.loading = true
            state.addPromo.error = ''
            state.addPromo.isSuccess = false
        },
        [addPromocode.fulfilled.type]: (state) => {
            state.addPromo.loading = false
            state.addPromo.error = ''
            state.addPromo.isSuccess = true
        },
        [addPromocode.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addPromo.loading = false
            state.addPromo.error = action.error.message
            state.addPromo.isSuccess = false
        },
        [addPromocodeClearState.fulfilled.type]: (state) => {
            state.addPromo.loading = false
            state.addPromo.error = ''
            state.addPromo.isSuccess = false
        },
        [editPromocode.pending.type]: (state) => {
            state.editPromo.loading = true
            state.editPromo.error = ''
            state.editPromo.isSuccess = false
        },
        [editPromocode.fulfilled.type]: (state) => {
            state.editPromo.loading = false
            state.editPromo.error = ''
            state.editPromo.isSuccess = true
        },
        [editPromocode.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editPromo.loading = false
            state.editPromo.error = action.error.message
            state.editPromo.isSuccess = false
        },
        [editPromocodeClearState.fulfilled.type]: (state) => {
            state.editPromo.loading = false
            state.editPromo.error = ''
            state.editPromo.isSuccess = false
        },
    }
})

export const promoReducer = promoSlice.reducer

export const promoActions = promoSlice.actions