import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilesService } from '../../../api/FilesService'
import { StoriesService } from '../../../api/StoriesService'
import { IStories, IStoriesAddImage, IStoriesSlides, IUISettings } from '../../../interfaces/Stories'
import { IImage } from '../../../interfaces/utils'
import { RootState } from '../../reducers/rootReducer'


interface EditStorieArgs {
    storie: IStories
    name: string 
    newPreview?: File
    newSlides?: IImage[]
    tpcasId: string
    conceptId: string
    uiSettings: IUISettings
    companyId: string
}
export const editStorie = createAsyncThunk('storie/update',
    async(args: EditStorieArgs, thunkApi) => {
        const {companyId, storie, name, newPreview, newSlides, tpcasId, conceptId, uiSettings} = args
        const api = new StoriesService()
        const fileApi = new FilesService()  
        let prepairedPreview = null,
            preview = storie.preview
        if(newPreview) {
            prepairedPreview = await fileApi.uploadStoryFile(newPreview, `${storie}-preview`) as IStoriesAddImage
            preview = {
                hash: prepairedPreview.hash,
                body: prepairedPreview.body
            }
        }

        let slides: IStoriesSlides[] = []
        if(newSlides)
            for(let i = 0; i < newSlides.length; i++) {
                if(newSlides[i].imageFile) {
                    const prepSlide = await fileApi.uploadStoryFile(newSlides[i].imageFile as File, `${name.trim()}-${i}`)  as IStoriesAddImage
                    slides.push({
                        hash: prepSlide.hash,
                        body: prepSlide.body
                    })
                } else slides.push({
                    body: newSlides[i].image,
                    hash: newSlides[i].hash as string
                })
                
            }

        const newStorie: IStories = {
            ...storie,
            preview,
            name: name.trim(),
            slides,
            tpcasId, 
            conceptId,
            companyId,
            uiSettings
        }

        const response = await api.editStorie(newStorie)
        return
    }
)
export const clearEditState = createAsyncThunk('storie/clearUpdateeState',
    (_, thunkApi) => {
        return
    }
)
