import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IChangeStuff, IStuff, IStuffScope } from '../interfaces/Stuff'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class StuffService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/staff'
    get = async() => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IStuff>>(`${this.URL}/${this.PATH}/`, {
                params: {
                    limit: 10000,
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getScopes = async() => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IStuffScope[]>(`${this.URL}/api/v1/reference/type/staff_scopes`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getById = async(id: string) => {
        try {
            const response = await axiosInstance.get<IStuff>(`${this.URL}/${this.PATH}/${id}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    code = async(id: string) => {
        try {
            const response = await axiosInstance.put<IStuff>(`${this.URL}/${this.PATH}/${id}/code`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    update = async(stuff: IChangeStuff) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/${this.PATH}/`, stuff)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    add = async(stuff: IChangeStuff) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/${this.PATH}/`, stuff)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async(id: string, isDeleted = true) => {
        try {
            const response = await axiosInstance.delete(`${this.URL}/${this.PATH}/${id}`, {
                params: {
                    isDeleted
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

}