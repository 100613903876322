import { deleteCategory, disabledCategory, enableCategory } from './disabled'
import { clearCategorieState, getCategorieById, getCategoriesByConceptId } from './get'
import { clearUpdateCategoryState, sortCategories, updateCategory } from './update'


export const IikoCategoriesAction = {
    getCategoriesByConceptId,
    disabledCategory,
    getCategorieById,
    clearCategorieState,
    updateCategory,
    clearUpdateCategoryState,
    sortCategories,
    deleteCategory,
    enableCategory,
}