import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomerService } from '../../../api/CustomerService'
import { IApi } from '../../../interfaces/Api'
import { ICustomer } from '../../../interfaces/Customer'
import { RootState } from '../../reducers/rootReducer'

interface GetAllCustomersArgs {
    page: number
    limit?: number
}
export const getAllCustomers = createAsyncThunk('customers/getAll', 
    async(args: GetAllCustomersArgs, thunkApi) => {
        const {page, limit} = args
        const api = new CustomerService()
        const customers = await api.getCustomers( page, limit) as IApi<ICustomer>
        return customers
    }
)


interface GetCustomersByPhoneArgs {
    phone: string
    card: string
    name: string
    page: number
    limit?: number
}
export const getCustomersByArg = createAsyncThunk('customers/getByArg', 
    async(args: GetCustomersByPhoneArgs, thunkApi) => {
        const {page, limit, phone, card, name} = args
        const api = new CustomerService()
        const customers = await api.getCustomersSearch( phone, card, name, page, limit) as IApi<ICustomer>
        return customers
    }
)


export const getCustomerById = createAsyncThunk('customers/getById', 
    async(id: string, thunkApi) => {
        const api = new CustomerService()
        const customers = await api.getCustomerById( id) as ICustomer
        return customers
    }
)