import { RootState } from '../../reducers/rootReducer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TerminalsService } from '../../../api/TerminalService'


export const disableTerminal = createAsyncThunk('terminals/disableTerminal',
    async(id: string, thunkApi) => {
        const api = new TerminalsService()
        const term = await api.delete(id, {isDisabled: true})
        return id
    }
)

export const enableTerminal = createAsyncThunk('terminals/enableTerminal',
    async(id: string, thunkApi) => {
        const api = new TerminalsService()
        const term = await api.delete(id, {isDisabled: false})
        return id
    }
)

interface IDelete {
    id: string
    isDeleted: boolean
}
export const deleteTerminal = createAsyncThunk('terminals/deleteTerminal',
    async(args: IDelete, thunkApi) => {
        const api = new TerminalsService()
        const {id, isDeleted} = args
        const term = await api.delete(id, {isDeleted})
        return id
    }
)