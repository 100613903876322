import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilesService } from '../../../api/FilesService'
import { PushService } from '../../../api/PushService'
import { IChangePush, IPush } from '../../../interfaces/Push'
import { IStoriesAddImage, IStoriesSlides } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'


interface UpdatePushArgs {
    push: IChangePush
    image?: File
}
export const updatePush = createAsyncThunk('push/update',
    async(args: UpdatePushArgs, thunkApi) => {
        const {push, image} = args
        const api = new PushService()
        let _push: IChangePush = {
            _id: push._id,
            id: push._id,
            title: push.title,
            subtitle: push.subtitle,
            body: push.body,
            image: push.image,
            isDeleted: push.isDeleted,
            isDisabled: push.isDisabled,
            segmentId: push.segmentId,
            triggerId: push.triggerId,
            tpcasId: push.tpcasId,
            companyId: push.companyId
        }
        if(image) {
            const fileApi = new FilesService()
            const prepairedPreview: IStoriesAddImage = await fileApi.uploadNotificationFile(image, `${push.title.trim()}-preview`) as IStoriesAddImage
            const preview: IStoriesSlides = {
                hash: prepairedPreview.hash,
                body: prepairedPreview.body
            }
            _push = {
                ..._push,
                image: preview
            }
        }

        const response = await api.update( _push) as IPush
        return response
            
    }
)
export const clearUpdatePushState = createAsyncThunk('push/clearUpdateeState',
    (_, thunkApi) => {
        return
    }
)
