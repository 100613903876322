import { createAsyncThunk } from '@reduxjs/toolkit'
import { FeaturedProductsService } from '../../../api/FeaturedProductsService'
import { IApi } from '../../../interfaces/Api'
import { IFeaturedProduct } from '../../../interfaces/FeaturedProduct'
import { RootState } from '../../reducers/rootReducer'

interface UpdateFeaturedProductsArgs {
    conceptId: string
    products: string[]
}
export const updateFeaturedProducts = createAsyncThunk('featuredProducts/update',
    async(args: UpdateFeaturedProductsArgs, thunkApi) => {
        const {conceptId, products} = args
        const api = new FeaturedProductsService()
        const {featuredProducts} = (thunkApi.getState() as RootState).featuredProductsReducer
        let res
        if(featuredProducts.length === 0) {
            res = await api.add( conceptId, products) as IApi<IFeaturedProduct>
        } else {
            res = await api.update( conceptId, products) as IApi<IFeaturedProduct>
        }
        
        return res
    }
)

export const updateFeaturedProductsClearState = createAsyncThunk('featuredProducts/updateFeaturedProductsClearState',
    (_, thunkApi) => {
        return true
    }
)