import axios, { AxiosError } from 'axios'
export const axiosInstance = axios.create()


axiosInstance.interceptors.request.use(async req => {
    const token = localStorage.getItem('token')
    if(req.headers) {
        req.headers.Authorization = `Bearer ${token}`
    }
       
    return req
})
axiosInstance.interceptors.response.use(async res => {    
    return res
}, (error: AxiosError) => {
    if(error.response?.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('company')
        window.dispatchEvent(new Event('storage'))
    }
    return Promise.reject(error)
})
