import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Loader } from '../../components/Loader/Loader'
import { Link } from 'react-router-dom'
import './Stuffs.scss'
import { IStuff } from '../../interfaces/Stuff'
import { StuffService } from '../../api/StuffService'
import { Icon } from '../../components/UI/Icon/Icon'
import { StuffTable } from '../../components/StuffTable/StuffTable'

const Stuff = () => {
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [stuffs, setStuffs] = useState<IStuff[]>([])

    useEffect(() => {
        document.title = 'Сотрудники'
        return () => {}
    }, [])

    useEffect(() => {
        let isMount = true

        const getStuffs = async () => {
            try {
                const api = new StuffService()
                const _stuffs = await api.get()
                if (isMount) {
                    setStuffs(_stuffs.data)
                }
            } catch (error: any) {
                console.error(error)
                setStatus({
                    loading: false,
                    error: error.message,
                })
            }
        }
        getStuffs()
        return () => {
            isMount = false
        }
    }, [])
    const onDelete = (deleteId: string) => {
        setStuffs((prev) => prev.filter((p) => p._id !== deleteId))
    }
    return (
        <section className='stuff'>
            <header className='stuff__header'>
                <div className='stuff__header--name'>{'Сотрудники'}</div>
                <Link to={'./add'} className='stuff__header--btn-add'>
                    <Icon name='Plus' />
                    Добавить
                </Link>
            </header>
            {status.loading ? (
                <Loader />
            ) : (
                <StuffTable stuffs={stuffs} onDelete={onDelete} />
            )}
        </section>
    )
}

export default Stuff
