import React, { FC } from 'react'
import './TableRow.scss'

interface TableRowProps {
    data: any[]
    onClick?: (e: React.MouseEvent) => void
    className?: string
}

export const TableRow: FC<TableRowProps> = ({
    data,
    onClick,
    className = '',
    children,
}) => {
    return (
        <div onClick={onClick} className={`ui-table-row ${className}`}>
            {data.map((item, index) => (
                <div key={`tablerow__${index}`} className='ui-table-row__data'>
                    {item}
                </div>
            ))}
            {children}
        </div>
    )
}
