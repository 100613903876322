import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ICompany } from '../interfaces/Company'
import { Api} from './Api'
import { axiosInstance } from './axios'

export class CompanyService extends Api {
    constructor() {
        super()
    }

    getCompanies = async(page: number, limit?: number, token?: string) => {
        try {
            let _limit = 1000
            if(limit) _limit = limit
            const _token = localStorage.getItem('token')
            const response = await axios.get<IApi<ICompany>>(`${this.URL}/api/v1/companies/?limit=${_limit}&page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${token || _token}`
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}