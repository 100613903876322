import { Button } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { ContentService } from '../../api/ContentService'
import { FilesService } from '../../api/FilesService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { DragNDropImage } from '../../components/DragNDropImage/DragNDropImage'
import { CustomModal } from '../../components/Modal/CustomModal'
import { TpCasDropDown } from '../../components/TpCasDropDown/TpCasDropDown'
import { Input } from '../../components/UI/Input/Input'
import { ISelectOptions, Select } from '../../components/UI/Select/Select'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { IUISettings } from '../../interfaces/Stories'
import { ITpCas } from '../../interfaces/TpCas'
import './AddContent.scss'

const options = [
    {
        id: 'notice',
        value: 'Уведомление',
    },
    {
        id: 'slide',
        value: 'Слайдер Desktop',
    },
    {
        id: 'mobile',
        value: 'Слайдер Mobile',
    },
]
const AddContent = () => {
    useEffect(() => {
        document.title = 'Добавление контента'
    }, [])
    const [img, setImg] = useState('')
    const [file, setFile] = useState<File>()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [text, setText] = useState('')
    const [color, setColor] = useState('#FFFFFF')
    const [type, setType] = useState<ISelectOptions>(options[0])
    const [conceptId, setConcept] = useState<string | null>(null)
    const [tpcas, setTpCas] = useState<ITpCas>()
    const [isOpen, setOpen] = useState(false)
    const setImageHandler = (image: string, file: File | null, error?: string) => {
        if (file) {
            setImg(image)
            setFile(file)
        }
        if (error) {
            console.log(error)
        }
    }
    const removeImageHandler = () => {
        setImg('')
        setFile(undefined)
    }
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })

    // useEffect(() => {
    //     console.log(text)

    //     console.log(text.replace(/\r?\n/g, '\n'))
    // }, [text])

    const { admin } = useTypedSelector((state) => state.userReducer)
    const onSubmit = () => {
        const companyId = localStorage.getItem('company')
        if (file || type.id === 'notice') {
            if (name.trim().length > 0) {
                const addContent = async () => {
                    try {
                        setStatus({
                            loading: true,
                            error: '',
                        })
                        const apiFile = new FilesService()
                        const api = new ContentService()
                        let _file
                        if (file && type.id !== 'notice') _file = await apiFile.uploadBannerFile(file, name)
                        const _res = await api.addContent({
                            name,
                            url,
                            conceptId,
                            tpcasId: tpcas?._id || null,
                            image: _file ? _file.body : null,
                            type: type.id as string,
                            text: text.replace(/\r?\n/g, '\n'),
                            companyId: companyId || admin.companyId,
                            isDeleted: false,
                            order: 0,
                            uiSettings: {
                                url,
                                text,
                                color,
                            },
                            // isHidden: false,
                            // isDisabled: false,
                        })

                        setStatus({
                            loading: false,
                            error: '',
                        })
                    } catch (e: any) {
                        console.error(e)
                        setStatus({
                            loading: false,
                            error: e.message,
                        })
                    } finally {
                        setOpen(true)
                    }
                }
                addContent()
            }
        } else {
            alert('Загрузите изображение')
        }
    }
    return (
        <section className='add-content-page'>
            <header className='add-content-page__header'>
                <div className='add-content-page__header--name'>Контент/Добавить</div>
            </header>

            <div className='add-content-page__content'>
                <div className='add-content-page__content-inputs'>
                    <div className='inputs-block'>
                        <label>Название</label>
                        <Input className='add-content-input' value={name} onChange={(e) => setName(e.target.value)} />
                        <label>Ссылка</label>
                        <Input className='add-content-input' value={url} onChange={(e) => setUrl(e.target.value)} />
                    </div>
                    <div className='inputs-block'>
                        <label>Текст</label>
                        <textarea style={undefined} value={text} onChange={(e) => setText(e.target.value)} className='add-content-textarea' />
                    </div>
                    <div className='dropdown-block'>
                        {type.id !== 'notice' && (
                            <>
                                {img ? (
                                    <div className='dropdown-block__preview-wrapper'>
                                        <img className='dropdown-block__preview' src={img} alt='preview' />
                                        <div className='dropdown-block__preview-remove' onClick={removeImageHandler}></div>
                                    </div>
                                ) : null}
                                <DragNDropImage isEmpty={img.length === 0} onChange={setImageHandler} />
                            </>
                        )}
                    </div>
                    <div className='select-type'>
                        <label>Концепция</label>
                        <ConceptDropDown additionalValue={{ id: 'addData', value: 'Общий' }} onChange={(c) => setConcept(c._id === 'addData' ? null : c._id)} />
                    </div>
                    <div className='select-type'>
                        <label>Тип контента</label>
                        <Select options={options} onChange={setType} defaultValue={type} />
                    </div>
                    <div className='tpcas-type'>
                        <label>TpCas</label>
                        <TpCasDropDown onChange={setTpCas} />
                    </div>
                    {type.id !== 'notice' && (
                        <div className='content-color'>
                            <label>Цвет</label>
                            <input className='input-color' type={'color'} value={color} onChange={(e) => setColor(e.target.value)} />
                        </div>
                    )}
                </div>
                <Button isLoading={status.loading} className='add-content-page__submit' onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/contents'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось добавить контент'
                message='Контент успешно добавлен'
                details={status.error}
            />
        </section>
    )
}

export default AddContent
