import { createAsyncThunk } from '@reduxjs/toolkit'
import { FilesService } from '../../../api/FilesService'
import { IikoProductsService } from '../../../api/IikoProductsService'
import { IApi } from '../../../interfaces/Api'
import { IIikoProducts, IUpdateIikoProducts } from '../../../interfaces/IikoProducts'
import { IStoriesAddImage, IStoriesSlides } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'
interface UpdateArgs {
    product: IIikoProducts
    image?: File
    previewImage?: File
    isAdd?: boolean
}
export const updateIikoProduct = createAsyncThunk('iikoProduct/update',
    async(args: UpdateArgs, thunkApi) => {
        const {product, image, isAdd = false, previewImage} = args
        const api = new IikoProductsService()
        let _product: IUpdateIikoProducts = {
            ...product,
            badges: product.badges?.map(b => b._id),
            featured: product.featured?.map(f => f._id),
            modifiers: product.modifiers?.map(m => m._id),
        }
        if(image) {
            const fileApi = new FilesService()
            const prepairedPreview: IStoriesAddImage = await fileApi.uploadNotificationFile(image, `${_product.name.trim()}-image`) as IStoriesAddImage
            const preview: IStoriesSlides = {
                hash: prepairedPreview.hash,
                body: prepairedPreview.body
            }
            _product = {
                ..._product,
                image: preview
            }
        }
        if(previewImage) {
            const fileApi = new FilesService()
            const prepairedPreview: IStoriesAddImage = await fileApi.uploadNotificationFile(previewImage, `${_product.name.trim()}-preview`) as IStoriesAddImage
            const preview: IStoriesSlides = {
                hash: prepairedPreview.hash,
                body: prepairedPreview.body
            }
            _product = {
                ..._product,
                previewImage: preview
            }
        }
        let response
        if(!isAdd)
            response = await api.update( _product, !!_product.rrule) as IIikoProducts
        else response = await api.add( _product, !!_product.rrule) as IIikoProducts
        return response  
    }
)
export const clearUpdateIikoProductState = createAsyncThunk('iikoProduct/clearState',
    (_, thunkApi) => {
        return
    }
)


export const disabledProduct = createAsyncThunk('iikoProduct/disabledProduct',
    async(pr: IIikoProducts, thunkApi) => {
        const api = new IikoProductsService()
        const response = await api.delete(pr._id, {isDisabled: true}, !!pr.rrule) as IIikoProducts
        return pr._id
    }
)

export const enableProduct = createAsyncThunk('iikoProduct/enableProduct',
    async(pr: IIikoProducts, thunkApi) => {
        const api = new IikoProductsService()
        const response = await api.delete(pr._id, {isDisabled: false}, !!pr.rrule) as IIikoProducts
        return pr._id
    }
)

interface IDelete {
    id: string
    isDeleted: boolean
    isSmart: boolean
}
export const deleteProduct = createAsyncThunk('iikoProduct/deleteProduct',
    async(args: IDelete, thunkApi) => {
        const api = new IikoProductsService()
        const {id, isDeleted, isSmart} = args
        const response = await api.delete(id, {isDeleted}, isSmart) as IIikoProducts
        return id
    }
)



interface SortProductsArgs {
    products: IIikoProducts[]
    page: number
}
export const sortProducts = createAsyncThunk('iikoProduct/sortProducts',
    async(args: SortProductsArgs, thunkApi) => {
        const {products, page} = args
        const api = new IikoProductsService()
        const pUpdate = products.map((p, index) => ({
            _id: p._id, 
            order: (page - 1)*10+index
        }))
        const response = await api.sort(pUpdate)
        return pUpdate
    }
)

