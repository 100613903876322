import { Button, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import ReactDom from 'react-dom'
import { v4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { CustomerService } from '../../api/CustomerService'
import { IikoOrdersService } from '../../api/IikoOrdersService'
import { UserService } from '../../api/UserService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { InputDish } from '../../components/InputDish/InputDish'
import { ModificatorsSelect } from '../../components/ModificatorsSelect/ModificatorsSelect'
import { TerminalSelect } from '../../components/TerminalSelect/TerminalSelect'
import { Input } from '../../components/UI/Input/Input'
import { ISelectOptions, Select } from '../../components/UI/Select/Select'
import { IConcept } from '../../interfaces/Concept'
import { IIikoProducts, IModifiers } from '../../interfaces/IikoProducts'
import {
    IOrder,
    IOrderDeliveryTypes,
    IOrderPaymentTypes,
    IOrderProduct,
    IOrderProductModifier,
} from '../../interfaces/Order'
import './AddOrder.scss'
import { ModificatorsGroupsSelect } from '../../components/ModificatorsGroupsSelect/ModificatorsGroupsSelect'
import { ModificatorsOptionsSelect } from '../../components/ModificatorsOptionsSelect/ModificatorsOptionsSelect'
import { getTotal } from '../../utils/getTotal'
import { AddProductWithModifierModal } from '../../components/Modal/AddProductWithModifierModal/AddProductWithModifierModal'
import { AddOrderProductsList } from './AddOrderProductsList'
import { ORderStatesDropDown } from '../../components/OrderStatesDropDown/OrderStatesDropDown'
import { CustomModal } from '../../components/Modal/CustomModal'
const initState: IOrder = {
    _id: '',
    comment: '',
    customerId: '',
    address: {
        city: '',
        street: '',
        home: '',
        flat: 0,
        entrance: 0,
        floor: 0,
    },
    terminalId: null,
    deliveryTypeId: '',
    personsCount: 0,
    deliveryTime: '',
    paymentTypeId: '',
    products: [],
    withdrawBonuses: 0,
    awsMessageId: '',
    conceptId: '',
    creationError: '',
    creationStatus: '',
    externalId: '',
    sourceId: '',
    telegramMessageId: '',
    statusId: '',
    number: 0,
    change: 0,
    courierId: '',
}

function reducer(state: IOrder, action: any): IOrder {
    switch (action.type) {
        case 'setstate':
            return {
                ...action.payload,
                products: action.payload.products.map((p: IOrderProduct) => ({
                    ...p,
                    frontEndId: v4(),
                })),
            }
        case 'setstatus':
            return {
                ...state,
                statusId: action.payload,
            }
        case 'setstreet':
            return {
                ...state,
                address: { ...state.address!, street: action.payload },
            }
        case 'sethome':
            return {
                ...state,
                address: { ...state.address!, home: action.payload },
            }
        case 'setflat':
            return {
                ...state,
                address: { ...state.address!, flat: action.payload },
            }
        case 'setentrance':
            return {
                ...state,
                address: { ...state.address!, entrance: action.payload },
            }
        case 'setfloor':
            return {
                ...state,
                address: { ...state.address!, floor: action.payload },
            }
        case 'setcomment':
            return { ...state, comment: action.payload }
        case 'setdeliverytime':
            return { ...state, deliveryTime: action.payload }
        case 'addproduct':
            return { ...state, products: [...state.products, action.payload] }
        case 'setdeliverytype': {
            if (action.payload) {
                return {
                    ...state,
                    deliveryTypeId: action.payload,
                    address: null,
                }
            }
            return {
                ...state,
                deliveryTypeId: action.payload,
                terminalId: null,
            }
        }
        case 'setpaymenttype': {
            return { ...state, paymentTypeId: action.payload }
        }
        case 'setchange': {
            return { ...state, change: action.payload }
        }
        case 'setterminalid':
            return { ...state, terminalId: action.payload }
        case 'increaseproduct': {
            return {
                ...state,
                products: state.products.map((p) => ({
                    ...p,
                    amount:
                        p.frontEndId === action.payload ? p.amount++ : p.amount,
                })),
            }
        }
        case 'decreaseproduct': {
            return {
                ...state,
                products: state.products
                    .map((p) => ({
                        ...p,
                        amount:
                            p.frontEndId === action.payload
                                ? p.amount--
                                : p.amount,
                    }))
                    .filter((p) => p.amount > 0),
            }
        }
        default:
            throw new Error()
    }
}

export const AddOrder = ({ isEdit = false }) => {
    const [state, dispatch] = useReducer(reducer, initState)
    const [concept, setConcept] = useState<IConcept>()
    const [deliveryTypes, setDeliveryTypes] = useState<IOrderDeliveryTypes[]>(
        []
    )
    const [paymentTypes, setPaymentTypes] = useState<IOrderPaymentTypes[]>([])
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [product, setProduct] = useState<IIikoProducts>()
    const setProductHandler = (_product: IIikoProducts) => {
        const _pr: IOrderProduct = {
            externalId: _product.externalId,
            code: _product.code,
            name: _product.name,
            amount: 1,
            price: _product.price,
            productModifiers: [],
            weight: _product.weight,
        }
        if (_product.modifiers.length === 0) {
            return dispatch({
                type: 'addproduct',
                payload: _pr,
            })
        }

        setProduct(_product)
        onOpen()
    }

    const fioRef = useRef<HTMLInputElement>(null)
    const phoneRef = useRef<HTMLInputElement>(null)
    const { id } = useParams()
    useEffect(() => {
        let isMount = true
        if (isEdit && id) {
            const getOrderById = async () => {
                try {
                    const api = new IikoOrdersService()
                    const _order = await api.getById(id)
                    const apiUser = new CustomerService()
                    const _user = await apiUser.getCustomerById(
                        _order.customerId
                    )
                    if (isMount) {
                        dispatch({ type: 'setstate', payload: _order })
                        if (fioRef.current)
                            fioRef.current.value = `${_user.firstName} ${_user.lastName}`
                        if (phoneRef.current)
                            phoneRef.current.value = `${_user.phone}`
                    }
                } catch (error) {
                    console.error(error)
                }
            }
            getOrderById()
        }
        return () => {
            isMount = false
        }
    }, [])

    const getPaymentType = useMemo(() => {
        return paymentTypes.find((p) => p._id === state.paymentTypeId)
    }, [state.paymentTypeId, paymentTypes])

    const getDeliveryType = useMemo(() => {
        return deliveryTypes.find((p) => p._id === state.deliveryTypeId)
    }, [state.deliveryTypeId, deliveryTypes])

    useEffect(() => {
        let isMount = true
        const getOrders = async () => {
            try {
                const api = new IikoOrdersService()
                // const _orders = await api.get(page, 20)
                const _payment_types = await api.getPaymentTypes(concept?._id)

                const _delivery_types = await api.getDeliveryTypes(concept?._id)
                if (isMount) {
                    // setOrders([...orders, ..._orders.data])
                    // setPages(_orders.pages)
                    setPaymentTypes(_payment_types)
                    setDeliveryTypes(_delivery_types)
                    if (!isEdit)
                        dispatch({
                            type: 'setdeliverytype',
                            payload: _delivery_types[0]?._id || '',
                        })
                }
            } catch (e) {
                console.error(e)
            }
        }

        if (concept) getOrders()
        return () => {
            isMount = false
        }
    }, [concept])
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [isOpenModal, setOpen] = useState(false)
    const onSubmit = async () => {
        try {
            setStatus({
                loading: true,
                error: '',
            })
            const api = new IikoOrdersService()
            const res = await api.update(state)
            setStatus({
                loading: false,
                error: '',
            })
        } catch (e: any) {
            console.log(e)
            setStatus({
                loading: false,
                error: e.message,
            })
        } finally {
            setOpen(true)
        }
    }

    return (
        <section className='edit-order'>
            <header className='edit-order__header'>
                {isEdit ? (
                    <div className='edit-order__header--name'>
                        Заказы/{state.number}
                    </div>
                ) : (
                    <div className='edit-order__header--name'>Заказы/Новый</div>
                )}
                <Button
                    isLoading={status.loading}
                    className='edit-order__header-submit'
                    onClick={onSubmit}>
                    Сохранить
                </Button>
            </header>
            <div className='edit-order__content'>
                <div className='edit-order__content-concept'>
                    <h1>Концепция</h1>
                    {isEdit ? (
                        <ConceptDropDown
                            defaultValue={state.conceptId}
                            onChange={setConcept}
                            readOnly={true}
                        />
                    ) : (
                        <ConceptDropDown onChange={setConcept} />
                    )}
                </div>
                <div className='edit-order__content-client-info'>
                    <h1 className='edit-order__content-client-info__header'>
                        Клиент
                    </h1>
                    <div className='client-info__inputs'>
                        <div>
                            <label>ФИО</label>
                            <Input
                                ref={fioRef}
                                placeholder={'ФИО'}
                                readonly={isEdit}
                            />
                        </div>
                        <div>
                            <label>Телефон</label>
                            <Input
                                placeholder={'+7 000 000 00 00'}
                                readonly={isEdit}
                                ref={phoneRef}
                            />
                        </div>
                        {concept && (
                            <div>
                                <label>Статус</label>
                                <ORderStatesDropDown
                                    conceptId={concept._id}
                                    onChange={(opt) =>
                                        dispatch({
                                            type: 'setstatus',
                                            payload: opt._id,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className='edit-order__content-delivery-info'>
                    {deliveryTypes.map((t) => (
                        <span
                            key={t._id}
                            className={`delivery-info__header ${
                                getDeliveryType?._id === t._id ? '--active' : ''
                            }`}
                            onClick={() =>
                                dispatch({
                                    type: 'setdeliverytype',
                                    payload: t._id,
                                })
                            }>
                            {t.name}
                        </span>
                    ))}
                    {deliveryTypes.length > 0 ? (
                        getDeliveryType?.isSelfService ? (
                            <div className='delivery-info__inputs'>
                                <div>
                                    {concept && (
                                        <TerminalSelect
                                            onChange={(t) =>
                                                // console.log(t)

                                                dispatch({
                                                    type: 'setterminalid',
                                                    payload: t,
                                                })
                                            }
                                            conceptId={concept._id}
                                            _label={'Терминал'}
                                            className='delivery-info__inputs-terminal'
                                        />
                                    )}
                                </div>
                                <div>
                                    <label>Комментарий</label>
                                    <Input
                                        value={state.comment || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setcomment',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Время доставки</label>
                                    <Input
                                        value={state.deliveryTime || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setdeliverytime',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='delivery-info__inputs'>
                                <div>
                                    <label>Улица</label>
                                    <Input
                                        value={state.address?.street || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setstreet',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Дом</label>
                                    <Input
                                        value={state.address?.home || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'sethome',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Квартира</label>
                                    <Input
                                        value={state.address?.flat || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setflat',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Подъезд</label>
                                    <Input
                                        value={state.address?.entrance || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setentrance',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Этаж</label>
                                    <Input
                                        value={state.address?.floor || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setfloor',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Комментарий</label>
                                    <Input
                                        value={state.comment || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setcomment',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Время доставки</label>
                                    <Input
                                        value={state.deliveryTime || ''}
                                        onChange={(e) =>
                                            dispatch({
                                                type: 'setdeliverytime',
                                                action: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )
                    ) : null}
                </div>
                {paymentTypes.length > 0 && (
                    <div className='edit-order__content-payment-type'>
                        <label>Тип оплаты</label>
                        <Select
                            options={paymentTypes.map((t) => ({
                                id: t._id,
                                value: t.name,
                            }))}
                            defaultValue={
                                getPaymentType
                                    ? {
                                          id: getPaymentType._id,
                                          value: getPaymentType.name,
                                      }
                                    : undefined
                            }
                            onChange={(data) =>
                                dispatch({
                                    type: 'setpaymenttype',
                                    payload: data.id,
                                })
                            }
                        />
                        {getPaymentType?.code === 'cash' && (
                            <div className='edit-order__content-payment-type--change'>
                                Сдача с{' '}
                                <Input
                                    type='number'
                                    value={state.change?.toString() || 0}
                                    onChange={({ target }) =>
                                        dispatch({
                                            type: 'setchange',
                                            payload: +target.value,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className='edit-order__content-products-info'>
                    <h1 className='edit-order__content-products-info__header'>
                        Заказ
                    </h1>
                    <div className='products-info__add-product'>
                        {concept && (
                            <div>
                                <InputDish
                                    label='Добавить блюдо'
                                    conceptId={concept._id}
                                    onChange={setProductHandler}
                                    terminalId={product?.terminalId}
                                />
                            </div>
                        )}
                    </div>
                    <div className='products-info__list'>
                        <div className='products-info__list--header'>
                            <div></div>
                            <div>Название</div>
                            <div>Модификатор</div>
                            <div>Кол-во</div>
                            <div>Цена</div>
                        </div>
                        {state.products.map((p) => (
                            <AddOrderProductsList
                                key={p.frontEndId}
                                product={p}
                                onChange={(type, payload) =>
                                    dispatch({ type, payload })
                                }
                            />
                        ))}
                        <div className='products-info__list-item --total'>{`Итого: ${getTotal(
                            state.products
                        )}₽`}</div>
                    </div>
                </div>
            </div>
            {product && (
                <AddProductWithModifierModal
                    isOpen={isOpen}
                    onClose={onClose}
                    product={product}
                    addProduct={(_product) =>
                        dispatch({
                            type: 'addproduct',
                            payload: _product,
                        })
                    }
                />
            )}
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/orders'
                clearState={() => {}}
                open={isOpenModal}
                toggleWindow={setOpen}
                errorMsg='Не удалось добавить\изменить заказ'
                message='Заказ успешно добавлен\обновлен'
                details={status.error}
            />
        </section>
    )
}
