import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IUser } from '../../interfaces/User'
import { getAdmin, getAllUsers, getUserById } from '../actions/user/get'
import { UserState } from '../types/user'

const initialState: UserState = {
    limit: 0,
    page: 0,
    pages: 0,
    users: [],
    user: {} as IUser,
    admin: {} as IUser,
    getUsers: {
        loading: false,
        error: ''
    },
    getUser: {
        loading: false,
        error: '',
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        removeAdmin: (state) => {
            state.admin = {} as IUser
        }
    },
    extraReducers: {
        [getAllUsers.pending.type]: (state) => {
            state.getUsers.loading = true
            state.getUsers.error = ''
        },
        [getAllUsers.fulfilled.type]: (state, action: PayloadAction<IApi<IUser>>) => {
            state.getUsers.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.users = action.payload.data
        },
        [getAllUsers.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getUsers.loading = false
            state.getUsers.error = action.error.message
        },
        [getUserById.pending.type]: (state) => {
            state.getUser.loading = true
            state.getUser.error = ''
        },
        [getUserById.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
            state.getUser.loading = false
            state.user = action.payload
        },
        [getUserById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getUser.loading = false
            state.getUser.error = action.error.message
        },
        [getAdmin.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
            state.admin = action.payload
        },
    }
})

export const userReducer = userSlice.reducer
export const {removeAdmin} = userSlice.actions