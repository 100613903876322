import { combineReducers } from 'redux'
import { terminalsReducer } from './terminalsReducer'
import { authReducer } from './authReducer'
import { terminalsGroupReducer } from './terminalsGroupReducer'
import { lastOrdersReducer } from './lastOrdersReducer'
import { pushReducer } from './pushReducer'
import { storiesReducer } from './storiesReducer'
import { segmentReducer } from './segmentReducer'
import { iikoProductsReducer } from './iikoProductsReducer'
import { conceptsReducer } from './conceptsReducer'
import { iikoCategoriesReducer } from './iikoCategoriesReducer'
import { promoReducer } from './promoReducer'
import { customerReducer } from './customerReducer'
import { triggerReducer } from './triggerReducer'
import { badgeReducer } from './badgeReducer'
import { orderReducer } from './orderReducer'
import { userReducer } from './userReducer'
import { iikoModifiersReducer } from './iikoModifiersReducer'
import { iikoModifiersOptionReducer } from './modifiersOptionReducer'
import { featuredProductsReducer } from './featuredProductsReducer'
import { dashboardReducer } from './dashboardReducer'

export const rootReducer = combineReducers({
    authReducer,
    terminalsReducer,
    terminalsGroupReducer,
    lastOrdersReducer,
    pushReducer,
    storiesReducer,
    segmentReducer,
    iikoProductsReducer,
    conceptsReducer,
    iikoCategoriesReducer,
    promoReducer,
    customerReducer,
    triggerReducer,
    badgeReducer,
    orderReducer,
    userReducer,
    iikoModifiersReducer,
    iikoModifiersOptionReducer,
    featuredProductsReducer,
    dashboardReducer
})

export type RootState = ReturnType<typeof rootReducer>