import axios, { AxiosError } from 'axios'
import { Api } from './Api'
import { IStoriesAddImage } from '../interfaces/Stories'
import { axiosInstance } from './axios'

export class FilesService extends Api {
    constructor() {
        super()
    }
    getGeoJson = async(url: string) => {
        try {
            const response = await axiosInstance.get(url, {
                headers: {
                    'Content-Type': 'application/geo+json'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadNotificationFile = async (image: File, name: string) => {
        try {
            const fd = new FormData()
            fd.append('file', image, `${name}.png`)
            const response = await axiosInstance.post<IStoriesAddImage>(`${this.URL}/api/v1/files/notification`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadStoryFile = async (image: File, name: string) => {
        try {
            const fd = new FormData()
            fd.append('file', image, `${name}.png`)
            const response = await axiosInstance.post<IStoriesAddImage>(`${this.URL}/api/v1/files/story`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadNomenclatureFile = async (image: File, name: string) => {
        try {
            const fd = new FormData()
            fd.append('file', image, `${name}.png`)
            const response = await axiosInstance.post<IStoriesAddImage>(`${this.URL}/api/v1/files/nomenclature`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadBadgeFile = async (image: File, name: string) => {
        try {
            const fd = new FormData()
            fd.append('file', image, `${name}.png`)
            const response = await axiosInstance.post<IStoriesAddImage>(`${this.URL}/api/v1/files/badge`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadBannerFile = async (image: File, name: string) => {
        try {
            const fd = new FormData()
            fd.append('file', image, `${name}.png`)
            const response = await axiosInstance.post<IStoriesAddImage>(`${this.URL}/api/v1/files/banner`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    uploadMap = async (map: File, terminalId: string) => {
        try {
            const fd = new FormData()
            fd.append('file', map, `${terminalId}.geojson`)
            const response = await axiosInstance.post(`${this.URL}/api/v1/files/geojson`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    terminal_id: terminalId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}