import { createAsyncThunk } from '@reduxjs/toolkit'
import { IikoModifiersService } from '../../../api/IikoModifiersService'
import { RootState } from '../../reducers/rootReducer'
interface DisableArgs {
    id: string
    isDisabled?: boolean
    isDeleted?: boolean
}
export const disableModifier = createAsyncThunk('modifiers/disable', 
    async(args: DisableArgs, thunkApi) => {
        const {id, isDisabled, isDeleted} = args
        const api = new IikoModifiersService()
        const response = await api.disableModifier( id, isDisabled, isDeleted)
        return id
    }
)


export const disableModifierOption = createAsyncThunk('modifiers/disableOption', 
    async(args: DisableArgs, thunkApi) => {
        const {id, isDisabled, isDeleted} = args
        const api = new IikoModifiersService()
        const response = await api.disableModifierOption( id, isDisabled, isDeleted)
        return id
    }
)