import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomerService } from '../../api/CustomerService'
import { OrderStatesService } from '../../api/OrderStatesService'
import { TerminalsService } from '../../api/TerminalService'
import { ICustomer } from '../../interfaces/Customer'
import {
    IOrder,
    IOrderDeliveryTypes,
    IOrderPaymentTypes,
} from '../../interfaces/Order'
import { IOrderState } from '../../interfaces/OrderState'
import { ITerminal } from '../../interfaces/Terminals'
import { convertToString } from '../../utils/dateConvert'
import { getTotal } from '../../utils/getTotal'
import { TableRow } from '../UI/Table/TableRow/TableRow'
interface OrdersTablePropsProps {
    order: IOrder
    paymentTypes: IOrderPaymentTypes[]
    deliveryTypes: IOrderDeliveryTypes[]
}
export const OrdersTableRow: FC<OrdersTablePropsProps> = ({
    order,
    paymentTypes,
    deliveryTypes,
}) => {
    const [terminal, setTerminal] = useState<ITerminal>()
    const [customer, setCustomers] = useState<ICustomer>()
    const [state, setState] = useState<IOrderState>()
    useEffect(() => {
        let isMount = true
        const getTerminal = async (id: string) => {
            try {
                const api = new TerminalsService()
                const _terminal = await api.getById(id)
                if (isMount) setTerminal(_terminal)
            } catch (e) {
                console.error(e)
            }
        }
        const getCustomer = async (id: string) => {
            try {
                const api = new CustomerService()
                const _customer = await api.getCustomerById(id)
                if (isMount) setCustomers(_customer)
            } catch (e) {
                console.error(e)
            }
        }
        const getStatus = async (id: string) => {
            try {
                const api = new OrderStatesService()
                const _state = await api.getById(id)
                if (isMount) setState(_state)
            } catch (e) {
                console.error(e)
            }
        }
        if (order.terminalId) getTerminal(order.terminalId)
        getCustomer(order.customerId)
        if (order.statusId) getStatus(order.statusId)
        return () => {
            isMount = false
        }
    }, [])
    const toPhoneString = useMemo(() => {
        let phone = ''
        if (customer) {
            phone = customer.phone?.toString()
        }
        if (!phone) return '-'
        return `+7 (${phone.substring(0, 3)}) ${phone.substring(
            3,
            6
        )}-${phone.substring(6, 8)}-${phone.substring(8)}`
    }, [customer?.phone])

    const getPaymentType = useMemo(() => {
        return paymentTypes.find((p) => p._id === order.paymentTypeId)
    }, [order.paymentTypeId, paymentTypes])

    const getDeliveryType = useMemo(() => {
        return deliveryTypes.find((p) => p._id === order.deliveryTypeId)
    }, [order.deliveryTypeId, deliveryTypes])
    const navigate = useNavigate()
    const preparedData = [
        // order.personsCount,
        order.number,
        <div>
            <span>{terminal?.name || ''}</span>
            <small>{terminal?.address || ''}</small>
        </div>,
        <div>
            <span>{`${customer?.firstName || ''} ${
                customer?.lastName || ''
            }`}</span>
            <small>{toPhoneString}</small>
        </div>,
        <div>
            <span>
                {!getDeliveryType?.isSelfService
                    ? `${order.address?.street} ${order.address?.home}`
                    : terminal?.address}
            </span>
            <small>{getDeliveryType?.name}</small>
        </div>,
        <div>
            <span>{getTotal(order.products)} ₽</span>
            <small>{getPaymentType?.name}</small>
        </div>,
        // convertToString(order.deliveryTime || ''),
        convertToString(order.createdAt || ''),
        state?.name || '-',
    ]
    return (
        <TableRow
            onClick={() => navigate(`./edit/${order._id}`)}
            data={preparedData}
        />
    )
}
