import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IFeaturedProduct } from '../interfaces/FeaturedProduct'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class FeaturedProductsService extends Api {
    constructor() {
        super()
    }

    get = async( conceptId: string, page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IFeaturedProduct>>(`${this.URL}/api/v1/featured_products/?limit=${_limit}&page=${page}`, {
                params: {
                    companyId,
                    conceptId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    add = async( conceptId: string, products: string[]) => {
        try {
            const companyId = localStorage.getItem('company')
            const responsePost = await axiosInstance.post<IFeaturedProduct>(`${this.URL}/api/v1/featured_products/`, {
                conceptId,
                products,
                companyId
            })
            const response = await axiosInstance.get<IApi<IFeaturedProduct>>(`${this.URL}/api/v1/featured_products/?limit=10&page=1`, {
                params: {
                    companyId,
                    conceptId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    update = async( conceptId: string, products: string[]) => {
        try {
            const companyId = localStorage.getItem('company')
            const responsePut = await axiosInstance.put<IFeaturedProduct>(`${this.URL}/api/v1/featured_products/`, {
                conceptId,
                products,
                companyId
            })
            const response = await axiosInstance.get<IApi<IFeaturedProduct>>(`${this.URL}/api/v1/featured_products/?limit=10&page=1`, {
                params: {
                    companyId,
                    conceptId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

}