import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IUser } from '../interfaces/User'

import { Api } from './Api'
import { axiosInstance } from './axios'

export class UserService extends Api {
    constructor() {
        super()
    }

    get = async(page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IUser>>(`${this.URL}/api/v1/users/?isDeleted=false&isHidden=false&isDisabled=false&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getById = async(id: string) => {
        try {
            const response = await axiosInstance.get<IUser>(`${this.URL}/api/v1/users/${id}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getByToken = async() => {
        try {
            const response = await axiosInstance.get<IUser>(`${this.URL}/api/v1/users/profile`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    changePassword = async(oldPw: string, newPw: string) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/users/password/change`, {
                old_password: oldPw,
                new_password: newPw
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(error.response.data.detail)
            } else throw new Error('Unhandled error')
        }
    }
    changeState = async(_id: string, isDisabled?: boolean, isAdmin?: boolean) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/users/state`, {
                _id,
                isDisabled,
                isAdmin
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(error.response.data.detail)
            } else throw new Error('Unhandled error')
        }
    }
    changeUserPassword = async(id: string, password: string) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/users/${id}/password/change`, {
                new_password: password
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(error.response.data.detail)
            } else throw new Error('Unhandled error')
        }
    }
    add = async(username: string, firstname: string, companyId: string | null) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/users/`, {
                username,
                firstname,
                companyId,
                avatar: null,
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(error.response.data.detail)
            } else throw new Error('Unhandled error')
        }
    }

}