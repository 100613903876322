import { ITerminalsGroup } from '../../../interfaces/TerminalsGroup'
import { GroupOfTerminalsService } from '../../../api/GroupOfTerminalsService'
import { RootState } from '../../reducers/rootReducer'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const disableTerminalGroup = createAsyncThunk('terminalsGroup/disable',
    async(id: string, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const term = await api.delete( id, {isDisabled: true}) as ITerminalsGroup
        return id
    }
)
export const enableTerminalGroup = createAsyncThunk('terminalsGroup/enableTerminalGroup',
    async(id: string, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const term = await api.delete( id, {isDisabled: false}) as ITerminalsGroup
        return id
    }
)
interface IDelete {
    id: string
    isDeleted: boolean
}
export const deleteTerminalGroup = createAsyncThunk('terminalsGroup/delete',
    async(args: IDelete, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const {id, isDeleted} = args
        const term = await api.delete( id,  {isDeleted}) as ITerminalsGroup
        return id
    }
)
