import React, { useEffect, useState } from 'react'
import './AdminUsers.scss'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { IUser } from '../../interfaces/User'
import { UserService } from '../../api/UserService'
import { useTypedSelector } from '../../hooks/useTypedSelector'

export const AdminUsers = () => {
    const [users, setUsers] = useState<IUser[] | null>(null)
    const { admin } = useTypedSelector((state) => state.userReducer)
    useEffect(() => {
        let isMount = true

        const loadUsers = async () => {
            try {
                const api = new UserService()
                const res = await api.get(1, 10_000)
                if (isMount) setUsers(res.data)
            } catch (e) {
                alert('Ошибка при загрузке данных')
            }
        }

        loadUsers()
        return () => {
            isMount = false
        }
    }, [])
    const nav = useNavigate()

    const changeState = async (e: React.MouseEvent, isDisabled: boolean, id: string) => {
        e.preventDefault()
        e.stopPropagation()
        const btn = e.target as HTMLButtonElement
        try {
            btn.disabled = true
            const api = new UserService()
            await api.changeState(id, isDisabled)
            setUsers((prev) =>
                prev!.map((u) => {
                    if (u._id === id)
                        return {
                            ...u,
                            isDisabled,
                        }
                    return u
                })
            )
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            btn.disabled = false
        }
    }
    const setAdmin = async (e: React.MouseEvent, id: string) => {
        e.preventDefault()
        e.stopPropagation()
        const btn = e.target as HTMLButtonElement
        try {
            btn.disabled = true
            const api = new UserService()
            await api.changeState(id, undefined, true)
            setUsers((prev) =>
                prev!.map((u) => {
                    if (u._id === id)
                        return {
                            ...u,
                            isAdmin: true,
                        }
                    return u
                })
            )
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            btn.disabled = false
        }
    }
    if (admin._id && !admin.isAdmin) return <Navigate to={'/'} />
    return (
        <section className='admin-users-page'>
            <header className='admin-users-page__header'>
                <div className='admin-users-page__header--name'>Настройки пользователей</div>
                <Button className='btn-submit' onClick={() => nav('./add')}>
                    Добавить
                </Button>
            </header>
            <div className='admin-users-page__table'>
                {users &&
                    users.map((user) => (
                        <div className='admin-users-page__table-row' key={user._id} onClick={() => nav(`./${user._id}`)}>
                            <div>{`${user.firstname} ${user.username}`}</div>
                            <div className='btn-group'>
                                <button className={user.isDisabled ? '--green' : '--red'} onClick={(e) => changeState(e, !user.isDisabled, user._id)}>
                                    {user.isDisabled ? 'Включить' : 'Выключить'}
                                </button>
                                {!user.isAdmin && (
                                    <button className='--admin' onClick={(e) => setAdmin(e, user._id)}>
                                        Сделать супер-администратором
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </section>
    )
}
