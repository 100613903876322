import { addStorie, clearAddState } from './add'
import { deleteStorie, disableStorie, enableStorie } from './delete'
import { editStorie, clearEditState } from './edit'
import { getAllStories, getStorieById } from './get'
import { sortStories } from './put'


export const StoriesActions = {
    getAllStories,
    getStorieById,
    addStorie,
    clearAddState,
    sortStories,
    editStorie,
    clearEditState,
    disableStorie,
    enableStorie,
    deleteStorie
}