import React, { FC } from 'react'
import './Table.scss'

interface TableProps {
    headers?: string[]
    className?: string
}

export const Table: FC<TableProps> = ({
    headers,
    className = '',
    children,
}) => {
    return (
        <div className={`ui-table ${className}`}>
            <header className='ui-table__header'>
                {headers?.map((header, index) => (
                    <div key={`${header}__${index}`}>{header}</div>
                ))}
            </header>
            <section className='ui-table__content'>{children}</section>
        </div>
    )
}
