import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IIikoProducts } from '../../interfaces/IikoProducts'
import { clearIikoProductsState, getAllIikoProducts, getIikoProductsByCategoryIdAndConceptId, getIikoProductsById, getIikoProductsBySubstr } from '../actions/iikoProducts/get'
import { clearUpdateIikoProductState, deleteProduct, disabledProduct, enableProduct, updateIikoProduct } from '../actions/iikoProducts/update'
import { IikoProductsState } from '../types/iikoProducts'

const initialState: IikoProductsState = {
    iikoProducts: [],
    getProducts: {
        loading: false,
        error: ''
    },
    limit: 0,
    pages: 0,
    page: 0,
    updateProduct: {
        loading: false,
        error: '',
        isSuccess: false
    }
}

export const iikoProductsSlice = createSlice({
    name: 'iikoProducts',
    initialState,
    reducers: {
        clear: (state) => {
            state.iikoProducts = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllIikoProducts.pending.type]: (state) => {
            state.getProducts.error = ''
            state.getProducts.loading = true
            // state.iikoProducts = []
        },
        [getAllIikoProducts.fulfilled.type]: (state, action: PayloadAction<IApi<IIikoProducts>>) => {
            state.getProducts.loading = false
            state.iikoProducts = [...state.iikoProducts, ...action.payload.data].sort((a, b) => {
                if(a.order < b.order) return  -1
                if(a.order > b.order) return 1
                return 0
            })
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getAllIikoProducts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.error = action.error.message
            state.getProducts.loading = false
        },
        [getIikoProductsById.pending.type]: (state) => {
            state.getProducts.error = ''
            state.getProducts.loading = true
            state.iikoProducts = []
        },
        [getIikoProductsById.fulfilled.type]: (state, action: PayloadAction<IIikoProducts>) => {
            state.getProducts.loading = false
            state.iikoProducts = [action.payload]
            state.limit = 1
            state.page = 1
            state.pages = 1
        },
        [getIikoProductsById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.error = action.error.message
            state.getProducts.loading = false
        },
        [getIikoProductsByCategoryIdAndConceptId.pending.type]: (state) => {
            state.getProducts.error = ''
            state.getProducts.loading = true
            // state.iikoProducts = []
        },
        [getIikoProductsByCategoryIdAndConceptId.fulfilled.type]: (state, action: PayloadAction<IApi<IIikoProducts>>) => {
            state.getProducts.loading = false
            state.iikoProducts = [...state.iikoProducts, ...action.payload.data].sort((a, b) => {
                if(a.order < b.order) return  -1
                if(a.order > b.order) return 1
                return 0
            })
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getIikoProductsByCategoryIdAndConceptId.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.error = action.error.message
            state.getProducts.loading = false
        },
        [updateIikoProduct.pending.type]: (state) => {
            state.updateProduct.error = ''
            state.updateProduct.isSuccess = false
            state.updateProduct.loading = true
        },
        [updateIikoProduct.fulfilled.type]: (state) => {
            state.updateProduct.error = ''
            state.updateProduct.isSuccess = true
            state.updateProduct.loading = false
        },
        [updateIikoProduct.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.updateProduct.error = action.error.message
            state.updateProduct.isSuccess = false
            state.updateProduct.loading = false
        },
        [clearUpdateIikoProductState.fulfilled.type]: (state) => {
            state.updateProduct.error = ''
            state.updateProduct.isSuccess = false
            state.updateProduct.loading = false
        },
        [getIikoProductsBySubstr.pending.type]: (state) => {
            state.getProducts.error = ''
            state.getProducts.loading = true
        },
        [getIikoProductsBySubstr.fulfilled.type]: (state, action: PayloadAction<IApi<IIikoProducts>>) => {
            state.getProducts.loading = false
            state.iikoProducts = action.payload.data.sort((a, b) => {
                if(a.order < b.order) return  -1
                if(a.order > b.order) return 1
                return 0
            })
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getIikoProductsBySubstr.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.error = action.error.message
            state.getProducts.loading = false
        },
        [disabledProduct.fulfilled.type]: (state, action: PayloadAction<string>) => {    
            state.iikoProducts = state.iikoProducts.map(c => {
                if(c._id === action.payload)
                    c.isDisabled = true   
                return c
            })
        },
        [enableProduct.fulfilled.type]: (state, action: PayloadAction<string>) => {    
            state.iikoProducts = state.iikoProducts.map(c => {
                if(c._id === action.payload)
                    c.isDisabled = false
                return c
            })
        },
        [deleteProduct.fulfilled.type]: (state, action: PayloadAction<string>) => {    
            state.iikoProducts = state.iikoProducts.map(c => {
                if(c._id === action.payload) {
                    c.isDeleted = !c.isDeleted
                }
                    
                return c
            })
        },
        
        // [clearIikoProductsState.fulfilled.type]: (state) => {
        //     state.
        // }
    }
})


export const iikoProductsReducer = iikoProductsSlice.reducer
export const iikoProductsActions = iikoProductsSlice.actions