import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ITpCas } from '../interfaces/TpCas'
import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class TpCasService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/tpcas'
    getTpCas = async( page: number, limit?: number, query?: GetQuery) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<ITpCas>>(`${this.URL}/${this.PATH}/?limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
   
    getTpCasById = async( id: string) => {
        try {
            const response = await axiosInstance.get<ITpCas>(`${this.URL}/${this.PATH}/${id}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    addTpCas = async(name: string, companyId: string) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/${this.PATH}/`, {
              name,
              credential: {},
              source: 'manual',
              isDeleted: false,
              companyId
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            throw new Error(JSON.stringify(error.response?.data))
        }
    }
    updateContent = async( cas: ITpCas) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/${this.PATH}/`, cas)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            throw new Error(JSON.stringify(error.response?.data))
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/${this.PATH}/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}