import React, { FC, useEffect, useState } from 'react'
import { OrderStatesService } from '../../api/OrderStatesService'
import { IApi } from '../../interfaces/Api'
import { IOrderState } from '../../interfaces/OrderState'

import { ISelectOptions, Select } from '../UI/Select/Select'

interface ORderStatesDropDownProps {
    onChange: (state: IOrderState) => void
    conceptId: string
    defaultValue?: string //order states id
    readOnly?: boolean
}
export const ORderStatesDropDown: FC<ORderStatesDropDownProps> = ({
    onChange,
    conceptId,
    defaultValue,
    readOnly = false,
}) => {
    const [selectData, setSelectData] = useState<ISelectOptions[]>([])
    const [defValue, setDefValue] = useState<ISelectOptions>()
    const [loading, setLoading] = useState(true)

    const [states, setStates] = useState<IOrderState[]>([])

    useEffect(() => {
        if (states.length > 0) {
            setSelectData(
                states.map((c) => ({
                    id: c._id,
                    value: c.name,
                }))
            )
            const _default = states.find((c) => c._id === defaultValue)
            if (defaultValue && _default)
                setDefValue({
                    id: _default._id,
                    value: _default.name,
                })
            onChange(_default || states[0])

            // onChange(concepts[0])
        }
        return () => {}
    }, [states, defaultValue])

    useEffect(() => {
        let isMounted = true

        const getAllTpCas = async () => {
            const api = new OrderStatesService()
            try {
                const _states = await api.get(conceptId)

                if (isMounted) {
                    setLoading(false)
                    setStates(_states)
                }
            } catch (e) {
                console.log(e)
                if (isMounted) setLoading(false)
            }
        }
        getAllTpCas()
        return () => {
            isMounted = false
        }
    }, [])

    const handleDropdown = (value: ISelectOptions) => {
        const _state = states.find((c) => c._id === value.id)
        if (_state) onChange(_state)
    }
    if (selectData.length === 0 || loading) return null
    if (readOnly && defValue)
        return (
            <Select
                onChange={handleDropdown}
                options={selectData}
                readonly={readOnly}
                defaultValue={defValue}
                isUpdateDefaultValue
            />
        )
    return (
        <Select
            onChange={handleDropdown}
            options={selectData}
            defaultValue={defValue || selectData[0]}
        />
    )
}
