import {
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { Icon } from '../UI/Icon/Icon'

interface CustomModalErrorProps {
    onClose: () => void
    clearState: () => void
    errorMsg: string
    details: string
}

export const CustomModalError: FC<CustomModalErrorProps> = ({
    onClose,
    clearState,
    errorMsg,
    details,
}) => {
    const [isShow, setShow] = useState(false)
    const closeHandler = () => {
        onClose()
        clearState()
    }
    return (
        <ModalContent className='modal'>
            <ModalHeader className='modal__header'>
                <button
                    onClick={closeHandler}
                    className='modal__header--btn-close'>
                    <Icon name='Close' />
                </button>
            </ModalHeader>
            <ModalBody className='modal__body'>
                <Icon name='Close' />
                {errorMsg}
                <p className='modal__body-error' onClick={() => setShow(true)}>
                    Детали
                </p>
                {isShow && (
                    <p className='modal__body-error--details'>{details}</p>
                )}
            </ModalBody>
            <ModalFooter className='modal__footer'>
                <button
                    className='modal__footer-btn btn-yes'
                    onClick={closeHandler}>
                    Ок
                </button>
            </ModalFooter>
        </ModalContent>
    )
}
