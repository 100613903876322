import { createAsyncThunk } from '@reduxjs/toolkit'
import { PushService } from '../../../api/PushService'
import { RootState } from '../../reducers/rootReducer'

export const sendPush = createAsyncThunk('push/send',
    async(id: string, thunkApi) => {
        const api = new PushService()
        const response = await api.send(id) as any
        console.log('send', response)
        
        return response
    }
)
