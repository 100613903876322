
export class Api {
    constructor() {

    }
   
    

    // protected readonly URL = 'https://staging.api.seabass.group'
     protected readonly URL = 'https://admin.api.cw.marketing'
	//protected readonly URL = 'http://127.0.0.1:8000'
    protected readonly URL_MARK = 'https://marketing.api.cw.marketing'
    // protected readonly URL_8083 = 'http://cw.marketing:8083'
    getQuery = (query?: GetQuery) => {
        let _query = ''
        if(!query) return _query
        
        if(query.isDeleted !== null && typeof query.isDeleted !== 'undefined' ) 
        _query += `isDeleted=${query.isDeleted}&`
        if(query.isDisabled !== null && typeof query.isDisabled !== 'undefined')
         _query += `isDisabled=${query.isDisabled}&`        
        if(query.isHidden !== null && typeof query.isHidden !== 'undefined') _query += `isHidden=${query.isHidden}&`
        return _query.slice(0, -1)
    }
}
export interface GetQuery {
    isDeleted?: boolean | null
    isDisabled?: boolean | null
    isHidden?: boolean | null
}

export interface DeleteQuery {
    isDeleted?: boolean 
    isDisabled?: boolean 
    isHidden?: boolean
}