/* eslint-disable indent */
import React, { FC } from 'react'
// import { ReactComponent as Logo } from '../../../static/icons/Logo.svg'
import Logo from '../../../static/icons/Logo.png'
import { ReactComponent as MenuDashboard } from '../../../static/icons/MenuDashboard.svg'
import { ReactComponent as ArrowDown } from '../../../static/icons/ArrowDown.svg'
import { ReactComponent as ArrowRight } from '../../../static/icons/ArrowRight.svg'
import { ReactComponent as Bicycle } from '../../../static/icons/Bicycle.svg'
import { ReactComponent as BurgerBag } from '../../../static/icons/BurgerBag.svg'
import { ReactComponent as Capital } from '../../../static/icons/Capital.svg'
import { ReactComponent as Close } from '../../../static/icons/Close.svg'
// import { ReactComponent as Delete } from '../../../static/icons/Delete.svg'
import Delete from '../../../static/icons/Delete.png'
import Refresh from '../../../static/icons/Refresh.png'
import Search from '../../../static/icons/Search.png'
import { ReactComponent as Download } from '../../../static/icons/Download.svg'
// import { ReactComponent as Drag } from '../../../static/icons/Drag.svg'
import Drag from '../../../static/icons/drag.png'
import LogoHook from '../../../static/icons/LogoHook.png'
import Edit from '../../../static/icons/Edit.png'
// import { ReactComponent as Edit } from '../../../static/icons/Edit.svg'
import { ReactComponent as Heart } from '../../../static/icons/Heart.svg'
import { ReactComponent as MenuMarketing } from '../../../static/icons/MenuMarketing.svg'
import { ReactComponent as Ok } from '../../../static/icons/Ok.svg'
import { ReactComponent as Plus } from '../../../static/icons/Plus.svg'
// import { ReactComponent as Shutdown } from '../../../static/icons/Shutdown.svg'
import Shutdown from '../../../static/icons/Shutdown.png'
import Star from '../../../static/icons/Star.png'
import { ReactComponent as Logout } from '../../../static/icons/Logout.svg'
import { ReactComponent as File } from '../../../static/icons/File.svg'
import './Icon.scss'
interface IconProps {
    name:
        | 'Logo'
        | 'MenuDashboard'
        | 'ArrowDown'
        | 'ArrowRight'
        | 'Bicycle'
        | 'BurgerBag'
        | 'Capital'
        | 'Close'
        | 'Delete'
        | 'Download'
        | 'Drag'
        | 'Edit'
        | 'Heart'
        | 'MenuMarketing'
        | 'Ok'
        | 'Plus'
        | 'Shutdown'
        | 'Logout'
        | 'File'
        | 'LogoHook'
        | 'Refresh'
        | 'Star'
        | 'Search'
}

export const Icon: FC<IconProps> = ({ name }) => {
    switch (name) {
        case 'Logo':
            // return <Logo />
            return <img className='ui-icon' src={Logo} />
        case 'MenuDashboard':
            return <MenuDashboard />
        case 'ArrowDown':
            return <ArrowDown />
        case 'ArrowRight':
            return <ArrowRight />
        case 'Bicycle':
            return <Bicycle />
        case 'BurgerBag':
            return <BurgerBag />
        case 'Capital':
            return <Capital />
        case 'Close':
            return <Close />
        case 'Delete':
            // return <Delete />
            return <img className='ui-icon' src={Delete} />
        case 'Download':
            return <Download />
        case 'Drag':
            // return <Drag />
            return <img className='ui-icon' src={Drag} />
        case 'Edit':
            // return <Edit />
            return <img className='ui-icon' src={Edit} />
        case 'Heart':
            return <Heart />
        case 'MenuMarketing':
            return <MenuMarketing />
        case 'Ok':
            return <Ok />
        case 'Plus':
            return <Plus />
        case 'Shutdown':
            // return <Shutdown />
            return <img className='ui-icon sh' src={Shutdown} />
        case 'Logout':
            return <Logout />
        case 'File':
            return <File />
        case 'LogoHook':
            return <img className='ui-icon' src={LogoHook} />
        case 'Refresh':
            return <img className='ui-icon' src={Refresh} />
        case 'Star':
            return <img className='ui-icon' src={Star} />
        case 'Search':
            return <img className='ui-icon' src={Search} />
        default:
            return <img className='ui-icon' src={Star} />
    }
}
