import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomerService } from '../../api/CustomerService'
import { TerminalsService } from '../../api/TerminalService'
import { ICustomer } from '../../interfaces/Customer'
import {
    IOrder,
    IOrderDeliveryTypes,
    IOrderPaymentTypes,
} from '../../interfaces/Order'
import { IOrderState } from '../../interfaces/OrderState'
import { ITerminal } from '../../interfaces/Terminals'
import { convertToString } from '../../utils/dateConvert'
import { getTotal } from '../../utils/getTotal'
import { TableRow } from '../UI/Table/TableRow/TableRow'
interface OrderStatesTableRowProps {
    state: IOrderState
    index: number
}
export const OrderStatesTableRow: FC<OrderStatesTableRowProps> = ({
    state,
    index,
}) => {
    const navigate = useNavigate()
    const preparedData = [index, state.name, state.needFeedback ? 'Да' : 'Нет']
    return (
        <TableRow
            onClick={() => navigate(`./edit/${state._id}`)}
            data={preparedData}
        />
    )
}
