import axios from 'axios'
import { Dispatch } from 'redux'
import { IOrder } from '../../../interfaces/Dashboard'
import { FetchOrdersAction } from '../../types/lastOrder'
import { FetchOrders } from '../actionTypes'

export function getLastOrders() {
    return async (dispatch: Dispatch<FetchOrdersAction>) => {
        try {
            dispatch(fetchStart())
            await axios.get('https://jsonplaceholder.typicode.com/posts')
            const orders: IOrder[] = [
                {
                    date: '12.12.2022',
                    user: {
                        id: 0,
                        name: 'userA',
                        phone: '+7 999 999 99 99'
                    },
                    deliveryType: 0,
                    source: 'ios',
                    price: 1990
                },
                {
                    date: '13.12.2022',
                    user: {
                        id: 0,
                        name: 'userA',
                        phone: '+7 999 999 99 99'
                    },
                    deliveryType: 0,
                    source: 'android',
                    price: 890
                },
                {
                    date: '12.12.2022',
                    user: {
                        id: 0,
                        name: 'userA',
                        phone: '+7 999 999 99 99'
                    },
                    deliveryType: 1,
                    source: 'site',
                    price: 4540
                },
                {
                    date: '12.12.2022',
                    user: {
                        id: 0,
                        name: 'userA',
                        phone: '+7 999 999 99 99'
                    },
                    deliveryType: 1,
                    source: 'ios',
                    price: 3000
                },
            ]
            dispatch(fetchSuccess(orders))
        } catch (e: any) {
            console.log(e)
            dispatch(fetchError(e.message))
        }
    }
}

export function sortByDate(orders: IOrder[], type: 'desc' | 'asc') {
    return (dispatch: Dispatch<FetchOrdersAction>) => {
        const newOrders = orders.sort((a, b) => {
            const dateASplit = a.date.split('.')
            const dateBSplit = b.date.split('.')
            const dateA = new Date(+dateASplit[2], +dateASplit[1]-1, +dateASplit[0])
            const dateB = new Date(+dateBSplit[2], +dateBSplit[1]-1, +dateBSplit[0])
            if(type === 'asc') {
                if(dateA > dateB) return 1
                if(dateA < dateB) return -1
                return 0
            } else {
                if(dateA > dateB) return -1
                if(dateA < dateB) return 1
                return 0
            }
        })
        dispatch(fetchSuccess(newOrders))
    }
}
export function sortByDeliveryType(orders: IOrder[], type: 'desc' | 'asc') {
    return (dispatch: Dispatch<FetchOrdersAction>) => {
        const newOrders = orders.sort((a, b) => {
            if(type === 'asc') {
                if(a.deliveryType > b.deliveryType) return 1
                if(a.deliveryType < b.deliveryType) return -1
                return 0
            } else {
                if(a.deliveryType > b.deliveryType) return -1
                if(a.deliveryType < b.deliveryType) return 1
                return 0
            }
        })
        dispatch(fetchSuccess(newOrders))
    }
}
export function sortByPrice(orders: IOrder[], type: 'desc' | 'asc') {
    return (dispatch: Dispatch<FetchOrdersAction>) => {
        const newOrders = orders.sort((a, b) => {
            if(type === 'asc') {
                if(a.price > b.price) return 1
                if(a.price < b.price) return -1
                return 0
            } else {
                if(a.price > b.price) return -1
                if(a.price < b.price) return 1
                return 0
            }
        })
        dispatch(fetchSuccess(newOrders))
    }
}

function fetchStart(): FetchOrdersAction {
    return {
        type: FetchOrders.FETCH_ORDERS_START
    }
}

function fetchSuccess(orders: IOrder[]): FetchOrdersAction {
    return {
        type: FetchOrders.FETCH_ORDERS_SUCCESS,
        payload: orders
    }
}

function fetchError(error: string): FetchOrdersAction {
    return {
        type: FetchOrders.FETCH_ORDERS_ERROR,
        error
    }
}
