import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import './AdminUsers.scss'
import { Navigate, useNavigate } from 'react-router-dom'
import { UserService } from '../../api/UserService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CompanySelect } from '../../components/CompanySelect/CompanySelect'
import { PhoneInput } from '../../components/UI/PhoneInput/PhoneInput'
export const AdminUserAdd = () => {
    const [firstname, setName] = useState('')
    const [username, setUsername] = useState('')
    const [company, setCompany] = useState<string | null>('')
    const [loading, setLoading] = useState(false)
    const { admin } = useTypedSelector((state) => state.userReducer)

    const nav = useNavigate()
    const onSubmit = async () => {
        if (firstname.trim().length === 0) return alert('Введите имя пользователя')
        const value = username.replaceAll(' ', '').replaceAll('+', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replace(username.charAt(0), '7')
        if (value.trim().length === 0) return alert('Введите username')
        try {
            setLoading(true)
            const api = new UserService()
            const res = await api.add(value, firstname, company)
            nav('/admin-users')
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            setLoading(false)
        }
    }
    if (admin._id && !admin.isAdmin) return <Navigate to={'/'} />
    return (
        <section className='admin-users-page'>
            <header className='admin-users-page__header'>
                <div className='admin-users-page__header--name'>Настройки пользователей/Новый пользователь</div>
            </header>
            <div className='admin-users-page__edit'>
                <div className='admin-users-page__edit--input'>
                    <label>Имя пользователя</label>
                    <input value={firstname} onChange={({ target }) => setName(target.value)} />
                </div>
                <div className='admin-users-page__edit--input'>
                    <label>Username</label>
                    <PhoneInput onChange={(value) => setUsername(value)} placeholder='+7 (999) 999-99-99' value={username} />
                </div>
                <div className='admin-users-page__edit--input'>
                    <label>Контора</label>
                    <CompanySelect onChange={(c) => setCompany(c._id === 'null' ? null : c._id)} withEmpty />
                </div>
            </div>
            <Button isLoading={loading} className='btn-submit' onClick={onSubmit}>
                Сохранить
            </Button>
        </section>
    )
}
