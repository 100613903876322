import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IAddPromo, IPromo } from '../interfaces/Promo'

import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class PromoService extends Api {
    constructor() {
        super()
    }

    get = async( page: number, limit?: number, query?: GetQuery) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IPromo>>(`${this.URL}/api/v1/promocodes/?${this.getQuery(query)}&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }


    getById = async( id: string) => {
        try {
            const response = await axiosInstance.get<IPromo>(`${this.URL}/api/v1/promocodes/${id}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    add = async( promocode: IAddPromo) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/promocodes/`, promocode)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error(error.response.data.detail)
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/api/v1/promocodes/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    edit = async( promocode: IAddPromo) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v1/promocodes/`, promocode)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error(error.response.data.detail)
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}