import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'
import { StoriesService } from '../../../api/StoriesService'
import { IApi } from '../../../interfaces/Api'
import { IStories } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'

export const getStorieById = createAsyncThunk('storie/getById',
    async(id: string, thunkApi) => {
        const api = new StoriesService()
        const storie = await api.getStorieById(id) as IStories
        return storie
    }
)

interface GetAllStoriesArgs {
    page: number
    query: GetQuery
    conceptId: string | null
}
export const getAllStories = createAsyncThunk('storie/getAll',
    async(args: GetAllStoriesArgs, thunkApi) => {
        const {page, query, conceptId} = args
        const api = new StoriesService()
        const stories = await api.getAllStories(page, conceptId, query) as IApi<IStories>
        return stories
    }
)