import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IOrder } from '../../interfaces/Order'
import { getCustomerOrderById, getCustomerOrders, getOrdersForDashboard } from '../actions/order/get'
import { OrderState } from '../types/order'

const initialState: OrderState = {
    limit: 0,
    page: 0,
    pages: 0,
    orders: [],
    order: {} as IOrder,
    new_orders: [],
    getOrders: {
        error: '',
        loading: false
    },
    getOrder: {
        error: '',
        loading: false
    },
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setNewOrders: (state, action: PayloadAction<IOrder[]>) => {
            state.new_orders = [...state.new_orders, ...action.payload]
        },
        addNewOrder: (state, action: PayloadAction<IOrder>) => {
            state.new_orders = [action.payload, ...state.new_orders]
        },
        clearNewOrders: (state) => {
            state.new_orders = []
        }
    },
    extraReducers: {
        [getCustomerOrders.pending.type]: (state) => {
            state.getOrders.error = ''
            state.getOrders.loading = true
        },
        [getCustomerOrders.fulfilled.type]: (state, action: PayloadAction<IApi<IOrder>>) => {
            state.getOrders.error = ''
            state.getOrders.loading = false
            state.orders = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getCustomerOrders.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getOrders.error = action.error.message
            state.getOrders.loading = false
        },
        [getCustomerOrderById.pending.type]: (state) => {
            state.getOrder.error = ''
            state.getOrder.loading = true
        },
        [getCustomerOrderById.fulfilled.type]: (state, action: PayloadAction<IOrder>) => {
            state.getOrder.error = ''
            state.getOrder.loading = false
            state.order = action.payload
        },
        [getCustomerOrderById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getOrder.error = action.error.message
            state.getOrder.loading = false
        },
        [getOrdersForDashboard.pending.type]: (state) => {
            state.getOrders.error = ''
            state.getOrders.loading = true
        },
        [getOrdersForDashboard.fulfilled.type]: (state, action: PayloadAction<IApi<IOrder>>) => {
            state.getOrders.error = ''
            state.getOrders.loading = false
            state.orders = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getOrdersForDashboard.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getOrders.error = action.error.message
            state.getOrders.loading = false
        },
    }
})


export const orderReducer = orderSlice.reducer
export const orderActions = orderSlice.actions