import { RootState } from '../../reducers/rootReducer'
import { SegmentService } from '../../../api/SegmentService'
import { ISegment } from '../../../interfaces/Segments'
import { IApi } from '../../../interfaces/Api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'


interface GetAllSegmentsArgs {
    page: number
    limit?: number
    manual?: boolean
    query: GetQuery
}
export const getAllSegments = createAsyncThunk('segment/getAll', 
    async(args: GetAllSegmentsArgs, thunkApi) => {
        const {page, limit, manual = true, query} = args
        const api = new SegmentService()
        let segments
        if(manual) {
            segments = await api.getManual( page, limit, query) as IApi<ISegment>
        } else {
            segments = await api.getAuto( page, limit, query) as IApi<ISegment>
        }
        
        return segments
    }
)
export const getSegmentById = createAsyncThunk('segment/getById', 
    async(id: string, thunkApi) => {
        const api = new SegmentService()
        const segment = await api.getById( id) as ISegment
        return segment
    }
)