import { Button } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConceptService } from '../../api/ConceptService'
import { ContentService } from '../../api/ContentService'
import { FilesService } from '../../api/FilesService'
import { TpCasService } from '../../api/TpCasService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { DragNDropImage } from '../../components/DragNDropImage/DragNDropImage'
import { Loader } from '../../components/Loader/Loader'
import { CustomModal } from '../../components/Modal/CustomModal'
import { TpCasDropDown } from '../../components/TpCasDropDown/TpCasDropDown'
import { Input } from '../../components/UI/Input/Input'
import { ISelectOptions, Select } from '../../components/UI/Select/Select'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { IUISettings } from '../../interfaces/Stories'
import { ITpCas } from '../../interfaces/TpCas'
import './EditContent.scss'
const options = [
    {
        id: 'notice',
        value: 'Уведомление',
    },
    {
        id: 'slide',
        value: 'Слайдер Desktop',
    },
    {
        id: 'mobile',
        value: 'Слайдер Mobile',
    },
]
const EditContent = () => {
    useEffect(() => {
        document.title = 'Редактирование контента'
    }, [])
    ///В идеале переписать через useReducer
    const { id } = useParams()
    const [img, setImg] = useState('')
    const [file, setFile] = useState<File>()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [text, setText] = useState('')
    const [color, setColor] = useState('#FFFFFF')
    const [type, setType] = useState<ISelectOptions>(options[0])
    const [conceptId, setConcept] = useState<string | null>(null)
    const [tpcas, setTpCas] = useState<ITpCas>()
    const [isOpen, setOpen] = useState(false)

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        let isMount = true
        const getContentById = async (contentId: string) => {
            try {
                setLoading(true)
                const api = new ContentService()

                const tpCasApi = new TpCasService()
                const _content = await api.getContentById(contentId)
                if (isMount && _content) {
                    if (_content.tpcasId) {
                        const _tpCas = await tpCasApi.getTpCasById(_content.tpcasId)
                        setTpCas(_tpCas)
                    }
                    setConcept(_content.conceptId)
                    setImg(_content.image)
                    setName(_content.name)
                    setUrl(_content.url)
                    setText(_content.text)
                    if (_content.uiSettings) setColor(_content.uiSettings.color)
                    setType(options.find((o) => o.id === _content.type) || options[0])
                }
            } catch (e) {
                console.error(e)
                setStatus((prev) => ({
                    ...prev,
                    error: 'Произошла ошибка при загрузке, повторите попытку позже',
                }))
                setOpen(true)
            } finally {
                setLoading(false)
            }
        }
        if (id) getContentById(id)
        return () => {
            isMount = false
        }
    }, [])

    const setImageHandler = (image: string, file: File | null, error?: string) => {
        if (file) {
            setImg(image)
            setFile(file)
        }
        if (error) {
            console.log(error)
        }
    }
    const removeImageHandler = () => {
        setImg('')
        setFile(undefined)
    }
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const { admin } = useTypedSelector((state) => state.userReducer)
    const onSubmit = () => {
        if (img || type.id === 'notice') {
            const companyId = localStorage.getItem('company')
            if (name.trim().length > 0) {
                const editContent = async () => {
                    try {
                        setStatus({
                            loading: true,
                            error: '',
                        })
                        const apiFile = new FilesService()
                        const api = new ContentService()
                        let _file = null
                        if (file && type.id !== 'notice') _file = await apiFile.uploadBannerFile(file, name)

                        const _res = await api.updateContent({
                            _id: id,
                            name,
                            url,
                            conceptId,
                            tpcasId: tpcas?._id || null,
                            image: _file ? (type.id === 'notice' ? null : _file.body) : img,
                            type: type.id as string,
                            text,
                            companyId: companyId || admin.companyId,
                            isDeleted: false,
                            order: 0,
                            uiSettings: {
                                url,
                                text,
                                color,
                            },
                            // isHidden: false,
                            // isDisabled: false,
                        })

                        setStatus({
                            loading: false,
                            error: '',
                        })
                    } catch (e: any) {
                        console.error(e)
                        setStatus({
                            loading: false,
                            error: e.message,
                        })
                    } finally {
                        setOpen(true)
                    }
                }
                editContent()
            }
        } else {
            alert('Загрузите изображение')
        }
    }
    if (loading) return <Loader />
    return (
        <section className='edit-content-page'>
            <header className='edit-content-page__header'>
                <div className='edit-content-page__header--name'>Контент/Редактирование</div>
            </header>

            <div className='edit-content-page__content'>
                <div className='edit-content-page__content-inputs'>
                    <div className='inputs-block'>
                        <label>Название</label>
                        <Input className='edit-content-input' value={name} onChange={(e) => setName(e.target.value)} />
                        <label>Ссылка</label>
                        <Input className='edit-content-input' value={url} onChange={(e) => setUrl(e.target.value)} />
                    </div>
                    <div className='inputs-block'>
                        <label>Текст</label>
                        <textarea style={undefined} value={text} onChange={(e) => setText(e.target.value)} className='edit-content-textarea' />
                    </div>
                    <div className='dropdown-block'>
                        {type.id !== 'notice' && (
                            <>
                                {img ? (
                                    <div className='dropdown-block__preview-wrapper'>
                                        <img className='dropdown-block__preview' src={img} alt='preview' />
                                        <div className='dropdown-block__preview-remove' onClick={removeImageHandler}></div>
                                    </div>
                                ) : null}
                                <DragNDropImage isEmpty={img.length === 0} onChange={setImageHandler} />
                            </>
                        )}
                    </div>
                    <div className='select-type'>
                        <label>Концепция</label>
                        <ConceptDropDown additionalValue={{ id: 'addData', value: 'Общий' }} defaultValue={conceptId || ''} onChange={(c) => setConcept(c._id === 'addData' ? null : c._id)} />
                    </div>
                    <div className='select-type'>
                        <label>Тип контента</label>
                        <Select options={options} onChange={setType} defaultValue={type} />
                    </div>
                    <div className='tpcas-type'>
                        <label>TpCas</label>
                        <TpCasDropDown defaultValue={tpcas?._id} onChange={setTpCas} />
                    </div>
                    {type.id !== 'notice' && (
                        <div className='content-color'>
                            <label>Цвет</label>
                            <input className='input-color' type={'color'} value={color} onChange={(e) => setColor(e.target.value)} />
                        </div>
                    )}
                </div>
                <Button isLoading={status.loading} className='edit-content-page__submit' onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/contents'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось обновить контент'
                message='Контент успешно обновлен'
                details={status.error}
            />
        </section>
    )
}

export default EditContent
