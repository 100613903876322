import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { IikoOrdersService } from '../../api/IikoOrdersService'
import { OrdersTable } from '../../components/OrdersTable/OrdersTable'
import { Icon } from '../../components/UI/Icon/Icon'
import { Input } from '../../components/UI/Input/Input'
import { useScroll } from '../../hooks/useScroll'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
    IOrder,
    IOrderDeliveryTypes,
    IOrderPaymentTypes,
} from '../../interfaces/Order'
import { orderActions } from '../../store/reducers/orderReducer'
import './Orders.scss'

const Filters = [
    {
        id: 'all',
        name: 'Все',
    },
    {
        id: 'success',
        name: 'Завершенные',
    },
    {
        id: 'accept',
        name: 'Принятые',
    },
    {
        id: 'work',
        name: 'В работе',
    },
    {
        id: 'cancelled',
        name: 'Отмененные',
    },
]

export const Orders = () => {
    const [currPage, setCurrPage] = useState(1)
    const { new_orders } = useTypedSelector((state) => state.orderReducer)
    const dispatch = useDispatch()
    const [paymentTypes, setPaymentTypes] = useState<IOrderPaymentTypes[]>([])
    const [deliveryTypes, setDeliveryTypes] = useState<IOrderDeliveryTypes[]>(
        []
    )
    const [pages, setPages] = useState(0)
    const parentRef = useRef<HTMLDivElement>(null)
    const childRef = useRef<HTMLDivElement>(null)
    const searchPhoneRef = useRef<HTMLInputElement>(null)
    const cb = useCallback(() => {
        if (pages <= currPage) return
        setCurrPage((prev) => prev + 1)
    }, [currPage, pages])

    useScroll(parentRef, childRef, cb)
    useEffect(() => {
        document.title = 'Заказы'
        return () => {}
    }, [])
    useEffect(() => {
        let isMount = true
        const getOrders = async (page: number) => {
            try {
                const api = new IikoOrdersService()
                const _orders = await api.get(page, 20)
                const _payment_types = await api.getPaymentTypes()
                const _delivery_types = await api.getDeliveryTypes()
                if (isMount) {
                    dispatch(orderActions.setNewOrders(_orders.data))
                    setPages(_orders.pages)
                    setPaymentTypes(_payment_types)
                    setDeliveryTypes(_delivery_types)
                }
            } catch (e) {
                console.error(e)
            }
        }

        getOrders(currPage)
        return () => {
            isMount = false
        }
    }, [currPage])
    useEffect(() => {
        return () => {
            dispatch(orderActions.clearNewOrders())
        }
    }, [])
    return (
        <section className='orders-page'>
            <header className='orders-page__header'>
                <div className='orders-page__header--name'>Заказы</div>
                <div className='orders-page__header-inputs'>
                    <div>
                        <Input
                            placeholder='Телефон'
                            ref={searchPhoneRef}
                            // value={phone}
                            // onChange={onPhoneChange}
                            className='orders-page__header-search-input phone'
                        />
                        <button>
                            <Icon name='Search' />
                        </button>
                    </div>
                    <Link to={'./add'} className='orders-page__header--btn-add'>
                        <Icon name='Plus' />
                        Новый
                    </Link>
                </div>
            </header>
            <div className='orders-page__content'>
                {/* <div className='orders-filter'>
                    {Filters.map((f) => (
                        <div
                            key={f.id}
                            className={`orders-filter-item ${f.id}`}>
                            {f.name}
                        </div>
                    ))}
                </div> */}

                <OrdersTable
                    orders={new_orders}
                    paymentTypes={paymentTypes}
                    deliveryTypes={deliveryTypes}
                />
                <div
                    ref={childRef}
                    style={{
                        // backgroundColor: 'green',
                        width: '100%',
                        height: '10px',
                    }}></div>
            </div>
        </section>
    )
}
