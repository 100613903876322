import { addIikoTerminal, clearAddTerminalState } from './add'
import { disableTerminal, enableTerminal, deleteTerminal } from './delete'
import { getAllTerminals, getTerminalById, getTerminalsByConcept, getTerminalsByGroup } from './get'
import { clearUpdateTerminalState, updateIikoTerminal } from './update'


export const TerminalsActions = {
    getTerminalsByGroup,
    getAllTerminals,
    getTerminalById,
    disableTerminal,
    updateIikoTerminal,
    clearUpdateTerminalState,
    getTerminalsByConcept, 
    enableTerminal, 
    deleteTerminal,
    addIikoTerminal,
    clearAddTerminalState
}