import { Avatar } from '@chakra-ui/react'
import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useScroll } from '../../../hooks/useScroll'
import { Loader } from '../../Loader/Loader'
import { Icon } from '../Icon/Icon'
import './Select.scss'

export interface ISelectOptions {
    id: number | string
    value: string
    icon?: string
}
interface SelectProps {
    options: ISelectOptions[]
    defaultValue?: ISelectOptions
    onChange: (value: ISelectOptions) => void
    className?: string
    isIcon?: boolean
    isUpdateDefaultValue?: boolean
    readonly?: boolean
    loading?: boolean
    cb?: () => void
}

export const Select: FC<SelectProps> = ({
    options,
    defaultValue = {
        id: -1,
        value: '',
    },
    onChange,
    className = '',
    isIcon = false,
    isUpdateDefaultValue = false,
    readonly = false,
    loading = false,
    cb,
}) => {
    const [selectedItem, setSelectedItem] = useState(defaultValue)
    const [btnValue, setBtnValue] = useState(selectedItem?.value)
    const [btnIcon, setBtnIcon] = useState(selectedItem?.icon)
    const [isListVisible, setListVisible] = useState(false)

    const selectRef = useRef<HTMLDivElement>(null)
    const liRef = useRef<HTMLLIElement>(null)
    const ulRef = useRef<HTMLUListElement>(null)

    useScroll(ulRef, liRef, cb)

    const handleClickOutside = (e: MouseEvent) => {
        if (selectRef.current?.contains(e.target as Node)) return
        setListVisible(false)
    }

    useEffect(() => {
        if (isListVisible) document.addEventListener('mousedown', handleClickOutside)
        else document.removeEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isListVisible])

    const ddListStyles = classNames({
        dropdown__list: true,
        'dropdown__list--visible': isListVisible,
    })
    const liListStyles = classNames({
        'dropdown__list-item': true,
        'dropdown__list--icon': isIcon,
    })
    const handleBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (readonly) return
        const target = e.target as HTMLButtonElement
        target.focus()
        setListVisible((prev) => !prev)
    }
    const handleCloseDropDown = (e: any) => {
        setTimeout(() => {
            setListVisible(false)
        }, 100)
    }
    useEffect(() => {
        if (isUpdateDefaultValue) {
            setSelectedItem(defaultValue)
            setBtnValue(defaultValue.value)
        }
    }, [defaultValue])

    const handleListClick = (e: any) => {
        e.stopPropagation()
        const { id, value, icon } = e.target.dataset
        setSelectedItem({ id, value })
        if (isUpdateDefaultValue) {
            setBtnValue(defaultValue.value)
            setBtnIcon(defaultValue.icon)
        } else {
            setBtnValue(value)
            setBtnIcon(icon)
        }
        setListVisible(false)
        onChange({ id, value, icon })
    }
    return (
        <div className={`dropdown ${className} ${readonly ? '--readonly' : ''}`} ref={selectRef}>
            <button
                className='dropdown__button'
                // onBlur={handleCloseDropDown}
                onClick={handleBtnClick}>
                {isIcon ? (
                    <div className='dropdown__list--icon'>
                        <Avatar className='dropdown-avatar' src={btnIcon} name={btnValue} />
                        {btnValue}
                    </div>
                ) : (
                    btnValue
                )}
                {readonly ? null : <Icon name='ArrowDown' />}
                <ul className={ddListStyles}>
                    {options.map((option, index) => {
                        return (
                            <li key={`${JSON.stringify(option)}`} className={liListStyles} data-id={option.id} data-value={option.value} data-icon={option.icon} onClick={handleListClick}>
                                {isIcon ? (
                                    <>
                                        <Avatar className='dropdown-avatar' src={option.icon} name={option.value} />
                                        {option.value}
                                    </>
                                ) : (
                                    option.value
                                )}
                            </li>
                        )
                    })}
                    {loading ? (
                        <li className='dd-list-helper-loader'>
                            <Loader className='dd-list-helper-loader--l' />
                        </li>
                    ) : null}

                    <li className='dd-list-helper' ref={liRef}></li>
                </ul>
            </button>
        </div>
    )
}
