import React, { FC, useEffect, useState } from 'react'
import { TpCasService } from '../../api/TpCasService'
import { IApi } from '../../interfaces/Api'
import { ITpCas } from '../../interfaces/TpCas'

import { ISelectOptions, Select } from '../UI/Select/Select'

interface TpCasDropDownProps {
    onChange: (tpCas: ITpCas) => void
    defaultValue?: string //tpcasId
    readOnly?: boolean
}
export const TpCasDropDown: FC<TpCasDropDownProps> = ({
    onChange,
    defaultValue,
    readOnly = false,
}) => {
    const [selectData, setSelectData] = useState<ISelectOptions[]>([])
    const [defValue, setDefValue] = useState<ISelectOptions>()
    const [loading, setLoading] = useState(true)

    const [tpCases, setTp] = useState<ITpCas[]>([])

    useEffect(() => {
        if (tpCases.length > 0) {
            setSelectData(
                tpCases.map((c) => ({
                    id: c._id,
                    value: c.name,
                }))
            )
            const _default = tpCases.find((c) => c._id === defaultValue)
            if (defaultValue && _default)
                setDefValue({
                    id: _default._id,
                    value: _default.name,
                })
            onChange(_default || tpCases[0])

            // onChange(concepts[0])
        }
        return () => {}
    }, [tpCases, defaultValue])

    useEffect(() => {
        let isMounted = true

        const getAllTpCas = async () => {
            const api = new TpCasService()
            try {
                const _tpcas = await api.getTpCas(1, 1000)

                if (isMounted) {
                    setLoading(false)
                    setTp(_tpcas.data)
                }
            } catch (e) {
                console.log(e)
                if (isMounted) setLoading(false)
            }
        }
        getAllTpCas()
        return () => {
            isMounted = false
        }
    }, [])

    const handleDropdown = (value: ISelectOptions) => {
        const _tpcas = tpCases.find((c) => c._id === value.id)
        if (_tpcas) onChange(_tpcas)
    }
    if (selectData.length === 0 || loading) return null
    if (readOnly && defValue)
        return (
            <Select
                onChange={handleDropdown}
                options={selectData}
                readonly={readOnly}
                defaultValue={defValue}
                isUpdateDefaultValue
            />
        )
    return (
        <Select
            onChange={handleDropdown}
            options={selectData}
            defaultValue={defValue || selectData[0]}
        />
    )
}
