import { RootState } from '../../reducers/rootReducer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConceptService } from '../../../api/ConceptService'
import { IConcept } from '../../../interfaces/Concept'


export const disableConcept = createAsyncThunk('concepts/disableConcept',
    async(id: string, thunkApi) => {
        const api = new ConceptService()
        const conc = await api.delete( id, {isDisabled: true}) as IConcept
        return id
    }
)
export const enableConcept = createAsyncThunk('concepts/enableConcept',
    async(id: string, thunkApi) => {
        const api = new ConceptService()
        const conc = await api.delete(id, {isDisabled: false})
        return id
    }
)


interface IDelete {
    id: string
    isDeleted: boolean
}
export const deleteConcept = createAsyncThunk('concepts/deleteConcept',
    async(args: IDelete, thunkApi) => {
        const api = new ConceptService()
        const {id, isDeleted} = args
        const conc = await api.delete( id, {isDeleted}) as IConcept
        return id
    }
)
