import React, { FC, useEffect, useState } from 'react'
import { IikoProductsService } from '../../api/IikoProductsService'
import { IOrderProduct } from '../../interfaces/Order'
import { getTotal } from '../../utils/getTotal'
import './AddOrder.scss'
interface AddOrderProductsListProps {
    product: IOrderProduct
    onChange: (type: string, payload: any) => void
}
export const AddOrderProductsList: FC<AddOrderProductsListProps> = ({
    product,
    onChange,
}) => {
    const [img, setImg] = useState('')
    useEffect(() => {
        let isMount = true
        const getProduct = async () => {
            try {
                const api = new IikoProductsService()
                const _product = await api.getIikoProducts(1, product.code)
                console.log(_product.data)

                const _image = _product.data[0].image?.body || ''
                if (isMount) setImg(_image)
            } catch (e) {
                console.log(e)
            }
        }
        getProduct()
        return () => {
            isMount = false
        }
    }, [])
    return (
        <div className='products-info__list-item'>
            <div className='products-info__list-item--img'>
                <img src={img} alt={product.code} />
            </div>
            <div className='products-info__list-item--name'>{product.name}</div>
            <div className='products-info__list-item--mods'>
                {product.productModifiers?.map((m) => m.name).join(', ')}
            </div>
            <div className='products-info__list-item--amount'>
                <button
                    onClick={() =>
                        onChange('decreaseproduct', product.frontEndId)
                    }>
                    -
                </button>
                <span>{product.amount}</span>
                <button
                    onClick={() => {
                        onChange('increaseproduct', product.frontEndId)
                    }}>
                    +
                </button>
            </div>
            <div className='products-info__list-item--price'>
                {getTotal([product])} ₽
            </div>
        </div>
    )
}
