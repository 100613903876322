import React, { useEffect, useState } from 'react'
import './TPCasTable.scss'
import { ITpCas } from '../../interfaces/TpCas'
import { TpCasService } from '../../api/TpCasService'
import { TableRow } from '../../components/UI/Table/TableRow/TableRow'
import { useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
export const TPCasTable = () => {
    const [tpcasses, setTpCasses] = useState<ITpCas[] | null>(null)
    useEffect(() => {
        let isMount = true

        const loadTpCasses = async () => {
            try {
                const api = new TpCasService()
                const res = await api.getTpCas(1, 10_000)
                if (isMount) setTpCasses(res.data)
            } catch (e) {
                alert('Ошибка при загрузке данных')
            }
        }

        loadTpCasses()
        return () => {
            isMount = false
        }
    }, [])
    const nav = useNavigate()
    const removeTpCas = async (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.preventDefault()
        e.stopPropagation()
        const target = e.target as HTMLButtonElement
        try {
            target.disabled = true
            const api = new TpCasService()
            const res = await api.delete(id, { isDeleted: true })
            setTpCasses(tpcasses?.filter((cas) => cas._id !== id) || null)
        } catch (error) {
            alert(`Не удалось удалить\n${(error as Error).message}`)
        } finally {
            target.disabled = false
        }
    }
    return (
        <section className='tpcas-page'>
            <header className='tpcas-page__header'>
                <div className='tpcas-page__header--name'>Настройки интеграций</div>
                <Button className='btn-submit' onClick={() => nav('./add')}>
                    Добавить
                </Button>
            </header>
            <div className='tpcas-page__table'>
                {tpcasses &&
                    tpcasses.map((cas) => (
                        <div className='tpcas-page__table-row' key={cas._id} onClick={() => nav(`./${cas._id}`)}>
                            {cas.name}
                            <button className='btn-remove' onClick={(e) => removeTpCas(e, cas._id)}>
                                Удалить
                            </button>
                        </div>
                    ))}
            </div>
        </section>
    )
}
