import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'
import { PromoService } from '../../../api/PromoService'
import { IApi } from '../../../interfaces/Api'
import { IPromo } from '../../../interfaces/Promo'
import { RootState } from '../../reducers/rootReducer'


interface GetAllPromosArgs {
    page: number
    limit?: number
    query: GetQuery
}
export const getAllPromos = createAsyncThunk('promo/getAll',
    async(args: GetAllPromosArgs, thunkApi) => {
        const {page, limit, query} = args
        const api = new PromoService()
        const promos = await api.get( page, limit, query) as IApi<IPromo>
        return promos
    }
)

export const getPromoById = createAsyncThunk('promo/getById',
    async(id: string, thunkApi) => {
        const api = new PromoService()
        const promo = await api.getById( id) as IPromo
        return promo
    }
)

