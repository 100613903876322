import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { ConceptService } from '../../../api/ConceptService'
import { IConcept } from '../../../interfaces/Concept'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'

interface getAllConceptsArgs {
    page: number
    limit?: number
    query?: GetQuery
}
export const getAllConcepts = createAsyncThunk('concept/getList',
    async(args: getAllConceptsArgs, thunkApi) => {
        const {page, limit, query} = args
        const api = new ConceptService()
        const concepts = await api.getConcepts( page, limit, query) as IApi<IConcept>
        
        return concepts
    }
)
export const getConceptById = createAsyncThunk('concept/getById',
    async(id: string, thunkApi) => {
        const api = new ConceptService()
        const concept = await api.getConceptById( id) as IConcept
        return concept
    }
)

export const clearConceptState = createAsyncThunk('concept/clearConceptState',
    (_, thunkApi) => {
        return
    }
)
