import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ITpCas } from '../../interfaces/TpCas'
import { TpCasService } from '../../api/TpCasService'
import { Loader } from '../../components/Loader/Loader'
import { IMultiSelectOptions, MultiSelect } from '../../components/UI/MultiSelect/MultiSelect'
import { Button } from '@chakra-ui/react'
import './TPCasTable.scss'
interface YandexGoCreds {
    bearer_token: string
}
interface IIKOBIZCreds {
    organization: string
    login: string
    password: string
    olapUrl: string
    isEnabled: boolean
}
interface IIKOTRANSCreds {
    organization: string
    apiLogin: string
    olapUrl: string
    isEnabled: boolean
}
interface TILLYPADCreds {
    base_url: string
    ppgr_ID: string
    clgr_ID: string
    cpop_user_ID: string
    cpop_cptp_ID: string
    isEnabled: boolean
}

export const EditTpCas = () => {
    const { id } = useParams()
    const [tpcas, setTpcas] = useState<ITpCas | null>(null)
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isMount = true

        const loadTpCas = async (_id: string) => {
            try {
                const api = new TpCasService()
                const res = await api.getTpCasById(_id)
                if (isMount) {
                    setTpcas(res)
                    setName(res.name)
                }
            } catch (e) {
                alert('Ошибка при загрузке данных')
            }
        }

        if (id) loadTpCas(id)
        return () => {
            isMount = false
        }
    }, [])

    const addCred = (credName: string) => {
        const iiko_biz = {
            organization: '',
            login: '',
            password: '',
            olapUrl: '',
            isEnabled: false,
        }
        const iiko_transport = {
            organization: '',
            apiLogin: '',
            olapUrl: '',
            isEnabled: false,
        }
        const yandex_go = {
            bearer_token: '',
        }
        const tillypad = {
            base_url: '',
            ppgr_ID: '',
            clgr_ID: '',
            cpop_user_ID: '',
            cpop_cptp_ID: '',
            isEnabled: false,
        }

        switch (credName) {
            case 'iiko_biz':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        iiko_biz,
                    },
                }))
            case 'iiko_transport':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        iiko_transport,
                    },
                }))
            case 'yandex_go':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        yandex_go,
                    },
                }))
            case 'tillypad':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        tillypad,
                    },
                }))
        }
    }
    const removeCred = (credName: string) => {
        switch (credName) {
            case 'iiko_biz':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        iiko_biz: null,
                    },
                }))
            case 'iiko_transport':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        iiko_transport: null,
                    },
                }))
            case 'yandex_go':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        yandex_go: null,
                    },
                }))
            case 'tillypad':
                return setTpcas((prev) => ({
                    ...(prev as ITpCas),
                    credentials: {
                        ...prev?.credentials,
                        tillypad: null,
                    },
                }))
        }
    }

    const nav = useNavigate()
    const onSubmit = async () => {
        const transport = tpcas?.credentials['iiko_transport']
        const biz = tpcas?.credentials['iiko_biz']
        const tillypad = tpcas?.credentials['tillypad']
        const active = []
        if (transport?.isEnabled) active.push('iiko_transport')
        if (biz?.isEnabled) active.push('iiko_biz')
        if (tillypad?.isEnabled) active.push('tillypad')
        if (name.trim().length === 0) return alert('Введите название')
        if (active.length !== 1) return alert('Одновременно должна быть активна 1 интеграция!')
        console.log(tpcas)

        try {
            setLoading(true)
            const api = new TpCasService()
            const res = await api.updateContent({ ...(tpcas as ITpCas), name })
            nav('/settings-tpcas')
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            setLoading(false)
        }
    }
    if (!tpcas) return <Loader />
    return (
        <section className='tpcas-page'>
            <header className='tpcas-page__header'>
                <div className='tpcas-page__header--name'>Настройки интеграций/{tpcas.name}</div>
            </header>
            <div className='tpcas-page__edit'>
                <div className='tpcas-page__edit--input'>
                    <label>Название</label>
                    <input value={name} onChange={({ target }) => setName(target.value)} />
                </div>
                <div className='tpcas-page__edit--creds-block'>
                    <YandexGoCredsBlock
                        creds={tpcas.credentials['yandex_go']}
                        addCred={addCred}
                        removeCred={removeCred}
                        onChange={(cred) =>
                            setTpcas((prev) => ({
                                ...(prev as ITpCas),
                                credentials: {
                                    ...prev?.credentials,
                                    yandex_go: cred,
                                },
                            }))
                        }
                    />

                    <IikoTransCredsBlock
                        creds={tpcas.credentials['iiko_transport']}
                        addCred={addCred}
                        removeCred={removeCred}
                        onChange={(cred) =>
                            setTpcas((prev) => ({
                                ...(prev as ITpCas),
                                credentials: {
                                    ...prev?.credentials,
                                    iiko_transport: cred,
                                },
                            }))
                        }
                    />

                    <IikoBizCredsBlock
                        creds={tpcas.credentials['iiko_biz']}
                        addCred={addCred}
                        removeCred={removeCred}
                        onChange={(cred) =>
                            setTpcas((prev) => ({
                                ...(prev as ITpCas),
                                credentials: {
                                    ...prev?.credentials,
                                    iiko_biz: cred,
                                },
                            }))
                        }
                    />

                    <TillypadCredsBlock
                        creds={tpcas.credentials['tillypad']}
                        addCred={addCred}
                        removeCred={removeCred}
                        onChange={(cred) =>
                            setTpcas((prev) => ({
                                ...(prev as ITpCas),
                                credentials: {
                                    ...prev?.credentials,
                                    tillypad: cred,
                                },
                            }))
                        }
                    />
                </div>
            </div>
            <Button isLoading={loading} className='btn-submit' onClick={onSubmit}>
                Сохранить
            </Button>
        </section>
    )
}

interface YandexGoCredsProps {
    creds: YandexGoCreds | null
    onChange: (creds: YandexGoCreds) => void
    addCred: (credName: string) => void
    removeCred: (credName: string) => void
}
const YandexGoCredsBlock = ({ creds, onChange, addCred, removeCred }: YandexGoCredsProps) => {
    return (
        <div className='creds-block'>
            <div className='creds-block__header'>
                <h3>Yandex GO</h3>
                {creds ? (
                    <button className='btn-remove' onClick={() => removeCred('yandex_go')}>
                        Удалить
                    </button>
                ) : (
                    <button className='btn-add' onClick={() => addCred('yandex_go')}>
                        Добавить
                    </button>
                )}
            </div>

            {creds && (
                <div className='creds-block__body'>
                    <div className='creds-block__input'>
                        <label>bearer_token</label>
                        <input value={creds?.bearer_token || ''} onChange={({ target }) => onChange({ ...creds, bearer_token: target.value })} />
                    </div>
                </div>
            )}
        </div>
    )
}

interface IikoTransCredsProps {
    creds: IIKOTRANSCreds | null
    onChange: (creds: IIKOTRANSCreds) => void
    addCred: (credName: string) => void
    removeCred: (credName: string) => void
}

const IikoTransCredsBlock = ({ creds, onChange, addCred, removeCred }: IikoTransCredsProps) => {
    return (
        <div className='creds-block'>
            <div className='creds-block__header'>
                <h3>Iiko Transport</h3>
                {creds ? (
                    <button className='btn-remove' onClick={() => removeCred('iiko_transport')}>
                        Удалить
                    </button>
                ) : (
                    <button className='btn-add' onClick={() => addCred('iiko_transport')}>
                        Добавить
                    </button>
                )}
            </div>

            {creds && (
                <div className='creds-block__body'>
                    <div className='creds-block__input'>
                        <label>organization</label>
                        <input value={creds?.organization || ''} onChange={({ target }) => onChange({ ...creds, organization: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>apiLogin</label>
                        <input value={creds?.apiLogin || ''} onChange={({ target }) => onChange({ ...creds, apiLogin: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>olapUrl</label>
                        <input value={creds?.olapUrl || ''} onChange={({ target }) => onChange({ ...creds, olapUrl: target.value })} />
                    </div>
                    <div className='creds-block__checkbox'>
                        <input id='itrans_cb' type='checkbox' checked={creds?.isEnabled} onChange={({ target }) => onChange({ ...creds, isEnabled: target.checked })} />
                        <label htmlFor='itrans_cb'>isEnabled</label>
                    </div>
                </div>
            )}
        </div>
    )
}

interface IikoBizProps {
    creds: IIKOBIZCreds | null
    onChange: (creds: IIKOBIZCreds) => void
    addCred: (credName: string) => void
    removeCred: (credName: string) => void
}

const IikoBizCredsBlock = ({ creds, onChange, addCred, removeCred }: IikoBizProps) => {
    return (
        <div className='creds-block'>
            <div className='creds-block__header'>
                <h3>Iiko Biz</h3>
                {creds ? (
                    <button className='btn-remove' onClick={() => removeCred('iiko_biz')}>
                        Удалить
                    </button>
                ) : (
                    <button className='btn-add' onClick={() => addCred('iiko_biz')}>
                        Добавить
                    </button>
                )}
            </div>
            {creds && (
                <div className='creds-block__body'>
                    <div className='creds-block__input'>
                        <label>organization</label>
                        <input value={creds?.organization || ''} onChange={({ target }) => onChange({ ...creds, organization: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>login</label>
                        <input value={creds?.login || ''} onChange={({ target }) => onChange({ ...creds, login: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>password</label>
                        <input value={creds?.password || ''} onChange={({ target }) => onChange({ ...creds, password: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>olapUrl</label>
                        <input value={creds?.olapUrl || ''} onChange={({ target }) => onChange({ ...creds, olapUrl: target.value })} />
                    </div>
                    <div className='creds-block__checkbox'>
                        <input id='ibiz_cb' type='checkbox' checked={creds?.isEnabled} onChange={({ target }) => onChange({ ...creds, isEnabled: target.checked })} />
                        <label htmlFor='ibiz_cb'>isEnabled</label>
                    </div>
                </div>
            )}
        </div>
    )
}

interface TillypadProps {
    creds: TILLYPADCreds | null
    onChange: (creds: TILLYPADCreds) => void
    addCred: (credName: string) => void
    removeCred: (credName: string) => void
}

const TillypadCredsBlock = ({ creds, onChange, addCred, removeCred }: TillypadProps) => {
    return (
        <div className='creds-block'>
            <div className='creds-block__header'>
                <h3>Tillypad</h3>
                {creds ? (
                    <button className='btn-remove' onClick={() => removeCred('tillypad')}>
                        Удалить
                    </button>
                ) : (
                    <button className='btn-add' onClick={() => addCred('tillypad')}>
                        Добавить
                    </button>
                )}
            </div>

            {creds && (
                <div className='creds-block__body'>
                    <div className='creds-block__input'>
                        <label>base_url</label>
                        <input value={creds?.base_url || ''} onChange={({ target }) => onChange({ ...creds, base_url: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>ppgr_ID</label>
                        <input value={creds?.ppgr_ID || ''} onChange={({ target }) => onChange({ ...creds, ppgr_ID: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>clgr_ID</label>
                        <input value={creds?.clgr_ID || ''} onChange={({ target }) => onChange({ ...creds, clgr_ID: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>cpop_cptp_ID</label>
                        <input value={creds?.cpop_cptp_ID || ''} onChange={({ target }) => onChange({ ...creds, cpop_cptp_ID: target.value })} />
                    </div>
                    <div className='creds-block__input'>
                        <label>cpop_user_ID</label>
                        <input value={creds?.cpop_user_ID || ''} onChange={({ target }) => onChange({ ...creds, cpop_user_ID: target.value })} />
                    </div>
                    <div className='creds-block__checkbox'>
                        <input id='tp_cb' type='checkbox' checked={creds?.isEnabled} onChange={({ target }) => onChange({ ...creds, isEnabled: target.checked })} />
                        <label htmlFor='tp_cb'>isEnabled</label>
                    </div>
                </div>
            )}
        </div>
    )
}
