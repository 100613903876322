import {  createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ITerminal } from '../../interfaces/Terminals'
import { addIikoTerminal, clearAddTerminalState } from '../actions/terminals/add'
import { deleteTerminal, disableTerminal, enableTerminal } from '../actions/terminals/delete'
import { getAllTerminals, getTerminalById, getTerminalsByConcept, getTerminalsByGroup } from '../actions/terminals/get'
import { clearUpdateTerminalState, updateIikoTerminal } from '../actions/terminals/update'
import { TerminalState } from '../types/terminals'

const initialState: TerminalState = {
    terminals: [],
    getTerminals: {
        loading: false,
        error: '',
    },
    count: 0,
    limit: 0,
    page: 0,
    pages: 0,
    terminal: {} as ITerminal,
    getTerminal: {
        loading: false,
        error: '',
    },
    addTerminal: {
        isSuccess: false,
        loading: false,
        error: ''
    },
    editTerminal: {
        isSuccess: false,
        loading: false,
        error: ''
    }
}

export const terminalsSlice = createSlice({
    name: 'terminals',
    initialState,
    reducers: {
        updateTerminalState: (state, action: PayloadAction<ITerminal>) => {
            state.terminal = action.payload
        }
    },
    extraReducers: {
        [getTerminalsByGroup.pending.type]: (state) => {  
            state.getTerminals.error = ''
            state.getTerminals.loading = true
            state.terminals = []
        },
        [getTerminalsByGroup.fulfilled.type]: (state, action: PayloadAction<IApi<ITerminal>>) => {
            state.getTerminals.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.terminals = action.payload.data
        },
        [getTerminalsByGroup.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminals.error = action.error.message
            state.getTerminals.loading = false
        },
        [getTerminalsByConcept.pending.type]: (state) => {  
            state.getTerminals.error = ''
            state.getTerminals.loading = true
            state.terminals = []
        },
        [getTerminalsByConcept.fulfilled.type]: (state, action: PayloadAction<IApi<ITerminal>>) => {
            state.getTerminals.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.terminals = action.payload.data
        },
        [getTerminalsByConcept.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminals.error = action.error.message
            state.getTerminals.loading = false
        },
        [getAllTerminals.pending.type]: (state) => {
            state.getTerminals.error = ''
            state.getTerminals.loading = true
            state.terminals = []
        },
        [getAllTerminals.fulfilled.type]: (state, action: PayloadAction<IApi<ITerminal>>) => {
            state.getTerminals.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.terminals = action.payload.data
        },
        [getAllTerminals.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminals.error = action.error.message
            state.getTerminals.loading = false
        },
        [getTerminalById.pending.type]: (state) => {
            state.getTerminal.error = ''
            state.getTerminal.loading = true
        },
        [getTerminalById.fulfilled.type]: (state, action: PayloadAction<ITerminal>) => {
            state.getTerminal.loading = false
            state.terminal = action.payload
        },
        [getTerminalById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminal.error = action.error.message
            state.getTerminal.loading = false
        },
        [disableTerminal.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminals = state.terminals.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = true
                return tg
            })
        },
        [enableTerminal.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminals = state.terminals.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = false
                return tg
            })
        },
        [deleteTerminal.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminals = state.terminals.map(tg => {
                if(tg._id === action.payload) {
                    // tg.isDisabled = true
                    tg.isDeleted = !tg.isDeleted
                }
                    
                return tg
            })
        },
        [updateIikoTerminal.pending.type]: (state) => {
            state.editTerminal.error = ''
            state.editTerminal.loading = true
            state.editTerminal.isSuccess = false
        },
        [updateIikoTerminal.fulfilled.type]: (state) => {
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = true
        },
        [updateIikoTerminal.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editTerminal.error = action.error.message
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = false
        },
        [clearUpdateTerminalState.fulfilled.type]: (state) => {
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = false
            state.editTerminal.error = ''
        },
        [addIikoTerminal.pending.type]: (state) => {
            state.editTerminal.error = ''
            state.editTerminal.loading = true
            state.editTerminal.isSuccess = false
        },
        [addIikoTerminal.fulfilled.type]: (state) => {
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = true
        },
        [addIikoTerminal.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editTerminal.error = action.error.message
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = false
        },
        [clearAddTerminalState.fulfilled.type]: (state) => {
            state.editTerminal.loading = false
            state.editTerminal.isSuccess = false
            state.editTerminal.error = ''
        },
    }
})

export const terminalsReducer = terminalsSlice.reducer
export const {updateTerminalState} = terminalsSlice.actions