import React, { FC, useCallback, useEffect, useState } from 'react'
import { TpCasService } from '../../api/TpCasService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ITpCas } from '../../interfaces/TpCas'
import { IMultiSelectOptions, MultiSelect } from '../UI/MultiSelect/MultiSelect'

interface TpCassesSelectProps {
    onChange: (badges: ITpCas[]) => void
    selectedCasses: ITpCas[]
}

export const TpCassesSelect: FC<TpCassesSelectProps> = ({
    onChange,
    selectedCasses,
}) => {
    const [currPage, setCurrPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [casses, setCasses] = useState<ITpCas[]>([])
    const limit = 10
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [cassesArray, setCassesArray] = useState<ITpCas[]>([])

    useEffect(() => {
        let isM = true
        const getCasses = async (page: number) => {
            try {
                const api = new TpCasService()
                const _casses = await api.getTpCas(page, limit)
                if (isM) {
                    setPages(_casses.pages)
                    setCasses(_casses.data)
                }
            } catch (e: any) {
                console.log(e)
                setStatus({
                    loading: false,
                    error: e,
                })
            }
        }
        getCasses(currPage)
        return () => {
            isM = false
        }
    }, [currPage])
    useEffect(() => {
        setCassesArray((prev) => [...prev, ...casses])
    }, [casses])

    const cb = useCallback(() => {
        console.log([limit, currPage, pages])

        if (pages <= currPage) return
        setCurrPage((prev) => prev + 1)
    }, [limit, currPage])

    const optionsToCass = (values: IMultiSelectOptions[]) => {
        const _casses = cassesArray.filter((b) => {
            for (let value of values) {
                if (value.id === b._id) return true
            }
            return false
        })
        onChange(_casses)
    }

    const getOptionsWithoutSelected = () => {
        return cassesArray
            .map((b) => ({
                id: b._id,
                value: b.name,
            }))
            .filter((b) => {
                for (let i = 0; i < selectedCasses.length; i++) {
                    if (b.id === selectedCasses[i]._id) return false
                }
                return true
            })
    }

    if (cassesArray.length === 0) return null
    return (
        <MultiSelect
            options={getOptionsWithoutSelected()}
            defaultValue={selectedCasses.map((s) => ({
                id: s._id,
                value: s.name,
            }))}
            onChange={optionsToCass}
            loading={status.loading}
            cb={cb}
        />
    )
}
