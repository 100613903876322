import { Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IikoModifiersService } from '../../api/IikoModifiersService'
import { DragNDropImage } from '../../components/DragNDropImage/DragNDropImage'
import { CustomModal } from '../../components/Modal/CustomModal'
import { Input } from '../../components/UI/Input/Input'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IActionModifiers, IModifiers } from '../../interfaces/IikoProducts'
import './EditModGroup.scss'

const EditModGroup = () => {
    const { terminalId, groupId } = useParams()
    const { getTerminalById } = useActions()
    const { terminal } = useTypedSelector((state) => state.terminalsReducer)
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    // const [techInfo, setInfo] = useState('')
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(0)
    const [file, setFile] = useState<File>()
    const [imgPreview, setImg] = useState('')
    const maxAspectRatio = [256, 256]
    const [fileError, setFileError] = useState('')

    const [_group, setGroup] = useState<IModifiers>()

    useEffect(() => {
        let isM = true
        const getGroup = async (id: string) => {
            try {
                const api = new IikoModifiersService()
                const group = await api.getIikoModifiersGroupById(id)
                if (isM) {
                    setName(group.name)
                    setMax(group.maxAmount)
                    setMin(group.minAmount)
                    setGroup(group)
                }
            } catch (e) {
                console.log(e)
            }
        }
        if (groupId) getGroup(groupId)
        if (terminalId) getTerminalById(terminalId)
        return () => {
            isM = false
        }
    }, [])
    useEffect(() => {
        if (terminal._id)
            document.title = `Модификаторы/${terminal.address}/Добавление группы модификаторов`
    }, [terminal])

    useEffect(() => {
        document.title = 'Редактирование группы модификаторов'
    }, [])

    const onNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }
    // const onSlugChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSlug(e.target.value)
    // }
    const onMinChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMin(Math.abs(+e.target.value))
    }
    const onMaxChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMax(Math.abs(+e.target.value))
    }
    // const onInfoChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     setInfo(e.target.value)
    // }
    // const onImageChageHandler = (
    //     image: string,
    //     imageFile: File | null,
    //     error?: string
    // ) => {
    //     if (error) {
    //         setFileError(error)
    //         setFile(undefined)
    //         setImg('')
    //     } else {
    //         setFileError('')
    //         setFile(imageFile!)
    //         setImg(image)
    //     }
    // }
    // const removeImageHandler = () => {
    //     setFile(undefined)
    //     setImg('')
    // }

    const [isOpen, setOpen] = useState(false)

    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })

    // useEffect(() => {
    //     if (isSuccess || (!isSuccess && error.trim() !== '')) setOpen(true)
    // }, [statsuerror])
    const { admin } = useTypedSelector((state) => state.userReducer)
    const onSubmit = () => {
        // if (file) {
        // }

        const editGroup = async (group: IActionModifiers) => {
            try {
                setStatus({ loading: true, error: '' })
                const api = new IikoModifiersService()
                const res = await api.updateModifiersGroup(group)
                setStatus({ loading: false, error: '' })
            } catch (e: any) {
                setStatus({
                    loading: false,
                    error: e.message,
                })
                console.error(e)
            } finally {
                setOpen(true)
            }
        }
        const companyId = localStorage.getItem('company')
        if (_group)
            editGroup({
                _id: groupId,
                externalId: _group.externalId,
                name,
                order: _group.order,
                image: null,
                maxAmount: max,
                minAmount: min,
                required: _group.required,
                source: 'manual',
                isDeleted: _group.isDeleted,
                isDisabled: _group.isDisabled,
                isHidden: _group.isHidden,
                terminalId: _group.terminalId,
                options: _group.options,
                companyId: companyId || admin.companyId,
            })
    }
    return (
        <section className='add-mod-group'>
            <header className='add-mod-group__header'>
                {terminal._id ? (
                    <div className='add-mod-group__header--name'>
                        <Link
                            className='add-mod-group__header--name-link'
                            to={'/modificators-terminal'}>
                            Модификаторы
                        </Link>
                        /{terminal.address}/Редактирование группы модификаторов
                    </div>
                ) : null}
            </header>
            <div className='add-mod-group__content'>
                <div className='add-mod-group__content-inputs'>
                    <div className='add-mod-group__content-inputs--left-side'>
                        <div className='add-mod-group__content-inputs--name'>
                            <label className='input-name__label'>
                                Название
                            </label>
                            <Input
                                className='input-name__input'
                                value={name}
                                onChange={onNameChangeHandler}
                                placeholder='Название'
                            />
                        </div>
                        {/* <div className='add-mod-group__content-inputs--slug'>
                            <label className='input-name__label'>Slug</label>
                            <Input
                                className='input-name__input'
                                value={slug}
                                onChange={onSlugChangeHandler}
                                placeholder='slug'
                            />
                        </div> */}
                        {/* <div className='add-mod-group__content-inputs--tech-info'>
                            <label className='input-name__label'>
                                Техническая информация
                            </label>
                            <textarea
                                className='input-name__input textarea'
                                value={techInfo}
                                onChange={onInfoChangeHandler}
                                placeholder='Техническая информация'
                            />
                        </div> */}
                        <div className='add-mod-group__content-inputs--amount'>
                            <div className='add-mod-group__content-inputs--min'>
                                <label className='input-name__label'>
                                    Min количество
                                </label>
                                <Input
                                    className='input-name__input'
                                    type='number'
                                    value={min.toString()}
                                    onChange={onMinChangeHandler}
                                    placeholder=''
                                />
                            </div>
                            <div className='add-mod-group__content-inputs--max'>
                                <label className='input-name__label'>
                                    Max количество
                                </label>
                                <Input
                                    className='input-name__input'
                                    type='number'
                                    value={max.toString()}
                                    onChange={onMaxChangeHandler}
                                    placeholder=''
                                />
                            </div>
                            {/* <div className='add-mod-group__content-inputs--max'>
                                <label className='input-name__label'>
                                    Цена
                                </label>
                                <Input
                                    className='input-name__input'
                                    type='number'
                                    value={price.toString()}
                                    onChange={onPriceChangeHandler}
                                    placeholder=''
                                />
                            </div> */}
                        </div>
                    </div>

                    {/* <div className='add-mod-group__content-inputs--img'>
                        {imgPreview ? (
                            <div className='inputs-img__preview-wrapper'>
                                <img
                                    className='inputs-img__preview'
                                    src={imgPreview}
                                    alt='preview'
                                />
                                <div
                                    className='inputs-img__preview-remove'
                                    onClick={removeImageHandler}></div>
                            </div>
                        ) : null}
                        {fileError ? (
                            <div className='inputs-img__error'>
                                <div></div>
                                {fileError}
                            </div>
                        ) : null}
                        <DragNDropImage
                            maxAspectRatio={maxAspectRatio}
                            acceptTypes='.png'
                            onChange={onImageChageHandler}
                        />
                    </div> */}
                </div>
                <Button
                    isLoading={status.loading}
                    className='add-mod-group__submit'
                    onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/menu'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось обновить группу модификаторов'
                message='Группа модификаторов успешно обновлена'
                details={status.error}
            />
        </section>
    )
}

export default EditModGroup
