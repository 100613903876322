import React, { FC, useEffect, useState } from 'react'
import { Input } from '../Input/Input'

interface PhoneInputProps {
    className?: string
    placeholder?: string
    onChange: (value: string) => void
    value: string
}

export const PhoneInput: FC<PhoneInputProps> = ({
    className = '',
    placeholder = '',
    onChange,
    value: data,
}) => {
    const [value, setValue] = useState(data)
    useEffect(() => {
        setValue(data)
    }, [data])
    const getInputNumbersValue = (inputValue: string) => {
        return inputValue.replace(/\D/g, '')
    }
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ruCodes = ['7', '8', '9']
        let input = e.target,
            inputNumberValue = getInputNumbersValue(input.value),
            selectionStart = input.selectionStart,
            formattedValue = ''

        if (!inputNumberValue) {
            return setValue('')
        }
        if (input.value.length !== selectionStart) {
            const { data } = e.nativeEvent as InputEvent

            if (data && /\D/g.test(data)) {
                return setValue(inputNumberValue)
            }
            console.log(input.value.length)

            if (input.value.length !== 11) return setValue(input.value)
        }
        if (ruCodes.indexOf(inputNumberValue[0]) > -1) {
            if (inputNumberValue[0] === '9')
                inputNumberValue = `7${inputNumberValue}`
            const firstSymbols = inputNumberValue[0] === '8' ? '8' : '+7'
            formattedValue = `${firstSymbols} `
            if (inputNumberValue.length > 1)
                formattedValue += `(${inputNumberValue.substring(1, 4)}`
            if (inputNumberValue.length >= 5)
                formattedValue += `) ${inputNumberValue.substring(4, 7)}`
            if (inputNumberValue.length >= 8)
                formattedValue += `-${inputNumberValue.substring(7, 9)}`
            if (inputNumberValue.length >= 10)
                formattedValue += `-${inputNumberValue.substring(9, 11)}`
        } else formattedValue = `+${inputNumberValue.substring(0, 16)}`
        setValue(formattedValue)
        onChange(formattedValue)
    }
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value.replace(
            /\D/g,
            ''
        )
        if (e.key === 'Backspace' && inputValue.length === 1) setValue('')
    }
    return (
        <Input
            className={className}
            placeholder={placeholder}
            type='tel'
            value={value}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHandler}
            maxLength={18}
        />
    )
}
