import { addTerminalGroups, clearAddTerminalGroupsState } from './add'
import { deleteTerminalGroup, disableTerminalGroup, enableTerminalGroup } from './disable'
import {getTerminalsGroupByConcept, getTerminalsGroupById} from './get'
import { clearUpdateTerminalGroupsState, updateTerminalGroups } from './update'

export const TerminalsGroupActions = {
    getTerminalsGroupByConcept,
    disableTerminalGroup,
    addTerminalGroups,
    clearAddTerminalGroupsState,
    updateTerminalGroups,
    clearUpdateTerminalGroupsState,
    getTerminalsGroupById,
    deleteTerminalGroup,
    enableTerminalGroup
}