import classNames from 'classnames'
import React, { FC, useRef, useState } from 'react'
import { Icon } from '../Icon/Icon'
import './DragNDrop.scss'

interface DragNDropProps {
    onChange: (file: File) => void
    isEmpty?: boolean
    // eslint-disable-next-line no-undef
    isEmptyText: JSX.Element
    // eslint-disable-next-line no-undef
    text: JSX.Element
    acceptTypes?: string
}

export const DragNDrop: FC<DragNDropProps> = ({
    onChange,
    isEmpty = true,
    isEmptyText,
    text,
    acceptTypes = '.*',
}) => {
    const [isDrag, setDrag] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [files, setFiles] = useState('')

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = ((e.target as HTMLInputElement).files as FileList)[0]
        onChange(file)
        setFiles('')
    }
    const onDragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(true)
    }
    const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(false)
    }
    const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        onChange(file)
        setDrag(false)
    }
    const onInputClickHandler = () => {
        inputRef.current?.click()
    }
    const dragStyles = classNames({
        'drag-n-drop': true,
        'drag-start': isDrag,
    })
    if (isEmpty)
        return (
            <div
                className={dragStyles}
                onDragStart={onDragStartHandler}
                onDragOver={onDragStartHandler}
                onDragLeave={onDragOverHandler}
                onClick={onInputClickHandler}
                onDrop={onDropHandler}>
                <Icon name='Drag' />
                {isEmptyText}
                <input
                    className='drag-n-drop__input'
                    type='file'
                    onChange={onChangeHandler}
                    ref={inputRef}
                    accept={acceptTypes}
                    value={files}
                />
            </div>
        )
    return (
        <div
            className='drag-n-drop__wrapper'
            onDragStart={onDragStartHandler}
            onDragOver={onDragStartHandler}
            onDragLeave={onDragOverHandler}
            onClick={onInputClickHandler}
            onDrop={onDropHandler}>
            <div className={dragStyles}>
                <Icon name='Drag' />
            </div>
            {text}
            <input
                className='drag-n-drop__input'
                type='file'
                onChange={onChangeHandler}
                ref={inputRef}
                accept={acceptTypes}
                value={files}
            />
        </div>
    )
}
