import { createAsyncThunk } from '@reduxjs/toolkit'
import { PushService } from '../../../api/PushService'

interface IDelete {
    id: string
    isDeleted: boolean
}
export const deletePush = createAsyncThunk('push/deletePush',
    async(args: IDelete, thunkApi) => {
        const api = new PushService()
        const {id, isDeleted} = args
        const response = await api.delete(id, {isDeleted})
        return id
    }
)
export const disablePush = createAsyncThunk('push/disablePush',
    async(id: string, thunkApi) => {
        const api = new PushService()
        const response = await api.delete(id, {isDisabled: true})
        return id
    }
)

export const enablePush = createAsyncThunk('push/enablePush',
    async(id: string, thunkApi) => {
        const api = new PushService()
        const response = await api.delete(id, {isDisabled: false})
        return id
    }
)
