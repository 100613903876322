import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IConcept } from '../../interfaces/Concept'
import { addConcept, clearAddConceptState } from '../actions/concepts/add'
import { deleteConcept, disableConcept, enableConcept } from '../actions/concepts/disable'
import { clearConceptState, getAllConcepts, getConceptById } from '../actions/concepts/get'
import { clearUpdateConceptState, updateConcept } from '../actions/concepts/update'
import { ConceptsState } from '../types/concepts'


const initialState: ConceptsState = {
    limit: 0,
    pages: 0,
    page: 0,
    concepts: [],
    concept: {} as IConcept,
    getConcepts: {
        loading: false,
        error: ''
    },
    getConcept: {
        loading: false,
        error: ''
    },
    addConcept: {
        loading: false,
        error: '',
        isSuccess: false
    },
    editConcept: {
        loading: false,
        error: '',
        isSuccess: false
    },
}


export const conceptSlice = createSlice({
    name: 'concept',
    initialState,
    reducers: {
        clear: (state) => {
            state.concepts = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllConcepts.pending.type]: (state) => {
            state.getConcepts.error = ''
            state.getConcepts.loading = true
            // state.concepts = []
        },
        [getAllConcepts.fulfilled.type]: (state, action: PayloadAction<IApi<IConcept>>) => {
            state.getConcepts.error = ''
            state.getConcepts.loading = false
            state.concepts = [...state.concepts, ...action.payload.data]
            // state.concepts = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getAllConcepts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getConcepts.error = action.error.message
            state.getConcepts.loading = false
        },
        [getConceptById.pending.type]: (state) => {
            state.getConcept.error = ''
            state.getConcept.loading = true
        },
        [getConceptById.fulfilled.type]: (state, action: PayloadAction<IConcept>) => {
            state.getConcept.error = ''
            state.getConcept.loading = false
            state.concept = action.payload
        },
        [getConceptById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getConcept.error = action.error.message
            state.getConcept.loading = false
        },
        [clearConceptState.fulfilled.type]: (state) => {
            state.concept = {} as IConcept
        },
        [addConcept.pending.type]: (state) => {
            state.addConcept.error = ''
            state.addConcept.loading = true
            state.addConcept.isSuccess = false
        },
        [addConcept.fulfilled.type]: (state) => {
            state.addConcept.error = ''
            state.addConcept.loading = false
            state.addConcept.isSuccess = true
        },
        [addConcept.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addConcept.error = action.error.message
            state.addConcept.loading = true
            state.addConcept.isSuccess = false
        },
        [clearAddConceptState.fulfilled.type]: (state) => {
            state.addConcept.error = ''
            state.addConcept.loading = false
            state.addConcept.isSuccess = false
        },
        [updateConcept.pending.type]: (state) => {
            state.editConcept.error = ''
            state.editConcept.loading = true
            state.editConcept.isSuccess = false
        },
        [updateConcept.fulfilled.type]: (state) => {
            state.editConcept.error = ''
            state.editConcept.loading = false
            state.editConcept.isSuccess = true
        },
        [updateConcept.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editConcept.error = action.error.message
            state.editConcept.loading = true
            state.editConcept.isSuccess = false
        },
        [clearUpdateConceptState.fulfilled.type]: (state) => {
            state.editConcept.error = ''
            state.editConcept.loading = false
            state.editConcept.isSuccess = false
        },
        [disableConcept.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.concepts = state.concepts.map(c => {
                if(c._id === action.payload)
                    c.isDisabled = true
                return c
            })
        },
        [enableConcept.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.concepts = state.concepts.map(c => {
                if(c._id === action.payload)
                    c.isDisabled = false
                return c
            })
        },
        [deleteConcept.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.concepts = state.concepts.map(c => {
                if(c._id === action.payload) {
                    c.isDeleted = !c.isDeleted
                }
                    
                return c
            })
        },
    }
})

export const conceptsReducer = conceptSlice.reducer
export const conceptsActions = conceptSlice.actions