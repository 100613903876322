import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IBadge } from '../../interfaces/Badges'
import { addBadge, addBadgeClearState } from '../actions/badge/add'
import { getAllBadges } from '../actions/badge/get'
import { BadgesState } from '../types/badge'


const initialState: BadgesState = {
    limit: 0,
    pages: 0,
    page: 0,
    badges: [],
    getBadges: {
        loading: false,
        error: ''
    },
    addBadge: {
        loading: false,
        error: '',
        isSuccess: false
    }
    // getBadge: {
    //     loading: false,
    //     error: ''
    // }
}


export const badgeSlice = createSlice({
    name: 'badge',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllBadges.pending.type]: (state) => {
            state.getBadges.error = ''
            state.getBadges.loading = true
        },
        [getAllBadges.fulfilled.type]: (state, action: PayloadAction<IApi<IBadge>>) => {
            state.getBadges.error = ''
            state.getBadges.loading = false
            state.badges = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getAllBadges.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getBadges.error = action.error.message
            state.getBadges.loading = false
        },
        [addBadge.pending.type]: (state) => {
            state.addBadge.loading = true
            state.addBadge.error = ''
            state.addBadge.isSuccess = false
        },
        [addBadge.fulfilled.type]: (state) => {
            state.addBadge.loading = false
            state.addBadge.error = ''
            state.addBadge.isSuccess = true
        },
        [addBadge.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addBadge.loading = false
            state.addBadge.error = action.error.message
            state.addBadge.isSuccess = false
        },
        [addBadgeClearState.fulfilled.type]: (state) => {
            state.addBadge.loading = false
            state.addBadge.error = ''
            state.addBadge.isSuccess = false
        },
    }
})

export const badgeReducer = badgeSlice.reducer