import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ISegment } from '../../interfaces/Segments'
import { addSegment, addSegmentPhones, clearAddSegmentState } from '../actions/segment/add'
import { deleteSegment, disableSegment, enableSegment } from '../actions/segment/disable'
import { getAllSegments, getSegmentById } from '../actions/segment/get'
import { SegmentsState } from '../types/segment'

const initialState: SegmentsState = {
    segments: [],
    getSegments: {
        loading: false,
        error: ''
    },
    limit: 0,
    pages: 0,
    page: 0,
    segment: {} as ISegment,
    getSegment: {
        loading: false,
        error: ''
    },
    addSegment: {
        loading: false,
        error: '',
        isSuccess: false
    },
}
export const segmentSlice = createSlice({
    name: 'segment',
    initialState,
    reducers: {
        clear: (state) => {
            state.segments = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllSegments.pending.type]: (state) => {
            state.getSegments.error = ''
            state.getSegments.loading = true
            // state.segments = []
        },
        [getAllSegments.fulfilled.type]: (state, action: PayloadAction<IApi<ISegment>>) => {
            state.segments = [...state.segments, ...action.payload.data]
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.getSegments.loading = false
        },
        [getAllSegments.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getSegments.error = action.error.message
            state.getSegments.loading = false
        },
        [getSegmentById.pending.type]: (state) => {
            state.getSegment.error = ''
            state.getSegment.loading = true
        },
        [getSegmentById.fulfilled.type]: (state, action: PayloadAction<ISegment>) => {
            state.segment = action.payload
            state.getSegment.loading = false
        },
        [getSegmentById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getSegment.error = action.error.message
            state.getSegment.loading = false
        },
        [addSegment.pending.type]: (state) => {
            state.addSegment.error = ''
            state.addSegment.loading = true
            state.addSegment.isSuccess = false
        },
        [addSegment.fulfilled.type]: (state) => {
            state.addSegment.error = ''
            state.addSegment.loading = false
            state.addSegment.isSuccess = true
        },
        [addSegment.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addSegment.error = action.error.message
            state.addSegment.loading = true
            state.addSegment.isSuccess = false
        },
        [clearAddSegmentState.fulfilled.type]: (state) => {
            state.addSegment.error = ''
            state.addSegment.loading = false
            state.addSegment.isSuccess = false
        },
        [addSegmentPhones.pending.type]: (state) => {
            state.addSegment.error = ''
            state.addSegment.loading = true
            state.addSegment.isSuccess = false
        },
        [addSegmentPhones.fulfilled.type]: (state) => {
            state.addSegment.error = ''
            state.addSegment.loading = false
            state.addSegment.isSuccess = true
        },
        [addSegmentPhones.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addSegment.error = action.error.message
            state.addSegment.loading = true
            state.addSegment.isSuccess = false
        },
        [disableSegment.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.segments = state.segments.map(segment => {
                if(segment._id === action.payload)
                    segment.isDisabled = true
                return segment
            })
        },
        [enableSegment.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.segments = state.segments.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = false
                return tg
            })
        },
        [deleteSegment.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.segments = state.segments.map(tg => {
                if(tg._id === action.payload) {
                    // tg.isDisabled = true
                    tg.isDeleted = !tg.isDeleted
                }
                    
                return tg
            })
        },
    }
})
export const segmentReducer = segmentSlice.reducer
export const segmentAction = segmentSlice.actions