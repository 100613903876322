import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { OrderStatesService } from '../../api/OrderStatesService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { OrderStatesTable } from '../../components/OrderStatesTable/OrderStatesTable'
import { Icon } from '../../components/UI/Icon/Icon'
import { IConcept } from '../../interfaces/Concept'
import { IOrderState } from '../../interfaces/OrderState'
import './OrderStates.scss'
export const OrderStates = () => {
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [states, setStates] = useState<IOrderState[]>([])
    const [concept, setConcept] = useState<IConcept>()
    useEffect(() => {
        document.title = 'Статусы'
        return () => {}
    }, [])

    useEffect(() => {
        let isMount = true

        const getContents = async (id: string) => {
            try {
                const api = new OrderStatesService()
                const _states = await api.get(id)
                if (isMount) {
                    setStates(_states)
                }
            } catch (error: any) {
                console.error(error)
                setStatus({
                    loading: false,
                    error: error.message,
                })
            }
        }
        if (concept) getContents(concept._id)
        return () => {
            isMount = false
        }
    }, [concept])
    return (
        <section className='order-states'>
            <header className='order-states__header'>
                <div className='order-states__header--name'>{'Статусы'}</div>
                <Link to={'./add'} className='order-states__header--btn-add'>
                    <Icon name='Plus' />
                    Добавить
                </Link>
            </header>
            <ConceptDropDown onChange={setConcept} />
            <OrderStatesTable states={states} />
        </section>
    )
}
