import React, { FC } from 'react'
import {
    IOrder,
    IOrderDeliveryTypes,
    IOrderPaymentTypes,
} from '../../interfaces/Order'
import { Table } from '../UI/Table/Table'
import { OrdersTableRow } from './OrdersTableRow'
import './OrdersTable.scss'
interface OrdersTableProps {
    orders: IOrder[]
    paymentTypes: IOrderPaymentTypes[]
    deliveryTypes: IOrderDeliveryTypes[]
}
export const OrdersTable: FC<OrdersTableProps> = ({
    orders,
    paymentTypes,
    deliveryTypes,
}) => {
    const headers = [
        'Номер',
        'Терминал',
        'Клиент',
        'Доставка',
        'Сумма',
        'Дата',
        'Статус',
    ]
    return (
        <Table className='orders-table' headers={headers}>
            {orders.map((order) => (
                <OrdersTableRow
                    key={order._id}
                    order={order}
                    paymentTypes={paymentTypes}
                    deliveryTypes={deliveryTypes}
                />
            ))}
        </Table>
    )
}
