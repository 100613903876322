import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TerminalsService } from '../../api/TerminalService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ITerminal } from '../../interfaces/Terminals'
import { ISelectOptions, Select } from '../UI/Select/Select'
import './TerminalSelect.scss'
interface TerminalSelectProps {
    groupId?: string
    conceptId?: string
    onChange: (terminal: string) => void
    className?: string
    defaultValue?: string
    _label?: string
}
export const TerminalSelect: FC<TerminalSelectProps> = ({
    groupId,
    conceptId,
    className = '',
    onChange,
    defaultValue = '',
    _label = 'Главный терминал',
}) => {
    const { getTerminals, terminals, pages } = useTypedSelector(
        (state) => state.terminalsReducer
    )

    const getTerminalsOptionsMemo = useMemo(
        () =>
            terminals.map((terminal) => {
                return {
                    id: terminal._id,
                    value: terminal.address,
                }
            }),
        [conceptId, terminals]
    )

    const { getTerminalsByGroup, getTerminalsByConcept } = useActions()
    const [currPage, setCurrPage] = useState(1)
    const [defTerminal, setDefTerminal] = useState<ITerminal>()
    const onChangeTerminal = (data: ISelectOptions) => {
        let newTerminal =
            terminals.find((l) => l._id === data.id) || ({} as ITerminal)
        onChange(newTerminal._id)
    }
    useEffect(() => {
        if (groupId)
            getTerminalsByGroup({
                page: currPage,
                groupId,
            })
    }, [currPage, groupId])
    useEffect(() => {
        if (conceptId)
            getTerminalsByConcept({
                page: currPage,
                conceptId,
            })
    }, [currPage, conceptId])
    useEffect(() => {
        if (!defaultValue) onChangeTerminal(getTerminalsOptionsMemo[0])
    }, [terminals])
    useEffect(() => {
        let isMount = true
        const getTerminal = async (id: string) => {
            try {
                const api = new TerminalsService()
                const _terminal = await api.getById(id)
                if (isMount) setDefTerminal(_terminal)
            } catch (e) {
                console.log(e)
            }
        }
        if (defaultValue) getTerminal(defaultValue)

        return () => {
            isMount = false
        }
    }, [defaultValue])
    const cb = useCallback(() => {
        console.log([currPage, pages])

        if (pages <= currPage) return
        setCurrPage((prev) => prev + 1)
    }, [currPage])
    useEffect(() => {
        console.log(defTerminal)
    }, [defTerminal])
    if (defaultValue) {
        return (
            <div className={`input-terminal ${className}`}>
                <label>{_label}</label>
                {defTerminal ? (
                    <Select
                        onChange={onChangeTerminal}
                        options={terminals.map((terminal) => {
                            return {
                                id: terminal._id,
                                value: terminal.address,
                            }
                        })}
                        defaultValue={{
                            id: defTerminal._id,
                            value: defTerminal.address,
                        }}
                        cb={cb}
                        loading={getTerminals.loading}
                    />
                ) : null}
            </div>
        )
    }

    return (
        <div className={`input-terminal ${className}`}>
            <label>{_label}</label>
            {terminals.length > 0 && (
                <Select
                    onChange={onChangeTerminal}
                    options={getTerminalsOptionsMemo}
                    defaultValue={getTerminalsOptionsMemo[0]}
                    cb={cb}
                    loading={getTerminals.loading}
                />
            )}
        </div>
    )
}
