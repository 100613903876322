import React, { FC } from 'react'
import { DragNDrop } from '../UI/DragNDrop/DragNDrop'

interface DragNDropImageProps {
    onChange: (image: string, imageFile: File | null, error?: string) => void
    isEmpty?: boolean
    acceptTypes?: string | string[]
    maxSize?: number | null
    accessAspectRatio?: number[] | null
    maxAspectRatio?: number[] | null
}

export const DragNDropImage: FC<DragNDropImageProps> = ({
    onChange,
    isEmpty = true,
    acceptTypes = 'image/*',
    maxSize = null,
    accessAspectRatio = null,
    maxAspectRatio = null,
}) => {
    const SpanEmpty = accessAspectRatio ? (
        <span>
            Перетащите фото или <span>выберите</span>.{' '}
            {`Размер ${accessAspectRatio[0]}x${accessAspectRatio[1]}px`}
        </span>
    ) : maxAspectRatio ? (
        <span>
            Перетащите фото или <span>выберите</span>.{' '}
            {`Максимальный размер ${maxAspectRatio[0]}x${maxAspectRatio[1]}px`}
        </span>
    ) : (
        <span>
            Перетащите фото или <span>выберите</span>.
        </span>
    )
    const SpanNotEmpty = (
        <span>
            Перетащите или <span>выберите</span> для загрузки нового фото
        </span>
    )
    const byteToMb = (byte: number) => byte / 1024 / 1024
    const createFileReader = (file: File) => {
        // if (maxSize) {
        //     if (maxSize < file.size)
        //         return onChange(
        //             '',
        //             null,
        //             `Размер файла больше ${byteToMb(maxSize)} мб`
        //         )
        // }
        // if (acceptTypes !== 'image/*') {
        //     const type = file.name.split('.')[file.name.split('.').length - 1]
        //     if (typeof acceptTypes === 'string') {
        //         if (
        //             acceptTypes.split('.')[1].toLocaleLowerCase() !==
        //             type.toLocaleLowerCase()
        //         )
        //             return onChange('', null, 'Неверный формат файла')
        //     } else {
        //         let flag = false
        //         acceptTypes.forEach((a) => {
        //             console.log(a.split('.')[1].toLocaleLowerCase())

        //             if (
        //                 a.split('.')[1].toLocaleLowerCase() ===
        //                 type.toLocaleLowerCase()
        //             )
        //                 flag = true
        //         })
        //         if (!flag) return onChange('', null, 'Неверный формат файла')
        //     }
        // }

        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = (e) => {
            const imgData = e.target?.result
            const image = new Image()
            image.onload = () => {
                // if (accessAspectRatio) {
                //     if (
                //         image.width !== accessAspectRatio[0] &&
                //         image.height !== accessAspectRatio[1]
                //     ) {
                //         onChange(
                //             '',
                //             null,
                //             `Неверный размер изображения, необходимо ${accessAspectRatio[0]}x${accessAspectRatio[1]}px`
                //         )
                //     } else onChange(fileReader.result as string, file)
                // } else if (maxAspectRatio) {
                //     if (
                //         image.width > maxAspectRatio[0] ||
                //         image.height > maxAspectRatio[1]
                //     ) {
                //         onChange(
                //             '',
                //             null,
                //             `Неверный размер изображения, необходимо не более ${maxAspectRatio[0]}x${maxAspectRatio[1]}px`
                //         )
                //     } else onChange(fileReader.result as string, file)
                // } else
                onChange(fileReader.result as string, file)
            }
            image.src = imgData as string
        }
        fileReader.onerror = () => {
            if (fileReader.error) {
                onChange('', null, fileReader.error.message)
            }
        }
    }

    return (
        <DragNDrop
            isEmpty={isEmpty}
            onChange={createFileReader}
            text={SpanNotEmpty}
            isEmptyText={SpanEmpty}
            // acceptTypes={
            //     typeof acceptTypes === 'string'
            //         ? acceptTypes
            //         : acceptTypes.join(', ')
            // }
            acceptTypes={'image/*'}
        />
    )
}
