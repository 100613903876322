import { Button } from '@chakra-ui/react'
import React, { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OrderStatesService } from '../../api/OrderStatesService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { Loader } from '../../components/Loader/Loader'
import { CustomModal } from '../../components/Modal/CustomModal'
import { TpCasDropDown } from '../../components/TpCasDropDown/TpCasDropDown'
import { Input } from '../../components/UI/Input/Input'
import { Select } from '../../components/UI/Select/Select'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
    IChangeOrderState,
    IOrderStateMessage,
} from '../../interfaces/OrderState'
import './EditOrderState.scss'
const initState: IChangeOrderState = {
    _id: 'new',
    name: '',
    comment: '',
    isDisabled: false,
    companyId: localStorage.getItem('company') || '',
    tpcasId: '',
    conceptId: '',
    terminalId: null,
    color: '#ffffff',
    shouldSendSMS: false,
    shouldSendPush: false,
    message: {
        title: '',
        subtitle: '',
        body: '',
        image: null,
    },
    feedbackMessage: {
        title: '',
        subtitle: '',
        body: '',
        image: null,
    },
    needFeedback: false,
    source: 'manual',
    integrationData: {},
}

const reducer = (state: IChangeOrderState, action: any): IChangeOrderState => {
    switch (action.type) {
        case 'setstate':
            return action.payload
        case 'setname':
            return { ...state, name: action.payload }
        case 'setcolor':
            return { ...state, color: action.payload }
        case 'setconcept':
            return { ...state, conceptId: action.payload }
        case 'settpcas':
            return { ...state, tpcasId: action.payload }
        case 'setmessagetype':
            if (action.payload === 'no')
                return {
                    ...state,
                    shouldSendPush: false,
                    shouldSendSMS: false,
                    message: null,
                }
            if (action.payload === 'push')
                return { ...state, shouldSendPush: true, shouldSendSMS: false }
            if (action.payload === 'sms')
                return { ...state, shouldSendPush: false, shouldSendSMS: true }
            return state
        case 'setfeedbacktype':
            if (action.payload === 'no')
                return { ...state, needFeedback: false, feedbackMessage: null }
            return { ...state, needFeedback: true }
        case 'setmessage':
            return { ...state, message: action.payload }
        case 'setfeedbackmessage':
            return { ...state, feedbackMessage: action.payload }
        default:
            return state
    }
}
const messageOption = [
    {
        id: 'no',
        value: 'Нет',
    },
    {
        id: 'push',
        value: 'Push',
    },
    {
        id: 'sms',
        value: 'СМС',
    },
]
const feedbackOption = [
    {
        id: 'no',
        value: 'Нет',
    },
    {
        id: 'yes',
        value: 'Да',
    },
]
export const EditOrderState = ({ isAdd = false }) => {
    const [state, dispatch] = useReducer(reducer, initState)
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const { id } = useParams()
    useEffect(() => {
        let isMount = true
        const getStateById = async (_id: string) => {
            try {
                const api = new OrderStatesService()
                const res = await api.getById(_id)
                if (isMount) dispatch({ type: 'setstate', payload: res })
            } catch (e) {
                console.log(e)
            }
        }
        if (!isAdd && id) getStateById(id)

        return () => {
            isMount = false
        }
    }, [])

    const [isOpen, setOpen] = useState(false)

    const { admin } = useTypedSelector((s) => s.userReducer)
    const onSubmit = async () => {
        try {
            setStatus({
                loading: true,
                error: '',
            })
            const api = new OrderStatesService()
            if (isAdd) {
                const res = await api.add(state)
            } else {
                const res = await api.edit({
                    ...state,
                    companyId:
                        localStorage.getItem('company') || state.companyId,
                })
            }

            setStatus({
                loading: false,
                error: '',
            })
        } catch (e: any) {
            console.error(e.message)
            setStatus({
                loading: false,
                error: e.message,
            })
        } finally {
            setOpen(true)
        }
    }

    const getDefaultMessageType = () => {
        if (state.shouldSendPush) return messageOption[1]
        if (state.shouldSendSMS) return messageOption[2]
        return messageOption[0]
    }
    if (!isAdd && state._id === 'new') return <Loader />
    return (
        <section className='edit-order-state'>
            <header className='edit-order-state__header'>
                <div className='edit-order-state__header--name'>
                    {isAdd ? 'Статусы/новый' : 'Статусы/Редактирование'}
                </div>
            </header>

            <div className='edit-order-state__content'>
                <div className='edit-order-state__content-inputs'>
                    <div className='inputs-block'>
                        <label>Название</label>
                        <Input
                            value={state.name}
                            className='edit-content-input'
                            onChange={(e) =>
                                dispatch({
                                    type: 'setname',
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className='content-color'>
                        <label>Цвет</label>
                        <input
                            className='input-color'
                            type={'color'}
                            value={state.color}
                            onChange={(e) =>
                                dispatch({
                                    type: 'setcolor',
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className='select-type'>
                        <label>Концепция</label>
                        <ConceptDropDown
                            defaultValue={state.conceptId}
                            onChange={(c) =>
                                dispatch({
                                    type: 'setconcept',
                                    payload: c._id,
                                })
                            }
                        />
                    </div>
                    <div className='tpcas-type'>
                        <label>TpCas</label>
                        <TpCasDropDown
                            defaultValue={!isAdd ? state.tpcasId : ''}
                            onChange={(c) =>
                                dispatch({
                                    type: 'settpcas',
                                    payload: c._id,
                                })
                            }
                        />
                    </div>
                </div>
                <div className='edit-order-state__content-select'>
                    <label>Сообщения</label>
                    <Select
                        options={messageOption}
                        defaultValue={getDefaultMessageType()}
                        onChange={(opt) =>
                            dispatch({
                                type: 'setmessagetype',
                                payload: opt.id,
                            })
                        }
                    />
                </div>
                {state.shouldSendPush || state.shouldSendSMS ? (
                    <SubForm
                        message={state.message}
                        onChange={(message) =>
                            dispatch({ type: 'setmessage', payload: message })
                        }
                    />
                ) : null}
                <div className='edit-order-state__content-select'>
                    <label>Оценка качества</label>
                    <Select
                        options={feedbackOption}
                        defaultValue={
                            state.needFeedback
                                ? feedbackOption[1]
                                : feedbackOption[0]
                        }
                        onChange={(opt) =>
                            dispatch({
                                type: 'setfeedbacktype',
                                payload: opt.id,
                            })
                        }
                    />
                </div>
                {state.needFeedback ? (
                    <SubForm
                        message={state.feedbackMessage}
                        onChange={(message) =>
                            dispatch({
                                type: 'setfeedbackmessage',
                                payload: message,
                            })
                        }
                    />
                ) : null}
                <Button
                    isLoading={status.loading}
                    className='edit-order-state__submit'
                    onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/order-states'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось'
                message='Статус успешно добавлен/обновлен'
                details={status.error}
            />
        </section>
    )
}

interface SubFormState {
    message: IOrderStateMessage | null
    onChange: (message: IOrderStateMessage) => void
}
const SubForm = ({ message, onChange }: SubFormState) => {
    return (
        <div className='edit-order-state__content-inputs'>
            <div className='inputs-block'>
                <label>Заголовок</label>
                <Input
                    className='edit-content-input'
                    value={message?.title || ''}
                    onChange={(e) => {
                        if (message)
                            onChange({ ...message, title: e.target.value })
                        else
                            onChange({
                                title: e.target.value,
                                subtitle: '',
                                body: '',
                                image: null,
                            })
                    }}
                />
                <label>Подзаголовок</label>
                <Input
                    className='edit-content-input'
                    value={message?.subtitle || ''}
                    onChange={(e) => {
                        if (message)
                            onChange({ ...message, subtitle: e.target.value })
                        else
                            onChange({
                                subtitle: e.target.value,
                                title: '',
                                body: '',
                                image: null,
                            })
                    }}
                />
            </div>
            <div className='inputs-block'>
                <label>Текст</label>
                <textarea
                    style={undefined}
                    value={message?.body || ''}
                    onChange={(e) => {
                        if (message)
                            onChange({ ...message, body: e.target.value })
                        else
                            onChange({
                                title: '',
                                subtitle: '',
                                body: e.target.value,
                                image: null,
                            })
                    }}
                    className='edit-content-textarea'
                />
            </div>
        </div>
    )
}
