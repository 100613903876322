import { createAsyncThunk } from '@reduxjs/toolkit'
import { BadgeService } from '../../../api/BadgeService'
import { FilesService } from '../../../api/FilesService'
import { IAddBadge, IBadge } from '../../../interfaces/Badges'
import { IStoriesAddImage } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'

interface AddBadgeArgs {
    badge: IAddBadge
    file: File
}
export const addBadge = createAsyncThunk('badge/add',
    async(args: AddBadgeArgs, thunkApi) => {
        const {badge, file} = args
        const api = new BadgeService()
        const fileApi = new FilesService()
        const prepImage = await fileApi.uploadBadgeFile(file, `${badge.name.trim()}-image`) as IStoriesAddImage
        const _badge: IAddBadge = {
            ...badge,
            image: {
                body: prepImage.body,
                hash: prepImage.hash
            }
        }
        const response = await api.add( _badge) as IBadge
        return response
    }
)


export const addBadgeClearState = createAsyncThunk('badge/addclearstate',
    (_, thunkApi) => {
        return true
    }
)