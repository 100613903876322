import React, { FC, useEffect, useState } from 'react'
import { ConceptService } from '../../api/ConceptService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IApi } from '../../interfaces/Api'
import { IConcept } from '../../interfaces/Concept'
import { ISelectOptions, Select } from '../UI/Select/Select'

interface ConceptDropDownProps {
    onChange: (concept: IConcept) => void
    defaultValue?: string //conceptId
    readOnly?: boolean
    additionalValue?: ISelectOptions
}
export const ConceptDropDown: FC<ConceptDropDownProps> = ({
    onChange,
    defaultValue,
    readOnly = false,
    additionalValue,
}) => {
    const [selectData, setSelectData] = useState<ISelectOptions[]>([])
    const [defValue, setDefValue] = useState<ISelectOptions>()
    const [loading, setLoading] = useState(true)

    const [concepts, setConcepts] = useState<IConcept[]>([])

    useEffect(() => {
        if (concepts.length > 0) {
            setSelectData(
                concepts.map((c) => ({
                    id: c._id,
                    value: c.name,
                }))
            )
            const _default = concepts.find((c) => c._id === defaultValue)
            console.log(_default)

            if (defaultValue && _default)
                setDefValue({
                    id: _default._id,
                    value: _default.name,
                })
            if (additionalValue) {
                onChange(_default || ({ _id: additionalValue.id } as IConcept))
            } else onChange(_default || concepts[0])

            // onChange(concepts[0])
        }
        return () => {}
    }, [concepts, defaultValue])

    useEffect(() => {
        let isMounted = true

        const getAllConcepts = async () => {
            const api = new ConceptService()
            try {
                const _concepts = (await api.getConcepts(
                    1,
                    1000
                )) as IApi<IConcept>

                if (isMounted) {
                    setLoading(false)
                    setConcepts(_concepts.data)
                }
            } catch (e) {
                console.log(e)
                if (isMounted) setLoading(false)
            }
        }
        getAllConcepts()
        return () => {
            isMounted = false
        }
    }, [])

    const handleDropdown = (value: ISelectOptions) => {
        if (additionalValue && additionalValue.id === value.id) {
            onChange({ _id: value.id.toString() } as IConcept)
            return
        }
        const _concept = concepts.find((c) => c._id === value.id)
        if (_concept) onChange(_concept)
    }
    if (selectData.length === 0 || loading) return null
    if (readOnly && defValue)
        return (
            <Select
                onChange={handleDropdown}
                options={
                    additionalValue
                        ? [additionalValue, ...selectData]
                        : selectData
                }
                readonly={readOnly}
                defaultValue={defValue}
                isUpdateDefaultValue
            />
        )
    return (
        <Select
            onChange={handleDropdown}
            options={
                additionalValue ? [additionalValue, ...selectData] : selectData
            }
            defaultValue={defValue || additionalValue || selectData[0]}
        />
    )
}
