import { Button } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StuffService } from '../../api/StuffService'
import { ConceptsSelect } from '../../components/ConceptDropDown/ConceptsSelect'
import { Loader } from '../../components/Loader/Loader'
import { CustomModal } from '../../components/Modal/CustomModal'
import { StaffScopesSelect } from '../../components/StaffScopesSelect/StaffScopesSelect'
import { TerminalsSelect } from '../../components/TerminalSelect/TerminalsSelect'
import { TpCassesSelect } from '../../components/TpCassesSelect/TpCassesSelect'
import { Input } from '../../components/UI/Input/Input'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { IStuff, IStuffScope } from '../../interfaces/Stuff'
import { ITerminal } from '../../interfaces/Terminals'
import { ITpCas } from '../../interfaces/TpCas'

import './EditStuff.scss'

const EditStuff = () => {
    useEffect(() => {
        document.title = 'Редактирование сотрудника'
    }, [])
    const { id } = useParams()
    const [stuff, setStuff] = useState<IStuff>()
    const [name, setName] = useState('')
    const [scopes, setScopes] = useState<IStuffScope[]>([])
    const [casses, setCasses] = useState<ITpCas[]>([])
    const [loading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [code, setCode] = useState('')
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })

    const [concepts, setConcepts] = useState<IConcept[]>([])
    const [terminals, setTerminals] = useState<ITerminal[]>([])

    useEffect(() => {
        let isM = true
        const getStuff = async (_id: string) => {
            try {
                setLoading(true)
                const api = new StuffService()
                const _stuff = await api.getById(_id)
                // const _code = await api.code(_id)
                if (isM) {
                    setStuff(_stuff)
                    setName(_stuff.name)
                    setScopes(
                        _stuff.scope.map((s) => ({
                            value: s,
                            name: s,
                        }))
                    )
                    setConcepts(_stuff.concepts)
                    setTerminals(_stuff.terminals)
                    setCasses(_stuff.tpcases)
                    setCode(_stuff.code || '')
                }
            } catch (e) {
                console.log(e)
                alert(e)
            } finally {
                setLoading(false)
            }
        }
        if (id) getStuff(id)
        return () => {
            isM = false
        }
    }, [])

    const { admin } = useTypedSelector((state) => state.userReducer)
    const onSubmit = () => {
        if (name.trim().length > 0) {
            const companyId = localStorage.getItem('company')
            const addStuff = async () => {
                try {
                    setStatus({
                        loading: true,
                        error: '',
                    })

                    const api = new StuffService()

                    const _res = await api.update({
                        ...stuff,
                        _id: id,
                        name,
                        telegram: null,
                        companyId: companyId || admin.companyId,
                        tpcases: casses.map((c) => c._id),
                        terminals: terminals.map(t => t._id),
                        concepts: concepts.map(c => c._id),
                        tgRegistered: false,
                        isDeleted: false,
                        scope: scopes.map((s) => s.value),
                    })
                    if (!_res.code) {
                        const setCode = await api.code(id || '')
                    }
                    setStatus({
                        loading: false,
                        error: '',
                    })
                } catch (e: any) {
                    console.error(e)
                    setStatus({
                        loading: false,
                        error: e.message,
                    })
                } finally {
                    setOpen(true)
                }
            }
            addStuff()
        } else {
            alert('Введите имя сотрудника')
        }
    }
    return (
        <section className='add-stuff-page'>
            <header className='add-stuff-page__header'>
                <div className='add-stuff-page__header--name'>
                    Сотрудники/Редактирование
                </div>
            </header>

            {loading ? (
                <Loader />
            ) : (
                <div className='add-stuff-page__content'>
                    <div className='add-stuff-page__content-inputs'>
                        <div className='inputs-block'>
                            <label>Имя</label>
                            <Input
                                className='add-stuff-input'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='inputs-block --casses'>
                            <label>Кассы</label>
                            <TpCassesSelect
                                selectedCasses={casses}
                                onChange={setCasses}
                            />
                        </div>
                        {scopes.length > 0 && (
                            <div className='inputs-block --scopes'>
                                <label>Уведомления</label>
                                <StaffScopesSelect
                                    selectedScopes={scopes}
                                    onChange={setScopes}
                                />
                            </div>
                        )}
                        <div className='inputs-block --concepts'>
                        <label>Концепции</label>
                        <ConceptsSelect
                            selectedConcepts={concepts}
                            onChange={(_concepts) => {
                                setTerminals(terminals.filter(t => {
                                    for(let i = 0; i < _concepts.length; i++) {
                                        if(t.conceptId === _concepts[i]._id) return true
                                    }
                                    return false
                                }))
                                setConcepts(_concepts)
                            }}
                        />
                    </div>
                    <div className='inputs-block --terminals'>
                        <label>Терминалы</label>
                        <TerminalsSelect
                            selectedTerminals={terminals}
                            onChange={setTerminals}
                            selectedConcepts={concepts}
                        />
                    </div>
                        <div className='inputs-block --code'>
                            <label className='code-label'>Код сотрудника</label>
                            <Input
                                className='code-input'
                                readonly
                                value={code || ''}
                            />
                            <button
                                className='code-btn'
                                onClick={(e) => {
                                    navigator.clipboard
                                        .writeText(stuff?.code || '')
                                        .then(() => {
                                            const target =
                                                e.target as HTMLButtonElement
                                            target.innerText = 'Скопировано'
                                            target.classList.add('--disabled')
                                            setTimeout(() => {
                                                target.innerText = 'Скопировать'
                                                target.classList.remove(
                                                    '--disabled'
                                                )
                                            }, 2000)
                                        })
                                }}>
                                Скопировать
                            </button>
                        </div>
                    </div>

                    <Button
                        isLoading={status.loading}
                        className='add-stuff-page__submit'
                        onClick={onSubmit}>
                        Сохранить
                    </Button>
                </div>
            )}
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/stuffs'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось обновить сотрудника'
                message='Сотрудник успешно обновлен'
                details={status.error}
            />
        </section>
    )
}

export default EditStuff
