import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConceptService } from '../../../api/ConceptService'
import { FilesService } from '../../../api/FilesService'
import { IChangeConcept } from '../../../interfaces/Concept'
import { IStoriesAddImage } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'

interface AddConceptArgs {
    concept: IChangeConcept,
    image?: File
}
export const updateConcept = createAsyncThunk('concepts/update',
    async(args: AddConceptArgs, thunkApi) => {
        const {concept, image} = args
        const api = new ConceptService()
        let preview = concept.image
        if(image) {
            const fileApi = new FilesService()
            const prepairedImage: IStoriesAddImage = await fileApi.uploadStoryFile(image, `${concept.name.trim()}-preview`) as IStoriesAddImage
            preview = {
                hash: prepairedImage.hash,
                body: prepairedImage.body
            }
            
        }

        const _concept: IChangeConcept = {
            ...concept,
            image: preview
        }

        const res = await api.update( _concept)
        return
    }
)

export const clearUpdateConceptState = createAsyncThunk('concepts/clearUpdateConceptState',
    (_, thunkApi) => {
        return
    }
)
