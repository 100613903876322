import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { IikoProductsService } from '../../../api/IikoProductsService'
import { IIikoProducts } from '../../../interfaces/IikoProducts'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAllIikoProducts = createAsyncThunk('iikoProduct/getAll',
    async(page: number, thunkApi) => {
        const api = new IikoProductsService()
        const products = await api.getIikoProducts( page) as IApi<IIikoProducts>
        return products
    }
)

interface GetById {
    code: string,
    isSmart: boolean
} 
export const getIikoProductsById = createAsyncThunk('iikoProduct/getById',
    async(args: GetById, thunkApi) => {
        const api = new IikoProductsService()
        const {code, isSmart} = args
        const products = await api.getProductById( code, isSmart) as IIikoProducts
        return products
    }
)
interface GetByCatAndConceptArgs {
    conceptId: string
    categoryId: string
    terminalId?: string
    page: number
    query?: {
        isDeleted: boolean | null
        isHidden: boolean | null
        isDisabled: boolean | null
    }
}
export const getIikoProductsByCategoryIdAndConceptId = createAsyncThunk('iikoProduct/getByCatAndConc',
    async(args: GetByCatAndConceptArgs, thunkApi) => {
        const {categoryId, conceptId, page, query, terminalId} = args
        const api = new IikoProductsService()
        const products = await api.getIikoProductsByCategoryIdAndConceptId( categoryId, conceptId,  page, query, terminalId) as IApi<IIikoProducts>
        return products
    }
)

interface GetBySubStr {
    conceptId: string
    str: string
}
export const getIikoProductsBySubstr = createAsyncThunk('iikoProduct/getBySubstr',
    async(args: GetBySubStr, thunkApi) => {
        const {conceptId, str} = args
        if(str.trim().length === 0) return []
        const api = new IikoProductsService()
        const products = await api.search( conceptId, str) as IApi<IIikoProducts>
        return products
    }
)

export const clearIikoProductsState = createAsyncThunk('iikoProduct/clearIikoProductsState',
    (_, thunkApi) => {
        return
    }
)