import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ITpCas } from '../../interfaces/TpCas'
import { TpCasService } from '../../api/TpCasService'
import { Loader } from '../../components/Loader/Loader'
import { Button } from '@chakra-ui/react'
import './TPCasTable.scss'
export const AddTpCas = () => {
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const nav = useNavigate()
    const onSubmit = async () => {
        if (name.trim().length === 0) return alert('Введите название')
        try {
            setLoading(true)
            const api = new TpCasService()
            const company = localStorage.getItem('company')
            const res = await api.addTpCas(name, company || '')
            nav('/settings-tpcas')
        } catch (error) {
            console.log(error)
            alert(`Не удалось сохранить\n${(error as Error).message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <section className='tpcas-page'>
            <header className='tpcas-page__header'>
                <div className='tpcas-page__header--name'>Настройки интеграций/Новая интеграция</div>
            </header>
            <div className='tpcas-page__edit'>
                <div className='tpcas-page__edit--input'>
                    <label>Название</label>
                    <input value={name} onChange={({ target }) => setName(target.value)} />
                </div>
            </div>
            <Button isLoading={loading} className='btn-submit' onClick={onSubmit}>
                Сохранить
            </Button>
        </section>
    )
}
