import { ITerminalsGroup } from '../../../interfaces/TerminalsGroup'
import { GroupOfTerminalsService } from '../../../api/GroupOfTerminalsService'
import { RootState } from '../../reducers/rootReducer'
import { IApi } from '../../../interfaces/Api'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface GetArgs {
    conceptId: string,
    page: number
    limit?: number
}
export const getTerminalsGroupByConcept = createAsyncThunk('terminalsGroup/getTGByConcept',
    async(args: GetArgs, thunkApi) => {
        const {conceptId, page, limit} = args
        const api = new GroupOfTerminalsService()
        const term = await api.get(conceptId, page, limit) as IApi<ITerminalsGroup>
        return term
    }
)


export const getTerminalsGroupById = createAsyncThunk('terminalsGroup/getTerminalsGroupById',
    async(id: string, thunkApi) => {
        const api = new GroupOfTerminalsService()
        const term = await api.getById(id) as ITerminalsGroup
        return term
    }
)
