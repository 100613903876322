import { createAsyncThunk } from '@reduxjs/toolkit'
import { FeaturedProductsService } from '../../../api/FeaturedProductsService'
import { IApi } from '../../../interfaces/Api'
import { IFeaturedProduct } from '../../../interfaces/FeaturedProduct'
import { RootState } from '../../reducers/rootReducer'

interface GetFeaturedProductsByConceptIdArgs {
    conceptId: string
    page: number
}
export const getFeaturedProductsByConceptId = createAsyncThunk('featuredProducts/getByConcept',
    async(args: GetFeaturedProductsByConceptIdArgs, thunkApi) => {
        const {conceptId, page} = args
        const api = new FeaturedProductsService()
        const categories = await api.get( conceptId, page) as IApi<IFeaturedProduct>
        return categories
    }
)