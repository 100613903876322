import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ITerminalsGroup } from '../../interfaces/TerminalsGroup'
import { addTerminalGroups, clearAddTerminalGroupsState } from '../actions/terminalsGroup/add'
import { deleteTerminalGroup, disableTerminalGroup, enableTerminalGroup } from '../actions/terminalsGroup/disable'
import { getTerminalsGroupByConcept, getTerminalsGroupById } from '../actions/terminalsGroup/get'
import { clearUpdateTerminalGroupsState, updateTerminalGroups } from '../actions/terminalsGroup/update'
import { TerminalsGroupState } from '../types/terminalsGroup'

const initialState: TerminalsGroupState = {
    terminalsGroup: [],
    getTerminalsGroup: {
        loading: false,
        error: '',
    },
    count: 0,
    limit: 0,
    page: 0,
    pages: 0,
    addTerminalGroup: {
        isSuccess: false,
        loading: false,
        error: ''
    },
    editTerminalGroup: {
        isSuccess: false,
        loading: false,
        error: ''
    },
    terminalGroup: {} as ITerminalsGroup,
    getTerminalGroup: {
        loading: false,
        error: '',
    }
}

export const terminalsGroupSlice = createSlice({
    name: 'terminalGroups',
    initialState,
    reducers: {},
    extraReducers: {
        [getTerminalsGroupByConcept.pending.type]: (state) => {
            state.getTerminalsGroup.error = ''
            state.getTerminalsGroup.loading = true
        },
        [getTerminalsGroupByConcept.fulfilled.type]: (state, action: PayloadAction<IApi<ITerminalsGroup>>) => {
            state.getTerminalsGroup.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.terminalsGroup = action.payload.data
        },
        [getTerminalsGroupByConcept.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminalsGroup.error = action.error.message
            state.getTerminalsGroup.loading = false
        },
        [addTerminalGroups.pending.type]: (state) => {
            state.addTerminalGroup.error = ''
            state.addTerminalGroup.loading = true
            state.addTerminalGroup.isSuccess = false
        },
        [addTerminalGroups.fulfilled.type]: (state, action: PayloadAction<ITerminalsGroup>) => {
            state.addTerminalGroup.loading = false
            state.addTerminalGroup.isSuccess = true
        },
        [addTerminalGroups.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.addTerminalGroup.error = action.error.message
            state.addTerminalGroup.loading = false
            state.addTerminalGroup.isSuccess = false
        },
        [clearAddTerminalGroupsState.fulfilled.type]: (state, action: PayloadAction<ITerminalsGroup>) => {
            state.addTerminalGroup.loading = false
            state.addTerminalGroup.isSuccess = false
            state.addTerminalGroup.error = ''
        },
        [disableTerminalGroup.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminalsGroup = state.terminalsGroup.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = true
                return tg
            })
        },
        [enableTerminalGroup.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminalsGroup = state.terminalsGroup.map(tg => {
                if(tg._id === action.payload)
                    tg.isDisabled = false
                return tg
            })
        },
        [deleteTerminalGroup.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.terminalsGroup = state.terminalsGroup.map(tg => {
                if(tg._id === action.payload) {
                    // tg.isDisabled = true
                    tg.isDeleted = !tg.isDeleted
                }
                    
                return tg
            })
        },
        [updateTerminalGroups.pending.type]: (state) => {
            state.editTerminalGroup.error = ''
            state.editTerminalGroup.loading = true
            state.editTerminalGroup.isSuccess = false
        },
        [updateTerminalGroups.fulfilled.type]: (state) => {
            state.editTerminalGroup.loading = false
            state.editTerminalGroup.isSuccess = true
        },
        [updateTerminalGroups.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.editTerminalGroup.error = action.error.message
            state.editTerminalGroup.loading = false
            state.editTerminalGroup.isSuccess = false
        },
        [clearUpdateTerminalGroupsState.fulfilled.type]: (state) => {
            state.editTerminalGroup.loading = false
            state.editTerminalGroup.isSuccess = false
            state.editTerminalGroup.error = ''
        },
        [getTerminalsGroupById.pending.type]: (state) => {
            state.getTerminalGroup.error = ''
            state.getTerminalGroup.loading = true
        },
        [getTerminalsGroupById.fulfilled.type]: (state, action: PayloadAction<ITerminalsGroup>) => {
            state.getTerminalGroup.loading = false
            state.terminalGroup = action.payload
        },
        [getTerminalsGroupById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTerminalGroup.error = action.error.message
            state.getTerminalGroup.loading = false
        },
    }
})

export const terminalsGroupReducer = terminalsGroupSlice.reducer