import { RootState } from '../../reducers/rootReducer'
import { SegmentService } from '../../../api/SegmentService'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const disableSegment = createAsyncThunk('segment/disableSegment', 
    async(id: string, thunkApi) => {
        const api = new SegmentService()
        const response = await api.delete(id, {isDisabled: true})
        return id
    }
)
export const enableSegment = createAsyncThunk('segment/enableSegment', 
    async(id: string, thunkApi) => {
        const api = new SegmentService()
        const response = await api.delete(id,{isDisabled: false})
        return id
    }
)

interface IDelete {
    id: string
    isDeleted: boolean
}
export const deleteSegment = createAsyncThunk('segment/deleteSegment', 
    async(args: IDelete, thunkApi) => {
        const api = new SegmentService()
        const {id, isDeleted} = args
        const response = await api.delete(id,{isDeleted})
        return id
    }
)
