import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IChangeOrderState, IOrderState } from '../interfaces/OrderState'
import { Api, GetQuery } from './Api'

import { axiosInstance } from './axios'

export class OrderStatesService extends Api {
    constructor() {
        super()
    }

    get = async(conceptId: string,  query?: GetQuery) => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IOrderState[]>(`${this.URL}/api/v1/order_states/`, {
                params: {
                    conceptId,
                    companyId,
                    ...query
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }


    getById = async( id: string) => {
        try {
            const response = await axiosInstance.get<IOrderState>(`${this.URL}/api/v1/order_states/${id}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    add = async( state: IChangeOrderState) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/order_states/`, state)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
  
    edit = async(state: IChangeOrderState) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v1/order_states/`, state)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status)
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}