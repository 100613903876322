import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IModifiersOption } from '../../interfaces/IikoProducts'
import { disableModifier, disableModifierOption } from '../actions/iikoModifiers/delete'
import { getIikoModifiersByTerminalId, getIikoModifiersoptionsByTerminalId } from '../actions/iikoModifiers/get'

import { IikoModifiersOptionState } from '../types/modifiersOption'

const initialState: IikoModifiersOptionState = {
    options: [],
    getOptions: {
        loading: false,
        error: ''
    },
    limit: 0,
    pages: 0,
    page: 0,
}

export const iikoModifiersOptionSlice = createSlice({
    name: 'iikoModifiersOption',
    initialState,
    reducers: {},
    extraReducers: {
        [getIikoModifiersoptionsByTerminalId.pending.type]: (state) => {
            state.getOptions = {
                error: '',
                loading: true
            }
        },
        [getIikoModifiersoptionsByTerminalId.fulfilled.type]: (state, actions: PayloadAction<IApi<IModifiersOption>>) => {
            state.getOptions = {
                error: '',
                loading: false
            }
            state.options = actions.payload.data
            state.limit = actions.payload.limit
            state.page = actions.payload.page
            state.pages = actions.payload.pages
        },
        [getIikoModifiersoptionsByTerminalId.rejected.type]: (state, actions: PayloadAction<any, any, any, Error>) => {
            state.getOptions = {
                error: actions.error.message,
                loading: false
            }
        },
        [disableModifierOption.pending.type]: (state) => {
            state.getOptions = {
                error: '',
                loading: true
            }
        },
        [disableModifierOption.fulfilled.type]: (state, actions: PayloadAction<string>) => {
            state.getOptions = {
                error: '',
                loading: false
            }
            state.options = state.options.map(m => {
                if(m._id === actions.payload) m.isDisabled = !m.isDisabled
                return m
            }) 
        },
        [disableModifierOption.rejected.type]: (state, actions: PayloadAction<any, any, any, Error>) => {
            state.getOptions = {
                error: actions.error.message,
                loading: false
            }
        }
    }
})


export const iikoModifiersOptionReducer = iikoModifiersOptionSlice.reducer