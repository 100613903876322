import { FC, useState } from 'react'
import { IIikoProducts } from '../../../interfaces/IikoProducts'
import { IOrderProduct, IOrderProductModifier } from '../../../interfaces/Order'
import '../../../pages/AddOrder/AddOrder.scss'
import { ISelectOptions, Select } from '../../UI/Select/Select'
import { v4 } from 'uuid'
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
interface AddProductWithModifierModalProps {
    isOpen: boolean
    onClose: () => void
    product: IIikoProducts
    addProduct: (product: IOrderProduct) => void
}
export const AddProductWithModifierModal: FC<
    AddProductWithModifierModalProps
> = ({ isOpen, onClose, product, addProduct }) => {
    const [mods, setMods] = useState<IOrderProductModifier[]>([])

    const setModHandler = (
        groupId: string,
        price: number,
        value: ISelectOptions
    ) => {
        const _mod: IOrderProductModifier = {
            group: groupId,
            name: value.value,
            price: price,
        }
        const newMods = mods.filter((mod) => mod.group !== _mod.group)
        newMods.push(_mod)
        setMods(newMods)
    }
    const addProductHandler = () => {
        onCloseHandler()
        addProduct({
            externalId: product.externalId,
            code: product.code,
            name: product.name,
            amount: 1,
            price: product.price,
            productModifiers: mods,
            weight: product.weight,
            frontEndId: v4(),
        })
    }
    const onCloseHandler = () => {
        setMods([])
        onClose()
    }

    return (
        <Modal size={'2xl'} isOpen={isOpen} onClose={onCloseHandler}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Добавление продукта</ModalHeader>
                <ModalBody>
                    <div className='modal-order-add-product-modifiers'>
                        <div className='modal-order-add-product-modifiers__img'>
                            <img src={product.image?.body} alt={product.name} />
                        </div>
                        <div className='modal-order-add-product-modifiers__content'>
                            <h1>{product.name}</h1>
                            <p>Модификаторы</p>
                            {product.modifiers.map((mod) => (
                                <div
                                    className='modal-order-add-product-modifiers__content-list'
                                    key={mod._id}>
                                    <div>{mod.name}</div>
                                    <Select
                                        options={mod.options.map((op) => ({
                                            id: `${op._id}___${op.price}`,
                                            value: op.name,
                                        }))}
                                        onChange={(value) =>
                                            setModHandler(
                                                mod._id,
                                                +value.id
                                                    .toString()
                                                    .split('___')[1],
                                                value
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='modal-order-add-product-modifiers__btn-add'
                        disabled={mods.length !== product.modifiers.length}
                        onClick={addProductHandler}>
                        Добавить
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
