import React from 'react'
import './Input.scss'

interface InputProps {
    value?: string | number
    placeholder?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
    type?: string
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onBlurCapture?: (e: React.FocusEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    maxLength?: number
    max?: number
    min?: number
    readonly?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            placeholder,
            onChange,
            className = '',
            type = 'text',
            onKeyDown,
            maxLength,
            max,
            min,
            readonly = false,
            onKeyUp,
            onBlur,
            onBlurCapture,
            onFocus,
        },
        ref
    ) => {
        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(e)
        }
        return (
            <input
                className={`ui-input ${className} ${
                    readonly ? '--readonly' : ''
                }`}
                type={type}
                placeholder={placeholder}
                onChange={onChangeHandler}
                value={value}
                ref={ref}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                max={max}
                min={min}
                step='any'
                readOnly={readonly}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
                onBlurCapture={onBlurCapture}
                onFocus={onFocus}
            />
        )
    }
)
