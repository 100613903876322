import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ICustomer } from '../../interfaces/Customer'
import { getAllCustomers, getCustomerById, getCustomersByArg } from '../actions/customer/get'
import { CustomerState } from '../types/customer'

const initialState: CustomerState = {
    limit: 0,
    page: 0,
    pages: 0,
    customers: [],
    getCustomers: {
        error: '',
        loading: false
    },
    customer: {} as ICustomer,
    getCustomer: {
        error: '',
        loading: false
    }
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllCustomers.pending.type]: (state) => {
            state.getCustomers.error = ''
            state.getCustomers.loading = true
        },
        [getAllCustomers.fulfilled.type]: (state, action: PayloadAction<IApi<ICustomer>>) => {
            state.getCustomers.error = ''
            state.getCustomers.loading = false
            state.customers = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getAllCustomers.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCustomers.error = action.error.message
            state.getCustomers.loading = false
        },
        [getCustomersByArg.pending.type]: (state) => {
            state.getCustomers.error = ''
            state.getCustomers.loading = true
        },
        [getCustomersByArg.fulfilled.type]: (state, action: PayloadAction<IApi<ICustomer>>) => {
            state.getCustomers.error = ''
            state.getCustomers.loading = false
            state.customers = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getCustomersByArg.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCustomers.error = action.error.message
            state.getCustomers.loading = false
        },
        [getCustomerById.pending.type]: (state) => {
            state.getCustomer.error = ''
            state.getCustomer.loading = true
        },
        [getCustomerById.fulfilled.type]: (state, action: PayloadAction<ICustomer>) => {
            state.getCustomer.error = ''
            state.getCustomer.loading = false
            state.customer = action.payload
        },
        [getCustomerById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCustomer.error = action.error.message
            state.getCustomer.loading = false
        },
    }
})


export const customerReducer = customerSlice.reducer