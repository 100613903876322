import React, { useEffect, useState } from 'react'
import './Settings.scss'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Input } from '../../components/UI/Input/Input'
import { ChangePassword } from '../../components/ChangePassword/ChangePassword'
export const Settings = () => {
    const { getAdmin } = useActions()
    const { admin } = useTypedSelector((state) => state.userReducer)
    const [isChangePassword, setChangePassword] = useState(false)
    useEffect(() => {
        if (!admin._id) getAdmin()
    }, [])
  return (
    <section className='settings-page'>
        <header className='settings-page__header'>
            <div className='settings-page__header--name'>Настройки</div>
        </header>
        {admin._id && 
        (<div className='settings-page__content'>
            <div className='settings-page__username'>
                Username: 
                <Input value={admin.username} readonly/>
            </div>
            <div className='settings-page__password'>
                {isChangePassword ? <ChangePassword onClose={() => setChangePassword(false)}/> : <button className='settings-page__change-pw' onClick={() => setChangePassword(true)}>Сменить пароль</button>}
            </div>
        </div>)}
       
    </section>
  )
}
