import React, { useEffect, useState } from 'react'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

export const RequireAuth = () => {
    const location = useLocation()
    const { isAuth } = useTypedSelector((state) => state.authReducer)

    return isAuth ? (
        <Outlet />
    ) : (
        <Navigate to={'auth'} replace state={{ pathname: location.pathname }} />
    )
}
