import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContentService } from '../../api/ContentService'
import { useActions } from '../../hooks/useActions'
import { IContent } from '../../interfaces/Content'
import { CustomModal } from '../Modal/CustomModal'
import { Icon } from '../UI/Icon/Icon'
import { TableRow } from '../UI/Table/TableRow/TableRow'
import Mobile from '../../static/icons/smartphone.png'
import './ContentTable.scss'
interface ContentTableRowProps {
    content: IContent
    onDelete: (id: string) => void
}
export const ContentTableRow: FC<ContentTableRowProps> = ({ content, onDelete }) => {
    // const {} = useActions()
    const navigator = useNavigate()
    const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation()
        navigator(`./edit/${content._id}`)
    }
    const disableContentHandler = () => {
        // content.isDisabled
        //     ? enableConcept(concept._id)
        //     : disableConcept(concept._id)
    }
    const [isDelete, setIsDelete] = useState(true)
    const [isShow, setShow] = useState(false)

    const deleteContent = async () => {
        try {
            const api = new ContentService()
            const res = await api.delete(content._id, {
                isDeleted: !content.isDeleted,
            })
            onDelete(content._id)
        } catch (e) {
            console.log(e)
        }
    }
    const preparedData = [
        <div className='content-table-row__name'>
            {`${content.name} `}
            {content.type === 'mobile' && <img src={Mobile} />}
        </div>,
        <div className='content-table-row__img'>
            <img src={content.image} alt={content.name} />
        </div>,
        <div className='content-table-row__icons' onClick={(e) => e.stopPropagation()}>
            <div className='content-table-row__icons--icon' onClick={disableContentHandler}>
                <Icon name='Shutdown' />
            </div>
            <div className='content-table-row__icons--icon' onClick={handleEdit}>
                <Icon name='Edit' />
            </div>
            <div
                className='content-table-row__icons--icon'
                onClick={() => {
                    setIsDelete(true)
                    setShow(true)
                }}>
                <Icon name='Delete' />
            </div>
        </div>,
    ]

    return (
        <>
            <TableRow
                // className={`${
                //     content.isDisabled ? 'content-table-row--disabled' : ''
                // }`}
                data={preparedData}
            />
            <CustomModal
                open={isShow}
                message={`Вы уверены, что хотите ${isDelete ? 'удалить/восстановить' : 'скрыть/восстановить'} ${content.name}?`}
                isError={false}
                toggleWindow={setShow}
                errorMsg={''}
                clearState={() => {}}
                details={''}
                btnYes={
                    <button
                        className='modal__footer-btn btn-yes'
                        onClick={() => {
                            isDelete ? deleteContent() : disableContentHandler()
                            setShow(false)
                        }}>
                        Да
                    </button>
                }
                btnNo={
                    <button
                        className='modal__footer-btn btn-no'
                        onClick={() => {
                            setShow(false)
                        }}>
                        Нет
                    </button>
                }
            />
        </>
    )
}
