import axios, { AxiosError } from 'axios'
import { IDashboardOrdersByType, IDashboardTopProducts } from '../interfaces/Dashboard'
import { Api } from './Api'
import { axiosInstance } from './axios'

export class DashboardService extends Api {
    constructor() {
        super()
    }

    getTopProducts = async( limit?: number) => {
        try {
            let _limit = 5
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IDashboardTopProducts[]>(`${this.URL}/api/v1/dashboards/top_products?limit=${_limit}`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    
    getOrdersByType = async() => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IDashboardOrdersByType[]>(`${this.URL}/api/v1/dashboards/orders_by_type`, {
                params: {
                    companyId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

}