import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserService } from '../../../api/UserService'
import { IApi } from '../../../interfaces/Api'
import { IUser } from '../../../interfaces/User'
import { RootState } from '../../reducers/rootReducer'

interface GetAllUsersArgs {
    page: number
    limit?: number
}

export const getAllUsers = createAsyncThunk('user/getAll',
    async(args: GetAllUsersArgs, thunkApi) => {
        const {page, limit} = args
        const api = new UserService()
        const users = await api.get(page, limit) as IApi<IUser>
        return users
    }
)


export const getUserById = createAsyncThunk('user/getById',
    async(id: string, thunkApi) => {
        const api = new UserService()
        const user = await api.getById(id) as IUser
        return user
    }
)

export const getAdmin = createAsyncThunk('user/getAdmin',
    async(_, thunkApi) => {
        const api = new UserService()
        const user = await api.getByToken() as IUser
        return user
    }
)