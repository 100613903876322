import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetQuery } from '../../../api/Api'
import { TriggerService } from '../../../api/TriggerService'
import { IApi } from '../../../interfaces/Api'
import { ITrigger } from '../../../interfaces/Trigger'
import { RootState } from '../../reducers/rootReducer'

interface GetAllTriggersArgs {
    page: number
    limit?: number
    query: GetQuery
}

export const getAllTriggers = createAsyncThunk('trigger/getAll',
    async(args: GetAllTriggersArgs, thunkApi) => {
        const {page, limit, query} = args
        const api = new TriggerService()
        const pushes = await api.get(page, limit, query) as IApi<ITrigger>
        return pushes
    }
)
