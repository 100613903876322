import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ITrigger } from '../interfaces/Trigger'

import { Api, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class TriggerService extends Api {
    constructor() {
        super()
    }

    get = async(page: number, limit?: number, query?: GetQuery) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<ITrigger>>(`${this.URL_MARK}/api/v1/triggers/?${this.getQuery(query)}&limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getById = async(triggerId: string) => {
        try {
            const response = await axiosInstance.get<IApi<ITrigger>>(`${this.URL_MARK}/api/v1/triggers/${triggerId}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    // add = async(token: string, promocode: IAddPromo) => {
    //     try {
    //         const response = await axios.post(`${this.URL}/promocodes/`, promocode,
    //         {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         return response.data
    //     } catch (e) {
    //         const error = e as AxiosError
    //         if(error.response) {
    //             if(error.response.status === 404) throw new Error('Сервер временно не работает')
    //             if(error.response.status === 422) throw new Error('Validation Error')
    //             if(error.response.status === 401) throw new Error(error.response.data.detail)
    //         } else throw new Error('Сервер временно не работает')
    //     }
    // }

}