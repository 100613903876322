import React, { useRef, useState } from 'react'
import './ChangePassword.scss'
import { Input } from '../UI/Input/Input'
import { UserService } from '../../api/UserService'
const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-!"#$%&'()*+,./:;<=>?@[\\\]_`{|}~])[0-9a-zA-Z-!"#$%&'()*+,./:;<=>?@[\\\]_`{|}~]{6,}$/

const isPassword = (str: string) => PASSWORD_PATTERN.test(str)
export const ChangePassword = ({onClose = () => {}}) => {
    const [oldPw, setOldPw] = useState('')
    const [newPw, setNewPw] = useState('')
    const pRef = useRef<HTMLParagraphElement>(null)
    const submitRef = useRef<HTMLButtonElement>(null)
    const submitPassword = async() => {
        pRef.current!.innerText = ''
        if(oldPw.trim().length === 0 || newPw.trim().length === 0) {
            pRef.current!.innerText = 'Заполните все поля'
            return
        }
        submitRef.current!.disabled = true
        const trimmedPw = newPw.trim()
        if(trimmedPw.length < 6) {
            pRef.current!.innerText = 'Пароль должен быть не менее 6 символов'
            return
        }
        if(!isPassword(trimmedPw)) {
            pRef.current!.innerText = 'Пароль должен содержать минимум: 1 латинскую букву в верхем регистре, 1 латинскую букву в нижнем регистре, 1 цифру и 1 специальный символ'
            return
        }
        
        try {
            const api = new UserService()
            const res = await api.changePassword(oldPw, newPw)
            console.log(res)
            pRef.current!.innerText = 'Пароль успешно обновлен'
        } catch (e: any) {
            console.log(e)
            pRef.current!.innerText = `Приозошла ошибка при обновлении пароля: ${e.message}`
        }
        finally {
            submitRef.current!.disabled = false
            setTimeout(() => {
                pRef.current!.innerText = ''
                onClose()
            }, 5000)
        }
    }
    return (
        <div className='change-password'>
            <div className='change-password__input'>
                Старый пароль:
                <Input type='password' value={oldPw} onChange={e => setOldPw(e.target.value)}/>
            </div>
            <div className='change-password__input'>
                Новый пароль:
                <Input type='password' value={newPw} onChange={e => setNewPw(e.target.value)}/>
            </div>
            <p ref={pRef} className='change-password__message'></p>
            <div>
                <button ref={submitRef} className='change-password__submit' onClick={submitPassword}>Сохранить</button>
                <button className='change-password__close' onClick={onClose}>Назад</button>
            </div>
        </div>
    )
}
