import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ITrigger } from '../../interfaces/Trigger'
import { getAllTriggers } from '../actions/triggers/get'
import { TriggerState } from '../types/trigger'

const initialState: TriggerState = {
    limit: 0,
    page: 0,
    pages: 0,
    triggers: [],
    trigger: {
        name: '',
        baseName: '',
        cronRule: {
            year: null,
            month: null,
            day: null,
            week: null,
            day_of_week: null,
            hour: null,
            minute: null,
            second: null,
            start_date: null,
            end_date: null,
            timezone: null,
            jitter: null
        },
        condition_name: '',
        condition_payload: '',
        action_name: '',
        action_payload: {
            notificationTemplate: {
                body: '',
                isPersonal: true,
                subtitle: '',
                title: '',
                image: {
                    body: '',
                    hash: ''
                }
            }
        },
        moderators: [],
        auditors: [],
        isDeleted: false,
        isDisabled: false,
        isHidden: false,
        createdAt: '',
        updatedAt: '',
        lastRun: '',
        isRunning: false,
        conceptId: '',
    },
    getTriggers: {
        loading: false,
        error: ''
    },
    addTrigger: {
        loading: false,
        error: '',
        isSuccess: false
    }
}

export const triggerSlice = createSlice({
    name: 'trigger',
    initialState,
    reducers: {
        updateState: (state, action: PayloadAction<ITrigger>) => {
            state.trigger = action.payload
        },
        clear: (state) => {
            state.triggers = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getAllTriggers.pending.type]: (state) => {
            state.getTriggers.loading = true
            state.getTriggers.error = ''
            // state.triggers = []
        },
        [getAllTriggers.fulfilled.type]: (state, action: PayloadAction<IApi<ITrigger>>) => {
            state.getTriggers.loading = false
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
            state.triggers = [...state.triggers, ...action.payload.data]
        },
        [getAllTriggers.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getTriggers.loading = false
            state.getTriggers.error = action.error.message
        },
        // [.pending.type]: (state) => {
        //     state.addTrigger.loading = true
        //     state.addTrigger.error = ''
        //     state.addTrigger.isSuccess = false
        // },
        // [.fulfilled.type]: (state) => {
        //     state.addTrigger.loading = false
        //     state.addTrigger.error = ''
        //     state.addTrigger.isSuccess = true
        // },
        // [.rejected.type]: (state, action: PayloadAction<string>) => {
        //     state.addTrigger.loading = false
        //     state.addTrigger.error = action.payload
        //     state.addTrigger.isSuccess = false
        // },
        // [.fulfilled.type]: (state) => {
        //     state.addTrigger.loading = false
        //     state.addTrigger.error = ''
        //     state.addTrigger.isSuccess = false
        // },
    }
})

export const triggerReducer = triggerSlice.reducer
export const triggerActions = triggerSlice.actions