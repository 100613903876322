import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { IIikoCategories } from '../../interfaces/IikoCategories'
import { deleteCategory, disabledCategory, enableCategory } from '../actions/iikoCategories/disabled'
import { clearCategorieState, getCategorieById, getCategoriesByConceptId } from '../actions/iikoCategories/get'
import { clearUpdateCategoryState, sortCategories, updateCategory } from '../actions/iikoCategories/update'
import { IikoCategoriesState } from '../types/iikoCategories'


const initialState: IikoCategoriesState = {
    limit: 0,
    pages: 0,
    page: 0,
    categories: [],
    getCategories: {
        loading: false,
        error: ''
    },
    categorie: {} as IIikoCategories,
    getCategorie: {
        loading: false,
        error: ''
    },
    updateCategory: {
        loading: false,
        error: '',
        isSuccess: false
    }
}

export const iikoCategoriesSlice = createSlice({
    name: 'iikoCategories',
    initialState,
    reducers: {
        clear: (state) => {
            state.categories = []
            state.pages = 0
        }
    },
    extraReducers: {
        [getCategoriesByConceptId.pending.type]: (state) => {
            state.getCategories.error = ''
            state.getCategories.loading = true
            // state.categories = []
        },
        [getCategoriesByConceptId.fulfilled.type]: (state, action: PayloadAction<IApi<IIikoCategories>>) => {
            state.getCategories.error = ''
            state.getCategories.loading = false
            state.categories = action.payload.data
                .sort((a, b) => {
                    if (a.order < b.order) return -1
                    if (a.order > b.order) return 1
                    return 0
                })
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.pages = action.payload.pages
        },
        [getCategoriesByConceptId.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCategories.error = action.error.message
            state.getCategories.loading = false
        },
        [getCategorieById.pending.type]: (state) => {
            state.getCategorie.error = ''
            state.getCategorie.loading = true
        },
        [getCategorieById.fulfilled.type]: (state, action: PayloadAction<IIikoCategories>) => {
            state.getCategorie.error = ''
            state.getCategorie.loading = false
            state.categorie = action.payload
        },
        [getCategorieById.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCategorie.error = action.error.message
            state.getCategorie.loading = false
        },
        [disabledCategory.pending.type]: (state) => {
            state.getCategories.error = ''
            state.getCategories.loading = true
        },
        [disabledCategory.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.getCategories.error = ''
            state.getCategories.loading = false
            state.categories = state.categories.map(c => {
                if (c._id === action.payload)
                    c.isDisabled = true
                return c
            })
        },
        [disabledCategory.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCategories.error = action.error.message
            state.getCategories.loading = false
        },
        [deleteCategory.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.getCategories.error = ''
            state.getCategories.loading = false
            state.categories = state.categories.map(c => {
                if (c._id === action.payload)
                    c.isDeleted = !c.isDeleted
                return c
            })
        },
        [enableCategory.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.getCategories.error = ''
            state.getCategories.loading = false
            state.categories = state.categories.map(c => {
                if (c._id === action.payload)
                    c.isDisabled = false
                return c
            })
        },
        [clearCategorieState.fulfilled.type]: (state) => {
            state.categorie = {} as IIikoCategories
        },
        [updateCategory.pending.type]: (state) => {
            state.updateCategory.error = ''
            state.updateCategory.isSuccess = false
            state.updateCategory.loading = true
        },
        [updateCategory.fulfilled.type]: (state) => {
            state.updateCategory.error = ''
            state.updateCategory.isSuccess = true
            state.updateCategory.loading = false
        },
        [updateCategory.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.updateCategory.error = action.error.message
            state.updateCategory.isSuccess = false
            state.updateCategory.loading = false
        },
      
        [clearUpdateCategoryState.fulfilled.type]: (state) => {
            state.updateCategory.error = ''
            state.updateCategory.isSuccess = false
            state.updateCategory.loading = false
        },
        // [sortCategories.fulfilled.type]: (state, action: PayloadAction<IIikoCategories[]>) => {
        //     console.log(action.payload)

        //     state.categories = action.payload
        // },
    }
})


export const iikoCategoriesReducer = iikoCategoriesSlice.reducer

export const IikoCategoriesAction = iikoCategoriesSlice.actions