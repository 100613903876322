import { Button } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { StuffService } from '../../api/StuffService'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'
import { ConceptsSelect } from '../../components/ConceptDropDown/ConceptsSelect'
import { CustomModal } from '../../components/Modal/CustomModal'
import { StaffScopesSelect } from '../../components/StaffScopesSelect/StaffScopesSelect'
import { TerminalsSelect } from '../../components/TerminalSelect/TerminalsSelect'
import { TpCassesSelect } from '../../components/TpCassesSelect/TpCassesSelect'
import { Input } from '../../components/UI/Input/Input'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { IStuffScope } from '../../interfaces/Stuff'
import { ITerminal } from '../../interfaces/Terminals'
import { ITpCas } from '../../interfaces/TpCas'

import './AddStuff.scss'

const AddStuff = () => {
    useEffect(() => {
        document.title = 'Добавление сотрудника'
    }, [])

    const [name, setName] = useState('')
    const [scopes, setScopes] = useState<IStuffScope[]>([])
    const [casses, setCasses] = useState<ITpCas[]>([])
    const [concepts, setConcepts] = useState<IConcept[]>([])
    const [terminals, setTerminals] = useState<ITerminal[]>([])
    const [isOpen, setOpen] = useState(false)

    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })

    const { admin } = useTypedSelector((state) => state.userReducer)
    const onSubmit = () => {
        if (concepts.length === 0) return alert('Выберите хотя бы 1 концепцию')
        // if(terminals.length === 0) return alert('Выберите хотя бы 1 терминал')
        if (name.trim().length > 0) {
            const addStuff = async () => {
                try {
                    setStatus({
                        loading: true,
                        error: '',
                    })

                    const api = new StuffService()
                    const companyId = localStorage.getItem('company')
                    const _res = await api.add({
                        name,
                        telegram: null,
                        companyId: companyId || admin.companyId,
                        tpcases: casses.map((c) => c._id),
                        concepts: concepts.map((c) => c._id),
                        terminals: terminals.map((t) => t._id),
                        tgRegistered: false,
                        isDeleted: false,
                        scope: scopes.map((s) => s.value),
                    })
                    console.log(_res._id)
                    const setCode = await api.code(_res._id)
                    setStatus({
                        loading: false,
                        error: '',
                    })
                } catch (e: any) {
                    console.error(e)
                    setStatus({
                        loading: false,
                        error: e.message,
                    })
                } finally {
                    setOpen(true)
                }
            }
            addStuff()
        } else {
            alert('Введите имя сотрудника')
        }
    }
    return (
        <section className='add-stuff-page'>
            <header className='add-stuff-page__header'>
                <div className='add-stuff-page__header--name'>Сотрудники/Добавить</div>
            </header>

            <div className='add-stuff-page__content'>
                <div className='add-stuff-page__content-inputs'>
                    <div className='inputs-block'>
                        <label>Имя</label>
                        <Input className='add-stuff-input' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='inputs-block'>
                        <label>Кассы</label>
                        <TpCassesSelect selectedCasses={casses} onChange={setCasses} />
                    </div>
                    <div className='inputs-block --scopes'>
                        <label>Уведомления</label>
                        <StaffScopesSelect selectedScopes={scopes} onChange={setScopes} />
                    </div>
                    <div className='inputs-block --concepts'>
                        <label>Концепции</label>
                        <ConceptsSelect
                            selectedConcepts={concepts}
                            onChange={(_concepts) => {
                                setTerminals(
                                    terminals.filter((t) => {
                                        for (let i = 0; i < _concepts.length; i++) {
                                            if (t.conceptId === _concepts[i]._id) return true
                                        }
                                        return false
                                    })
                                )
                                setConcepts(_concepts)
                            }}
                        />
                    </div>
                    <div className='inputs-block --terminals'>
                        <label>Терминалы</label>
                        <TerminalsSelect selectedTerminals={terminals} onChange={setTerminals} selectedConcepts={concepts} />
                    </div>
                    {/* <div className='inputs-block --concept'>
                        <label>Концепция</label>
                        <ConceptDropDown onChange={(val) => console.log(val)} />
                    </div> */}
                </div>

                <Button isLoading={status.loading} className='add-stuff-page__submit' onClick={onSubmit}>
                    Сохранить
                </Button>
            </div>
            <CustomModal
                isError={status.error.trim().length > 0}
                navigate='/stuffs'
                clearState={() => {}}
                open={isOpen}
                toggleWindow={setOpen}
                errorMsg='Не удалось добавить сотрудника'
                message='Сотрудник успешно добавлен'
                details={status.error}
            />
        </section>
    )
}

export default AddStuff
