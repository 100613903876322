import { createAsyncThunk } from '@reduxjs/toolkit'
import { IikoCategoriesService } from '../../../api/IikoCategoriesService'
import { IIikoCategories, IIikoCategoriesUpdate } from '../../../interfaces/IikoCategories'
import { RootState } from '../../reducers/rootReducer'

interface DeleteArgs {
    category: IIikoCategories,
    isSmart: boolean
}
export const disabledCategory = createAsyncThunk('iikoCategory/disabledCategory', 
    async(args: DeleteArgs, thunkApi) => {
        const api = new IikoCategoriesService()
        const response = await api.delete( args.category._id, args.isSmart, {isDisabled: true}) as IIikoCategories
        return args.category._id
    }
)
export const enableCategory = createAsyncThunk('iikoCategory/enableCategory', 
    async(args: DeleteArgs, thunkApi) => {
        const api = new IikoCategoriesService()
        const response = await api.delete( args.category._id, args.isSmart, {isDisabled: false}) as IIikoCategories
        return args.category._id
    }
)

export const deleteCategory = createAsyncThunk('iikoCategory/deleteCategory', 
    async(args: DeleteArgs, thunkApi) => {
        const api = new IikoCategoriesService()
        const response = await api.delete( args.category._id, args.isSmart, {isDeleted: !args.category.isDeleted}) as IIikoCategories
        return args.category._id
    }
)