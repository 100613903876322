import React, { Suspense, useEffect } from 'react'
import './App.scss'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Loader } from './components/Loader/Loader'
import { Navbar } from './components/Navbar/Navbar'
import { useTypedSelector } from './hooks/useTypedSelector'
import { RequireAuth } from './components/RequireAuth/RequireAuth'
import { useActions } from './hooks/useActions'
import { Orders } from './pages/Orders/Orders'
import { AddOrder } from './pages/AddOrder/AddOrder'
import Content from './pages/Content/Content'
import AddContent from './pages/AddContent/AddContent'
import EditContent from './pages/EditContent/EditContent'
import { NewOrderListener } from './components/NewOrderListener/NewOrderListener'
import { OrderStates } from './pages/OrderStates/OrderStates'
import { EditOrderState } from './pages/EditOrderState/EditOrderState'
import EditModGroup from './pages/EditModGroup/EditModGroup'
import EditModOption from './pages/EditModOptions/EditModOptions'
import Stuff from './pages/Stuffs/Stuffs'
import AddStuff from './pages/AddStuff/AddStuff'
import EditStuff from './pages/EditStuff/EditStuff'
import { useDispatch } from 'react-redux'
import { removeAdmin } from './store/reducers/userReducer'
import { Settings } from './pages/Settings/Settings'
import { TPCasTable } from './pages/TPCasTable/TPCasTable'
import { EditTpCas } from './pages/TPCasTable/EditTpCas'
import { AddTpCas } from './pages/TPCasTable/AddTpCas'
import { AdminUsers } from './pages/AdminUsers/AdminUsers'
import { AdminUserEdit } from './pages/AdminUsers/AdminUserEdit'
import { AdminUserAdd } from './pages/AdminUsers/AdminUserAdd'
import { MarketingTriggers } from './pages/MarketingTriggers/MarketingTriggers.jsx'

const Auth = React.lazy(() => import('./pages/Auth/Auth'))
const NotFoundPage = React.lazy(() => import('./pages/NotFound/NotFoundPage'))
const TerminalGroups = React.lazy(() => import('./pages/TerminalGroups/TerminalGroups'))
const Terminals = React.lazy(() => import('./pages/Terminals/Terminals'))
const Concept = React.lazy(() => import('./pages/Concept/Concept'))
const AddConcept = React.lazy(() => import('./pages/AddConcept/AddConcept'))
const AddProduct = React.lazy(() => import('./pages/AddProduct/AddProduct'))
const AddCategory = React.lazy(() => import('./pages/AddCategory/AddCategory'))
const AddModGroup = React.lazy(() => import('./pages/AddModGroup/AddModGroup'))
const AddModOption = React.lazy(() => import('./pages/AddModOption/AddModOption'))
const AddTerminalGroup = React.lazy(() => import('./pages/AddTerminalGroup/AddTerminalGroup'))
const EditTerminalGroup = React.lazy(() => import('./pages/EditTerminalGroup/EditTerminalGroup'))
const EditConcept = React.lazy(() => import('./pages/EditConcept/EditConcept'))
const AddTerminal = React.lazy(() => import('./pages/AddTerminal/AddTerminal'))
const EditTerminal = React.lazy(() => import('./pages/EditTerminal/EditTerminal'))
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'))
const Push = React.lazy(() => import('./pages/Push/Push'))
const AddPush = React.lazy(() => import('./pages/AddPush/AddPush'))
const EditPush = React.lazy(() => import('./pages/EditPush/EditPush'))
const Stories = React.lazy(() => import('./pages/Stories/Stories'))
const AddStories = React.lazy(() => import('./pages/AddStories/AddStories'))
const EditStorie = React.lazy(() => import('./pages/EditStorie/EditStorie'))
const Menu = React.lazy(() => import('./pages/Menu/Menu'))
const EditCategory = React.lazy(() => import('./pages/EditCategory/EditCategory'))
const Badges = React.lazy(() => import('./pages/Badges/Badges'))
const AddBadge = React.lazy(() => import('./pages/AddBadge/AddBadge'))
const CardRecommendation = React.lazy(() => import('./pages/CardRecommendation/CardRecommendation'))
const Segments = React.lazy(() => import('./pages/Segments/Segments'))
const AutoSegments = React.lazy(() => import('./pages/AutoSegments/AutoSegments'))

const AddSegment = React.lazy(() => import('./pages/AddSegment/AddSegment'))
const Promo = React.lazy(() => import('./pages/Promo/Promo'))
const AddPromo = React.lazy(() => import('./pages/AddPromo/AddPromo'))
const EditPromo = React.lazy(() => import('./pages/EditPromo/EditPromo'))
const Customers = React.lazy(() => import('./pages/Customers/Customers'))
const Triggers = React.lazy(() => import('./pages/Triggers/Triggers'))
const AddTrigger = React.lazy(() => import('./pages/AddTrigger/AddTrigger'))
const CustomerOrderDetail = React.lazy(() => import('./pages/CustomerOrderDetail/CustomerOrderDetail'))
const CustomerDetail = React.lazy(() => import('./pages/CustomerDetail/CustomerDetail'))
const UploadSegment = React.lazy(() => import('./pages/UploadSegment/UploadSegment'))
const MenuRestaurant = React.lazy(() => import('./pages/MenuRestaurant/MenuRestaurant'))
const MenuCategory = React.lazy(() => import('./pages/MenuCategory/MenuCategory'))
const ModificatorsTerminal = React.lazy(() => import('./pages/ModificatorsTerminal/ModificatorsTerminal'))
const ModificatorsGroups = React.lazy(() => import('./pages/ModificatorsGroups/ModificatorsGroups'))
const MenuDish = React.lazy(() => import('./pages/MenuDish/MenuDish'))
const ModificatorsOptions = React.lazy(() => import('./pages/ModificatorsOptions/ModificatorsOptions'))

export enum Paths {
    DASHBOARD = 'dashboard',
    PUSH = 'push',
    STORIES = 'stories',
    SEGMENTS = 'segments',
    AUTOSEGMENTS = 'autosegments',
    PROMO = 'promocodes',
    TRIGGERS = 'triggers',
    MENU = 'menu',
    MODIFICATORS = 'modificators-terminal',
    BADGES = 'badges',
    RECOMMENDATIONS = 'recommendations-dish',
    CONCEPTS = 'concepts',
    CUSTOMERS = 'customers',
    ORDERS = 'orders',
    CONTENTS = 'contents',
    ORDER_STATES = 'order-states',
    STUFFS = 'stuffs',
    SETTINGS = 'settings',
    SETTINGS_TPCAS = 'settings-tpcas',
    SUPER_ADMIN_PASSWORD = 'admin-users',
    NEW_TRIGGERS = 'triggers_new',
}

const App = () => {
    const { isAuth } = useTypedSelector((state) => state.authReducer)
    const location = useLocation()
    const { logout, getAdmin } = useActions()
    useEffect(() => {
        const tokenCheck = () => {
            console.log(location.pathname)

            if (
                // location.pathname !== '/auth' &&
                !localStorage['token'] ||
                !localStorage['company']
            ) {
                logout()
            }
        }
        tokenCheck()
        // getAdmin()

        window.addEventListener('storage', tokenCheck)
        return () => {
            window.removeEventListener('storage', tokenCheck)
        }
    }, [])
    return (
        <>
            {localStorage.getItem('company') && isAuth ? <Navbar /> : null}
            {localStorage.getItem('company') && isAuth ? <NewOrderListener /> : null}
            <div className='content'>
                <Routes>
                    <Route path={Paths.SETTINGS} element={<Settings />} />
                    <Route path={Paths.SETTINGS_TPCAS} element={<TPCasTable />} />
                    <Route path={`${Paths.SETTINGS_TPCAS}/:id`} element={<EditTpCas />} />
                    <Route path={`${Paths.SETTINGS_TPCAS}/add`} element={<AddTpCas />} />
                    <Route path={`${Paths.SUPER_ADMIN_PASSWORD}`} element={<AdminUsers />} />
                    <Route path={`${Paths.SUPER_ADMIN_PASSWORD}/:id`} element={<AdminUserEdit />} />
                    <Route path={`${Paths.SUPER_ADMIN_PASSWORD}/add`} element={<AdminUserAdd />} />
                    <Route path={Paths.NEW_TRIGGERS} element={<MarketingTriggers />} />
                    <Route
                        path='auth'
                        element={
                            <Suspense fallback={<Loader />}>
                                <Auth />
                            </Suspense>
                        }
                    />
                    <Route
                        index
                        element={
                            <Suspense fallback={<Loader />}>
                                <Auth />
                            </Suspense>
                        }
                    />
                    <Route element={<RequireAuth />}>
                        <Route
                            path={Paths.PUSH}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Push />
                                </Suspense>
                            }
                        />
                        <Route
                            path={Paths.STORIES}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Stories />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.STORIES}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditStorie />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.STORIES}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddStories />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.PUSH}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddPush />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.PUSH}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditPush />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Concept />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddConcept />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:id/edit`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditConcept />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <TerminalGroups />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddTerminalGroup />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups/:id/edit`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditTerminalGroup />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Terminals />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups/:id/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddTerminal />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONCEPTS}/:conceptId/terminal-groups/:groupId/:id/edit`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditTerminal />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.DASHBOARD}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Dashboard />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Menu />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <ModificatorsTerminal isMenu />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <MenuRestaurant />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddCategory />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId/:category`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <MenuCategory />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId/:category/edit`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditCategory />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId/:category/add-product`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddProduct />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MENU}/:restaurant/:terminalId/:category/:dish`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <MenuDish />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <ModificatorsTerminal />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <ModificatorsGroups />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddModGroup />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId/edit/:groupId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditModGroup />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId/:page/:modId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <ModificatorsOptions />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId/:page/:modId/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddModOption />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.MODIFICATORS}/:terminalId/:page/:modId/edit/:optId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditModOption />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.BADGES}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Badges />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.BADGES}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddBadge />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.RECOMMENDATIONS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Menu isRecommendation />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.SEGMENTS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Segments />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.SEGMENTS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddSegment />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.SEGMENTS}/upload`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <UploadSegment />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.AUTOSEGMENTS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AutoSegments />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.TRIGGERS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Triggers />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.TRIGGERS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddTrigger />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.PROMO}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Promo />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.PROMO}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddPromo />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.PROMO}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditPromo />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CUSTOMERS}`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Customers />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CUSTOMERS}/detail/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <CustomerDetail />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CUSTOMERS}/detail/:id/order/:orderId`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <CustomerOrderDetail />
                                </Suspense>
                            }
                        />

                        <Route
                            path={`${Paths.ORDERS}/`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Orders />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.ORDERS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddOrder />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.ORDERS}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddOrder isEdit />
                                </Suspense>
                            }
                        />

                        <Route
                            path={`${Paths.ORDER_STATES}/`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <OrderStates />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.ORDER_STATES}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditOrderState isAdd />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.ORDER_STATES}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditOrderState />
                                </Suspense>
                            }
                        />

                        <Route
                            path={`${Paths.CONTENTS}/`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Content />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONTENTS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddContent />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.CONTENTS}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditContent />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.STUFFS}/`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <Stuff />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.STUFFS}/add`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <AddStuff />
                                </Suspense>
                            }
                        />
                        <Route
                            path={`${Paths.STUFFS}/edit/:id`}
                            element={
                                <Suspense fallback={<Loader />}>
                                    <EditStuff />
                                </Suspense>
                            }
                        />
                    </Route>

                    <Route
                        path='*'
                        element={
                            <Suspense fallback={<Loader />}>
                                <NotFoundPage />
                            </Suspense>
                        }
                    />
                </Routes>
            </div>
        </>
    )
}
export default App
