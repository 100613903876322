import { addPush, clearAddPushState } from './add'
import { deletePush, enablePush, disablePush } from './delete'
import { getAllPushes, getPushById } from './get'
import { sendPush } from './send'
import { clearUpdatePushState, updatePush } from './update'


export const PushActions = {
    getAllPushes,
    getPushById,
    updatePush,
    clearUpdatePushState,
    addPush,
    clearAddPushState,
    sendPush,
    deletePush,
    enablePush,
    disablePush
}