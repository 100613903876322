import { createAsyncThunk } from '@reduxjs/toolkit'
import { StoriesService } from '../../../api/StoriesService'
import { IStories, IStoriesSort } from '../../../interfaces/Stories'
import { RootState } from '../../reducers/rootReducer'


interface SortStoriesArgs {
    stories: IStories[]
    page: number
}
export const sortStories = createAsyncThunk('storie/sort',
    async(args: SortStoriesArgs, thunkApi) => {
        const {stories, page} = args
        const api = new StoriesService()
        const storiesUpdate: IStoriesSort[] = stories.map((storie, index) => ({
            _id: storie._id, 
            order: (page - 1)*10+index
        }))
        const response = await api.sortStories(storiesUpdate)
        return stories
    }
)