import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import { IikoProductsService } from '../../api/IikoProductsService'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IApi } from '../../interfaces/Api'
import { IIikoProducts } from '../../interfaces/IikoProducts'
import { Loader } from '../Loader/Loader'
import { Input } from '../UI/Input/Input'
import './InputDish.scss'

interface InputDishProps {
    onChange: (dish: IIikoProducts) => void
    conceptId: string
    isError?: boolean
    defValueId?: string
    className?: string
    label?: string
    clearAfterSelect?: boolean
    isLabeled?: boolean
    isCode?: boolean
    terminalId?: string
}

export const InputDish: FC<InputDishProps> = ({
    onChange,
    conceptId,
    isError = false,
    defValueId,
    className = '',
    label = 'Блюдо',
    isLabeled = true,
    clearAfterSelect = false,
    isCode = false,
    terminalId,
}) => {
    const [dishName, setDishName] = useState('')
    // const { getIikoProductsBySubstr, getIikoProductsById } = useActions()
    const [isVisible, setVisible] = useState(false)
    const [defProduct, setDefProduct] = useState<IIikoProducts>()
    const [iikoProducts, setIikoProducts] = useState<IIikoProducts[]>([])
    const [loading, setLoading] = useState(false)
    // const { iikoProducts, getProducts } = useTypedSelector(
    //     (state) => state.iikoProductsReducer
    // )
    const onInputDishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDishName(e.target.value)
    }

    const getIikoProductsBySubstr = async (conceptId: string, str: string) => {
        if (str.trim().length === 0) {
            setIikoProducts([])
            return
        }
        try {
            setLoading(true)
            const api = new IikoProductsService()
            const products = (await api.search(
                conceptId,
                str,
                terminalId
            )) as IApi<IIikoProducts>
            setIikoProducts(products.data)
        } catch (error) {
            console.log(error)
            setIikoProducts([])
        } finally {
            setLoading(false)
        }
    }

    const getIikoProductsById = async (id: string) => {
        if (!id) return
        try {
            setLoading(true)
            const api = new IikoProductsService()
            const product = (await api.getProductById(id, false)) as IIikoProducts
            setIikoProducts([product])
        } catch (error) {
            console.log(error)
            setIikoProducts([])
        } finally {
            setLoading(false)
        }
    }
    const getIikoProductsByCode = async (code: string) => {
        try {
            setLoading(true)
            const api = new IikoProductsService()
            const product = (await api.getIikoProducts(1, code)).data
            setIikoProducts([...product])
        } catch (error) {
            console.log(error)
            setIikoProducts([])
        } finally {
            setLoading(false)
        }
    }
    const fetchNewDishes = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.target as HTMLInputElement).value.length >= 3)
            // getIikoProductsBySubstr({
            //     conceptId,
            //     str: (e.target as HTMLInputElement).value,
            // })
            getIikoProductsBySubstr(
                conceptId,
                (e.target as HTMLInputElement).value
            )
        else {
            // getIikoProductsBySubstr({
            //     str: '',
            //     conceptId,
            // })
            getIikoProductsBySubstr(conceptId, '')
        }
    }
    useEffect(() => {
        if (defValueId) {
            if (isCode) getIikoProductsByCode(defValueId)
            else getIikoProductsById(defValueId)
        }
    }, [defValueId])

    useEffect(() => {
        if (defValueId && iikoProducts) {
            if (!defProduct?._id) {
                setDefProduct(
                    iikoProducts.find(
                        (d) => d._id === defValueId
                    ) as IIikoProducts
                )
            }
        }
    }, [defValueId, iikoProducts])
    useEffect(() => {
        if (defProduct?._id) {
            const _dish = iikoProducts.find((d) => d._id === defValueId)
            if (_dish) {
                setDishName(_dish.name)
                onChange(_dish)
            }
        }
    }, [defProduct])

    useEffect(() => {
        // getIikoProductsBySubstr({
        //     str: '',
        //     conceptId,
        // })
        getIikoProductsBySubstr(conceptId, '')
        setDishName('')
    }, [conceptId])

    const onBlurHandler = () => {
        // Special for Safari
        setTimeout(() => {
            setVisible(false)
        }, 100)
    }
    const setDishHandler = (id: string) => {
        const _dish = iikoProducts.find((d) => d._id === id)
        if (_dish) {
            if (clearAfterSelect) setDishName('')
            else setDishName(_dish.name)
            onChange(_dish)
            setVisible(false)
        }

        // getIikoProductsBySubstr({
        //     str: _dish?.name || '',
        //     conceptId,
        // })
        getIikoProductsBySubstr(conceptId, _dish?.name || '')
    }
    useEffect(() => {
        // getIikoProductsBySubstr({
        //     str: '',
        //     conceptId,
        // })
        getIikoProductsBySubstr(conceptId, '')
    }, [])
    const selectRef = useRef<HTMLDivElement>(null)
    const handleClickOutside = (e: MouseEvent) => {
        if (selectRef.current?.contains(e.target as Node)) return
        setVisible(false)
    }

    useEffect(() => {
        if (isVisible)
            document.addEventListener('mousedown', handleClickOutside)
        else document.removeEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isVisible])

    const inputStyles = classNames({
        'input-dish__input': true,
        error: isError,
    })
    return (
        <div className={`input-dish ${className}`} ref={selectRef}>
            {isLabeled && <label className='input-dish__label'>{label}</label>}
            <Input
                className={inputStyles}
                value={dishName}
                onChange={onInputDishChange}
                onKeyUp={fetchNewDishes}
                // onBlur={onBlurHandler}
                onFocus={() => setVisible(true)}
            />
            <div className='input-dish__dropdown'>
                {isVisible && dishName ? (
                    loading ? (
                        <div className='input-dish__dropdown-loader'>
                            <Loader className='promo-loader' />
                        </div>
                    ) : iikoProducts?.length > 0 ? (
                        iikoProducts.map((d) => (
                            <div
                                className='input-dish__dropdown-item'
                                key={d._id}
                                onClick={() => setDishHandler(d._id)}>
                                {d.name}
                            </div>
                        ))
                    ) : (
                        <div className='input-dish__dropdown-item not-found'>
                            {conceptId === 'not'
                                ? 'Выберите концепцию'
                                : 'Блюдо не найдено'}
                        </div>
                    )
                ) : null}
            </div>
        </div>
    )
}
