import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IChangeOrder, IOrder, IOrderDeliveryTypes, IOrderPaymentTypes, IOrderSources, IOrderStates } from '../interfaces/Order'
import { Api, DeleteQuery } from './Api'
import { axiosInstance } from './axios'

export class IikoOrdersService extends Api {
    constructor() {
        super()
    }

    get = async( page: number, limit?: number) => {
        try {
            let _limit = 10
            if(limit) _limit = limit
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IOrder>>(`${this.URL}/api/v1/orders/?limit=${_limit}&page=${page}`, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getById = async( id: string) => {
        try {
            const response = await axiosInstance.get<IOrder>(`${this.URL}/api/v1/orders/${id}`)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getOrderStates = async(conceptId?: string) => {
        try {
            let q = `${this.URL}/api/v1/order_states/`
            if(conceptId) q += `?conceptId=${conceptId}`
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IOrderStates[]>(q, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getOrderSources = async(conceptId?: string) => {
        try {
            let q = `${this.URL}/api/v1/order_sources/`
            if(conceptId) q += `?conceptId=${conceptId}`
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IOrderSources[]>(q, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getPaymentTypes = async(conceptId?: string) => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IOrderPaymentTypes[]>(`${this.URL}/api/v1/order_payment_types/`, {
                params: {
                    companyId,
                    conceptId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getDeliveryTypes = async(conceptId?: string) => {
        try {
            const companyId = localStorage.getItem('company')
            let q = `${this.URL}/api/v1/order_delivery_types/`
            if(conceptId) q += `?conceptId=${conceptId}`
            const response = await axiosInstance.get<IOrderDeliveryTypes[]>(q, {
                params: {
                    companyId
                }
            })
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    update = async(order: IChangeOrder) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v1/orders/`, order)
            return response.data
           
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async( id: string, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            const response = await axiosInstance.delete(`${this.URL}/api/v1/orders/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if(error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}