import {
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../UI/Icon/Icon'

interface CustomModalSuccessProps {
    onClose: () => void
    clearState: () => void
    msg: string
    navTo?: string
    // eslint-disable-next-line no-undef
    btnYes?: JSX.Element
    // eslint-disable-next-line no-undef
    btnNo?: JSX.Element
}

export const CustomModalSuccess: FC<CustomModalSuccessProps> = ({
    onClose,
    clearState,
    msg,
    navTo = '',
    btnNo,
    btnYes,
}) => {
    const navigator = useNavigate()
    const redirect = () => {
        onClose()
        clearState()
        if (navTo.trim() !== '') navigator(navTo)
    }
    return (
        <ModalContent className='modal'>
            <ModalHeader className='modal__header'>
                <button onClick={redirect} className='modal__header--btn-close'>
                    <Icon name='Close' />
                </button>
            </ModalHeader>
            <ModalBody className='modal__body'>
                <Icon name='Ok' />
                {msg}
            </ModalBody>
            <ModalFooter className='modal__footer'>
                {btnYes ? (
                    btnYes
                ) : (
                    <button
                        className='modal__footer-btn btn-yes'
                        onClick={redirect}>
                        Ок
                    </button>
                )}
                {btnNo}
            </ModalFooter>
        </ModalContent>
    )
}
