import { createAsyncThunk } from '@reduxjs/toolkit'
import { PromoService } from '../../../api/PromoService'
import { IAddPromo, IPromo } from '../../../interfaces/Promo'
import { RootState } from '../../reducers/rootReducer'

export const editPromocode = createAsyncThunk('promo/edit',
    async(promocode: IAddPromo, thunkApi) => {
        const api = new PromoService()
        const response = await api.edit(promocode) as IPromo
        return response
    }
)


export const editPromocodeClearState = createAsyncThunk('promo/editclearstate',
    (_, thunkApi) => {
        return true
    }
)