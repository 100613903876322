import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IIikoCategories, IIikoCategoriesUpdate, IRRule } from '../interfaces/IikoCategories'
import { Api, DeleteQuery, GetQuery } from './Api'
import { axiosInstance } from './axios'

export class IikoCategoriesService extends Api {
    constructor() {
        super()
    }

    getAllCategories = async (page: number) => {
        try {
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoCategories>>(`${this.URL}/api/v1/categories/?isDeleted=false&isHidden=false&isDisabled=false&limit=10000&page=${page}`, {
                params: {
                    companyId
                }
            })
            // const responseSmart = await axiosInstance.get<IApi<IIikoCategories>>(`${this.URL}/api/v1/smart_categories/?isDeleted=false&isHidden=false&isDisabled=false&limit=10000&page=${page}`, {
            //     params: {
            //         companyId
            //     }
            // })
            // return {
            //     ...response.data,
            //     data: [...response.data.data, ...responseSmart.data.data]
            // }
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    getAllCategoriesByConceptId = async (conceptId: string, page: number, query?: GetQuery, terminalId?: string) => {
        try {
            const terminal = terminalId ? `terminalId=${terminalId}&` : ''
            const companyId = localStorage.getItem('company')
            const response = await axiosInstance.get<IApi<IIikoCategories>>(`${this.URL}/api/v1/categories/?${this.getQuery(query)}&limit=1000&page=${page}`, {
                params: {
                    companyId,
                    conceptId,
                    terminalId
                }
            })
            // const responseSmart = await axiosInstance.get<IApi<IIikoCategories>>(`${this.URL}/api/v1/smart_categories/?${this.getQuery(query)}&limit=1000&page=${page}`, {
            //     params: {
            //         companyId,
            //         conceptId,
            //         terminalId
            //     }
            // })
            // return {
            //     ...response.data,
            //     data: [...response.data.data, ...responseSmart.data.data]
            // }
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }

    getCategoryById = async (id: string, isSmart: boolean) => {
        try {
            // if (isSmart) {
            //     const response = await axiosInstance.get<IIikoCategories>(`${this.URL}/api/v1/smart_categories/${id}?isDeleted=false`)
            //     return response.data
            // }
            const response = await axiosInstance.get<IIikoCategories>(`${this.URL}/api/v1/categories/${id}?isDeleted=false`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    addCategory = async (category: IIikoCategoriesUpdate, isSmart: boolean) => {
        try {
            // if(isSmart) {
            //     const response = await axiosInstance.post<IIikoCategories>(`${this.URL}/api/v1/smart_categories/`, category)
            //     return response.data
            // }
            const response = await axiosInstance.post<IIikoCategories>(`${this.URL}/api/v1/categories/`, category)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    updateIikoCategory = async (category: IIikoCategoriesUpdate, isSmart: boolean) => {
        try {
            // if(isSmart) {
            //     const response = await axiosInstance.put<IIikoCategories>(`${this.URL}/api/v1/smart_categories/`, category)
            //     return response.data
            // }
            const response = await axiosInstance.put<IIikoCategories>(`${this.URL}/api/v1/categories/`, category)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    migrateToSmart = async (rrule: IRRule, id: string) => {
        try {
            const response = await axiosInstance.post(`${this.URL}/api/v1/c2smc/`, {
                _id: id,
                rrule
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    delete = async (id: string, isSmart: boolean, params: DeleteQuery) => {
        try {
            const q = this.getQuery(params)
            // if(isSmart) {
            //     const response = await axiosInstance.delete(`${this.URL}/api/v1/smart_categories/${id}?${q}`)
            //     return response.data
            // }
            const response = await axiosInstance.delete(`${this.URL}/api/v1/categories/${id}?${q}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     if(error.response.status === 401) throw new Error('Unauthorized')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
    sort = async (cats: any) => {
        try {
            const response = await axiosInstance.put(`${this.URL}/api/v1/categories/sort`, cats)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            // if(error.response) {
            //     if(error.response.status === 404) throw new Error('Сервер временно не работает')
            //     if(error.response.status === 422) throw new Error('Validation Error')
            //     else throw new Error('Сервер временно не работает')
            // } else throw new Error('Сервер временно не работает')
            if (error.response) {
                console.log(error.response.status, JSON.stringify(error.response.data))
                throw new Error(JSON.stringify(error.response.data))
            } else throw new Error('Unhandled error')
        }
    }
}