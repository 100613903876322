import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Loader } from '../../components/Loader/Loader'
import { Link } from 'react-router-dom'
import './Content.scss'
import { Icon } from '../../components/UI/Icon/Icon'
import { useScroll } from '../../hooks/useScroll'
import { IContent } from '../../interfaces/Content'
import { ContentTable } from '../../components/ContentTable/ContentTable'
import { ContentService } from '../../api/ContentService'
import { IConcept } from '../../interfaces/Concept'
import { ConceptDropDown } from '../../components/ConceptDropDown/ConceptDropDown'

const Content = () => {
    const [pages, setPages] = useState(1)
    const [currPage, setCurrPage] = useState(1)
    const [status, setStatus] = useState({
        loading: false,
        error: '',
    })
    const [contents, setContents] = useState<IContent[]>([])

    useEffect(() => {
        document.title = 'Контент'
        return () => {}
    }, [])

    const cb = useCallback(() => {
        if (pages <= currPage) return
        setCurrPage((prev) => prev + 1)
    }, [currPage, pages])
    const parentRef = useRef<HTMLDivElement>(null)
    const childRef = useRef<HTMLDivElement>(null)

    useScroll(parentRef, childRef, cb)
    useEffect(() => {
        let isMount = true

        const getContents = async (page: number) => {
            try {
                const api = new ContentService()
                const _contents = await api.getContents(page)
                if (isMount) {
                    setContents((prev) => [...prev, ..._contents.data])
                    setPages(_contents.pages)
                }
            } catch (error: any) {
                console.error(error)
                setStatus({
                    loading: false,
                    error: error.message,
                })
            }
        }
        getContents(currPage)
        return () => {
            isMount = false
        }
    }, [currPage])
    const [activeType, setActiveType] = useState<string | null>(null)

    const onDelete = (deleteId: string) => {
        setContents((prev) => prev.filter((p) => p._id !== deleteId))
    }

    return (
        <section className='contents'>
            <header className='contents__header'>
                <div className='contents__header--name'>{'Контент'}</div>
                <Link to={'./add'} className='contents__header--btn-add'>
                    <Icon name='Plus' />
                    Добавить
                </Link>
            </header>
            <div className='contents__filter'>
                {/* <button
                    className={`contents__filter--btn ${
                        activeType === 'main' ? '--active' : ''
                    }`}
                    onClick={() => setActiveType('main')}>
                    Главная страница
                </button>
                <button
                    className={`contents__filter--btn ${
                        activeType === 'slide' ? '--active' : ''
                    }`}
                    onClick={() => setActiveType('slide')}>
                    Слайдер
                </button> */}
                <ConceptDropDown
                    additionalValue={{ id: 'addData', value: 'Общий' }}
                    onChange={(c) =>
                        setActiveType(c._id === 'addData' ? null : c._id)
                    }
                />
            </div>

            <ContentTable
                contents={contents.filter((c) => c.conceptId === activeType)}
                onDelete={onDelete}
            />
            {status.loading && <Loader />}
            <div
                ref={childRef}
                style={{
                    // backgroundColor: 'green',
                    width: '100%',
                    height: '10px',
                }}></div>
        </section>
    )
}

export default Content
